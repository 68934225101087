import React, { useState, useEffect } from 'react'
import { Form, ToggleButton, ToggleButtonGroup } from 'react-bootstrap'
import FeatherIcon from 'feather-icons-react';

const RadioToggleField = ({
  id,
  name,
  label,
  onChange,
  error,
  value = true
}) => {
  const handleChange = (isYes) => {
    onChange({
      target: {
        id: id,
        name: name,
        value: isYes,
      },
    })
  }

  return (
    <Form.Group className="mb-3">
      <Form.Label>{label}</Form.Label>
      <br />
      <ToggleButtonGroup
        name={name}
        type="radio"
        defaultValue={value}
        className="mb-2 btn-group-toggle"
        onChange={handleChange}
        style={{ zIndex: 0 }}
      >
        <ToggleButton variant="white" id={name + "Yes"} value={true}>
          <FeatherIcon icon="check-circle" size="1em" /> Yes
        </ToggleButton>
        <ToggleButton variant="white" id={name + "No"} value={false}>
          <FeatherIcon icon="check-circle" size="1em" v /> No
        </ToggleButton>
      </ToggleButtonGroup>
    </Form.Group>
  )
}

export default RadioToggleField
