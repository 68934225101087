import FeatherIcon from 'feather-icons-react';
import React from 'react';
import { Card, Col, Row } from 'react-bootstrap';
import { Header } from '..';
import { ModalTotalWealth } from 'components/modals'
import { formatCurrency } from 'helpers'

export default function AdviceCard({ icon, heading, text, value = 0.00, ...props }) {
  return (
    <Card {...props} className="bg-dark">
      <Card.Body>
        <Row className="d-flex flex-column justify-content-center">
          <Col className="text-center text-light">
            <h5 className="text-uppercase mb-2 text-light">{heading}</h5>
            <FeatherIcon icon={icon} size="50" className="text-light mt-3" />
            <p>{text}</p>
          </Col>
          <Col xs="auto">
          <Header.Pretitle className="text-secondary text-center">
              <ModalTotalWealth buttonText="How can i do this?" />
            </Header.Pretitle>
          </Col>
        </Row>
      </Card.Body>
    </Card>
  );
}
