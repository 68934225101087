import PropTypes from 'prop-types'
import { Alert, Col, Row } from 'react-bootstrap'
import { FormHeader } from 'components'
import {
  NumberField,
  CurrencyField,
  FrequencyField,
  SelectField,
} from 'components/form'
import { useForm } from 'helpers'
import {
  deferredPeriodOptions,
  insuranceProviderOptions,
  insuranceTypeOptions,
  policyHolderOptions,
  policyTypeOptions,
} from 'config/schemas'
import FinanceFormActions from './FinanceFormActions'

const InsuranceForm = ({ state, errors, success, handleChange, handleSubmit }) => {
  return (
    <form className="" id="DebtsForm" onSubmit={handleSubmit}>
      <div role="tabpanel" aria-labelledby="insurancesForm">
        <FormHeader
          title="Add a new insurance"
          subtext="Please tell us about your personal insurances e.g life insurance, critical illness cover and income protection. Don’t forget any to include any cover you get through work!"
        />
        <SelectField
          name="provider"
          label="Provider"
          onChange={handleChange}
          value={state.provider}
          options={insuranceProviderOptions}
        />
        <SelectField
          name="insuranceType"
          label="Insurance Type"
          onChange={handleChange}
          value={state.insuranceType}
          options={insuranceTypeOptions}
        />
        {state.insuranceType === `Life Cover` && (
          <LifeCoverForm
            state={state}
            errors={errors}
            onChange={handleChange}
          />
        )}
        {state.insuranceType === `Critical Illness` && (
          <CriticalIllnessForm
            state={state}
            errors={errors}
            onChange={handleChange}
            onSelectChange={handleChange}
          />
        )}
        {state.insuranceType === `Income Protection` && (
          <IncomeProtectionForm
            state={state}
            errors={errors}
            onChange={handleChange}
            onSelectChange={handleChange}
          />
        )}
      </div>
      <FinanceFormActions cancelPath="/finances/insurance" />
    </form>
  )
}

InsuranceForm.propTypes = {}

const LifeCoverForm = ({ state, errors, onChange }) => (
  <div>
    <CurrencyField
      label="What's the value of this policy?"
      inputClass="form-control"
      name="policyValue"
      id="policyValue"
      value={state.policyValue}
      onChange={onChange}
      error={errors.policyValue}
    />
    <SelectField
      name="policyType"
      label="Policy Type"
      onChange={onChange}
      value={state.policyType}
      options={policyTypeOptions}
    />
    <SelectField
      name="policyHolder"
      label="Policy Holder"
      onChange={onChange}
      value={state.policyHolder}
      options={[...policyHolderOptions, `Joint`]}
    />
    <NumberField
      label="What's the remaining policy term in years?"
      placeholder="60"
      inputClass="form-control"
      name="policyTerm"
      id="policyTerm"
      value={state.policyTerm}
      onChange={onChange}
      error={errors.policyTerm}
    />
  </div>
)

const CriticalIllnessForm = ({ state, errors, onChange, onSelectChange }) => (
  <div>
    <CurrencyField
      label="What's the value of this policy?"
      inputClass="form-control"
      name="policyValue"
      id="policyValue"
      value={state.policyValue}
      onChange={onChange}
      error={errors.policyValue}
    />
    <SelectField
      name="policyHolder"
      label="Policy Holder"
      onChange={onSelectChange}
      value={state.policyHolder}
      options={[...policyHolderOptions, `Joint`]}
    />
    <NumberField
      label="What's the remaining policy term in years?"
      placeholder="60"
      inputClass="form-control"
      name="policyTerm"
      id="policyTerm"
      value={state.policyTerm}
      onChange={onChange}
      error={errors.policyTerm}
    />
  </div>
)

const IncomeProtectionForm = ({ state, errors, onChange, onSelectChange }) => (
  <div>
    <SelectField
      name="policyHolder"
      label="Policy Holder"
      onChange={onSelectChange}
      value={state.policyHolder}
      options={policyHolderOptions}
    />
    <CurrencyField
      label="What's the monthly payout?"
      inputClass="form-control"
      name="policyValue"
      id="policyValue"
      value={state.policyValue}
      onChange={onChange}
      error={errors.policyValue}
    />
    <SelectField
      name="deferredPeriod"
      label="Deferred Period"
      onChange={onSelectChange}
      value={state.deferredPeriod}
      options={deferredPeriodOptions}
    />
    <NumberField
      label="What's the remaining policy term in years?"
      placeholder="60"
      inputClass="form-control"
      name="policyTerm"
      id="policyTerm"
      value={state.policyTerm}
      onChange={onChange}
      error={errors.policyTerm}
    />
  </div>
)

export default InsuranceForm
