import { Modal } from 'containers'

const ModalPensionType = () => {
  return (
    <Modal heading="Pension Types">
      <h3>What is a defined benefit (DB) pension?</h3>
      <p>A DB (also called a 'Final Salary' or ‘Career Average’ pension scheme) is a type of workplace pension that pays you a retirement income based on your salary and the number of years you’ve worked for the employer, rather than the amount of money you’ve contributed to the pension.</p>
      <p>DB pensions are increasingly rare, but you may have one if you’ve worked for a large company like BT or a public sector organisation e.g NHS, Local Council etc.</p>
      <h3>What is a defined contribution (DC) pension?</h3>
      <p>A DC plan is a type of pension which the employer, employee or both make contributions to on a regular basis. Your retirement benefits from this type of pension are based on pension payments and investment growth over the years.</p>
      <p>DC pensions are the most common, either taken out privately via well known companies like Aviva, Fidelity, Hargreaves Lansdown etc or via workplace arrangements with organisations like Nest, SmartPension, Standard Life etc.</p>
    </Modal>
  )
}

export default ModalPensionType
