import React from 'react'
import { Form } from 'react-bootstrap'

const NumberField = ({
  id,
  name,
  label,
  value,
  error,
  onChange = null,
  onBlur = null,
  mutedText = null,
  labelClass = null,
  inputClass = null,
  readOnly = false,
  placeholder = ``,
  isNumber = false,
}) => (
  <div className="form-group">
    <label className={labelClass}>{label}</label>
    {mutedText && <small className="form-text text-muted">{mutedText}</small>}
    <input
      type="text"
      className={`form-control ${inputClass} ${error ? "is-invalid" : ""}`}
      id={id}
      name={name}
      value={isNumber && value === `0` ? `` : value}
      onChange={onChange}
      onBlur={onBlur}
      readOnly={readOnly}
      placeholder={placeholder}
    />
    {error && <div className="invalid-feedback">{error}</div>}
  </div>
)

export default NumberField
