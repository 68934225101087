const formatAccountUser = account => {
  switch (account.accountType) {
      case `PROPERTY`:
      case `INSURANCE`:
      case `DEBT`:
      case `SAVING_INVESTMENT`:
      case `PENSION`:
        return account.accountHolder === 'Me' ? `me` : (account.accountHolder === 'Partner' ? `parter` : `all`)
  }
}

export default formatAccountUser
