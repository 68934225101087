import { AccountWrapper } from 'containers'
import { debt as schema } from 'config/schemas'
import { DebtForm } from 'components'
import { useParams } from 'react-router-dom'

const FinancesDebtsForm = ({
  profile,
  account,
  errors,
  success,
  handleChange,
  handleSubmit
}) => {
  return <AccountWrapper accountType="DEBT" schema={schema} form={DebtForm} location={useParams()} />;
}

export default FinancesDebtsForm
