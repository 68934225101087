import React from "react"
// import DayPickerInput from "react-day-picker/DayPickerInput"
// import "react-day-picker/lib/style.css"
// import { DateUtils } from "react-day-picker"
import "react-day-picker/dist/style.css"
import { Form } from 'react-bootstrap'


import DayPickerInput from './DayPickerInput'

// import MomentLocaleUtils, {
//   formatDate,
//   parseDate,
// } from "react-day-picker/moment"

// import "moment/locale/en-gb"




// <DayPickerInput
//   inputProps={{
//     className: `form-control ${inputClass} ${error ? "is-invalid" : ""}`,
//   }}
//   onDayChange={value => {
//     onChange({
//       target: {
//         id: id,
//         name: id,
//         value: value || ``,
//       },
//     })
//   }}
//   value={typeof value.toDate === "function" ? value.toDate() : value}
//   id={id}
//   formatDate={formatDate}
//   parseDate={parseDate}
//   format="L"
//   placeholder={`${formatDate(new Date(), "L", "en-gb")}`}
//   dayPickerProps={{
//     locale: "en-gb",
//     localeUtils: MomentLocaleUtils,
//   }}
//   // readOnly={readOnly}
// />



const DayPickerField = ({
  id,
  label,
  value,
  error,
  onChange = null,
  onBlur = null,
  mutedText = null,
  labelClass = null,
  inputClass = null,
  readOnly = false,
  placeholder = ``,
  isNumber = false,
}) => (
  <Form.Group className="mb-3">
    <Form.Label>{label}</Form.Label>
    {mutedText && <small className="form-text text-muted">{mutedText}</small>}
    <br />
    <DayPickerInput
      inputProps={{
        className: `form-control ${inputClass} ${error ? "is-invalid" : ""}`,
      }}
      onChange={value => {
        console.log(`setting date value`, value, id)
        onChange({
          target: {
            id: id,
            name: id,
            value: value || ``,
          },
        })
      }}
      value={typeof value.toDate === "function" ? value.toDate() : value}
      id={id}
    />

    {error && (
      <div className="invalid-feedback" style={{ display: `block` }}>
        {error}
      </div>
    )}
  </Form.Group>
)

export default DayPickerField
