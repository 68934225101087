const initialState = {}

export default (state = initialState, action) => {
  switch (action.type) {
    case "CREATE_SAVINGS":
      return state
    case "CREATE_SAVINGS_ERROR":
      return state
    case "CREATE_PENSION":
      return state
    case "CREATE_PENSION_ERROR":
      return state
    case "CREATE_INVESTMENT":
      return state
    case "CREATE_INVESTMENT_ERROR":
      return state
    case "CREATE_PROPERTY":
      return state
    case "CREATE_PROPERTY_ERROR":
      return state
    case "CREATE_INSURANCE":
      return state
    case "CREATE_INSURANCE_ERROR":
      return state

    case "UPDATE_FINANCIAL_ACCOUNT":
      return state
    case "UPDATE_FINANCIAL_ACCOUNT_ERROR":
      return state

    case "DELETE_FINANCIAL_ACCOUNT":
      return state
    case "DELETE_FINANCIAL_ACCOUNT_ERROR":
      return state

    case 'CREATE_ACCOUNT':
    case 'UPDATE_ACCOUNT':
      return {
        success: true,
        ...state
      }
      return state;

    // case typeName:
    //   return { ...state, ...payload }

    default:
      return state
  }
}
