import React, { useState } from 'react'
import { Button, Card, Col, Container, Form, Row, Table } from 'react-bootstrap';
import PropTypes from 'prop-types'
import { Outlet } from 'react-router-dom'
import { Header } from 'components'
import { CurrencyField, PercentageField, NumberField, RadioToggleField } from 'components/form'
import { FormWrapper } from 'containers'
import { calculateBreakdown, formatCurrency } from 'helpers'
import Joi from 'joi-browser'

// https://yarnpkg.com/package/financial
// https://financialjs.netlify.app/modules/_financial_.html#fv
import { fv } from 'financial'

const calculateTotalWealth = ({
  totalSavings,
  totalProperty,
  totalPensions,
  totalInvestments,
}) => {
  console.log({
    totalSavings: totalSavings,
    totalProperty: totalProperty,
    totalPensions: totalPensions,
    totalInvestments: totalInvestments
  })

  const total = totalSavings + totalProperty + totalPensions + totalInvestments;

  alert(`Total: ${formatCurrency(total)}`)

  return total
}

const calculateNetWealth = ({
  totalSavings,
  totalProperty,
  totalPensions,
  totalInvestments,
  totalDebt,
  totalMortgage,
}) => {
  console.log({
    totalSavings: totalSavings,
    totalProperty: totalProperty,
    totalPensions: totalPensions,
    totalInvestments: totalInvestments,
    totalDebt: totalDebt,
    totalMortgage: totalMortgage
  })

  const total = totalSavings + totalProperty + totalPensions + totalInvestments - totalDebt - totalMortgage;

  alert(`Total: ${formatCurrency(total)}`)

  return total
}

// https://yarnpkg.com/package/financial
const calculateCompoundInterest = ({ state }) => {

  const { pmt, pv, type, interestRate, interestPaymentFrequency, totalTimeYears } = state
  const rate = (interestRate / interestPaymentFrequency) / 100
  const nper = totalTimeYears / interestPaymentFrequency

  const total = fv(rate, nper, pmt, pv, 0)

  alert(`Total: ${formatCurrency(total)}`)

  console.log(total, {rate, nper, pmt, pv, type}, state)

  return total;
}

const CalculatorsPage = ({  }) => {
  return (
    <div className="main-content">
      <Container fluid>
        <PageHeader />
        <FormWrapper
          initialState={{}}
          schema={{
            defaultValues: {

            },
            validator: Joi.object().keys()
          }}
          component={CompoundInterestBreakdown}
          onSubmit={() => {}}
        />
        <FormWrapper
          initialState={{}}
          schema={{
            defaultValues: {
              interestRate: 5,
              interestPaymentFrequency: 1,
              // rate: 5,
              totalTimeYears: 5,
              pmt: 1200,
              pv: -50000,
            },
            validator: Joi.object().keys()
          }}
          component={CompoundInterestCalcultor}
          onSubmit={calculateCompoundInterest}
        />
        {/*<FormWrapper
          initialState={{
            totalSavings: 0.00,
            totalProperty: 0.00,
            totalPensions: 0.00,
            totalInvestments: 0.00,
            totalDebt: 0.00,
            totalMortgage: 0.00,
          }}
          schema={{}}
          component={TotalWealthCalcultor}
          onSubmit={calculateTotalWealth}
        />*/}
      </Container>
    </div>
  );
}


const CompoundInterestBreakdownForm = ({ state, errors, handleChange, handleSubmit }) => {
  return (
    <Form onSubmit={handleSubmit}>
      <Row className="align-items-center">
        <Col sm={3} className="my-1">
          <CurrencyField
            label="Present Value"
            name="pv"
            value={state.pv}
            error={errors.pv}
            onChange={handleChange}
            mutedText=""
          />
        </Col>
        <Col sm={3} className="my-1">
          <PercentageField
            label="Annual Interest Rate"
            name="interestRate"
            value={state.interestRate}
            error={errors.interestRate}
            onChange={handleChange}
            mutedText=""
          />
        </Col>
        <Col xs="auto" className="my-1">
          <CurrencyField
            label="Regular Deposit"
            name="pmt"
            value={state.pmt}
            error={errors.pmt}
            onChange={handleChange}
            mutedText=""
          />
        </Col>
        <Col xs="auto" className="my-1">
          <Button type="submit" className="lift w-100">Calculate</Button>
        </Col>
      </Row>
    </Form>
  )
}

const CompoundInterestBreakdown = ({  }) => {

  const [results, setResults] = useState([]);

  const calculateResults = ({ state: { pmt, pv, interestRate }}) => {
    const breakdown = calculateBreakdown({
      pmt,
      pv,
      interestRate: (interestRate / 100),
      periods: 24
    })

    setResults(breakdown)
  }

  return (
    <>
      <Container>
        <Row className="justify-content-between align-items-center mb-5">
          <Col xs={12} md={9} xl={7}>
            <h2 className="mb-2">Compound Interest Calculator</h2>
            <p className="text-muted mb-xl-0">
              Some description about Calculator that'll help us in the future
            </p>
          </Col>
          <Col xs={12} xl="auto">
            <Button variant="white">Reset?</Button>
          </Col>
        </Row>
        <Row className="justify-content-center">
          <Card>
            <Card.Body>
              <FormWrapper
                initialState={{}}
                schema={{
                  defaultValues: {
                    pmt: 1200,
                    interestRate: 5,
                    pv: 50000,
                  },
                  validator: Joi.object().keys()
                }}
                component={CompoundInterestBreakdownForm}
                onSubmit={calculateResults}
              />
              <Table size="sm" className="mb-0" hover responsive>
                <thead>
                  <tr>
                    <th scope="col">Period</th>
                    <th scope="col">New deposit at the start of the period</th>
                    <th scope="col">Principal at the start of the period</th>
                    <th scope="col">Amount at the end of the period</th>
                  </tr>
                </thead>
                <tbody>
                  {results.map(result => (
                    <tr>
                      <th scope="row">{result.period}</th>
                      <td>{result.deposit}</td>
                      <td>{formatCurrency(result.startingPrinciple)}</td>
                      <td>{formatCurrency(result.endingPrinciple)}</td>
                    </tr>
                  ))}
                </tbody>
              </Table>
            </Card.Body>
          </Card>
        </Row>
      </Container>
    </>
  )
}

const CompoundInterestCalcultor = ({ state, errors, handleChange, handleSubmit }) => {
  return (
    <>
      <Row className="justify-content-center">
        <Col xs={12} lg={10} xl={8} className="py-6">
          <Row className="justify-content-between align-items-center mb-5">
            <Col xs={12} md={9} xl={7}>
              <h2 className="mb-2">Compound Interest Calculator</h2>
              <p className="text-muted mb-xl-0">
                Some description about Calculator that'll help us in the future
              </p>
            </Col>
            <Col xs={12} xl="auto">
              <Button variant="white">Reset?</Button>
            </Col>
          </Row>
          <Row>
            <Col xs={12} md={6} className="order-md-2">
              <Card className="bg-light border ml-md-4">
                <Card.Body>
                  <p className="mb-2">Password requirements</p>
                  <p className="small text-muted mb-2">
                    To create a new password, you have to meet all of the following requirements:
                  </p>
                  <ul className="small text-muted ps-4 mb-0">
                    <li>Minimum 8 character</li>
                    <li>At least one special character</li>
                    <li>At least one number</li>
                    <li>Can’t be the same as a previous password</li>
                  </ul>
                </Card.Body>
              </Card>
            </Col>
            <Col xs={12} md={6} className="col-12 col-md-6">
              <form onSubmit={handleSubmit}>
                <PercentageField
                  label="Interest Rate (Yearly)"
                  name="interestRate"
                  value={state.interestRate}
                  error={errors.interestRate}
                  onChange={handleChange}
                  mutedText=""
                />
                <NumberField
                  label="Payment Frequency / Year"
                  name="interestPaymentFrequency"
                  value={state.interestPaymentFrequency}
                  error={errors.interestPaymentFrequency}
                  onChange={handleChange}
                  mutedText=""
                />
                <PercentageField
                  label="Interest per Period, rate"
                  name="rate"
                  value={state.interestRate / state.interestPaymentFrequency}
                  // error={errors.rate}
                  // onChange={handleChange}
                  mutedText="This is the 'rate' parameter in the FV calculation and is equal to Interest Rate / Payment Frequency"
                  readOnly={true}
                />
                <NumberField
                  label="Total Time (Years)"
                  name="totalTimeYears"
                  value={state.totalTimeYears}
                  error={errors.totalTimeYears}
                  onChange={handleChange}
                  mutedText=""
                />
                <NumberField
                  label="Number of Periods, nper"
                  name="interestPaymentFrequency"
                  value={state.totalTimeYears / state.interestPaymentFrequency}
                  // error={errors.interestPaymentFrequency}
                  // onChange={handleChange}
                  mutedText="This is the 'rate' parameter in the FV calculation and is equal to Total Time Years / Payment Frequency"
                  readOnly={true}
                />
                <CurrencyField
                  label="Payment per Period, pmt"
                  name="pmt"
                  value={state.pmt}
                  error={errors.pmt}
                  onChange={handleChange}
                  mutedText=""
                />
                <CurrencyField
                  label="Present Value"
                  name="pv"
                  value={state.pv}
                  error={errors.pv}
                  onChange={handleChange}
                  mutedText=""
                />
                <Button type="submit" className="lift w-100">Calculate</Button>
              </form>
            </Col>
          </Row>
        </Col>
      </Row>
    </>
  );
}

const TotalWealthCalcultor = ({ state, errors, handleChange, handleSubmit }) => {
  return (
    <>
      <Row className="justify-content-center">
        <Col xs={12} lg={10} xl={8} className="py-6">
          <Row className="justify-content-between align-items-center mb-5">
            <Col xs={12} md={9} xl={7}>
              <h2 className="mb-2">Total Wealth Calculator</h2>
              <p className="text-muted mb-xl-0">
                Some description about Calculator that'll help us in the future
              </p>
            </Col>
            <Col xs={12} xl="auto">
              <Button variant="white">Reset?</Button>
            </Col>
          </Row>
          <Row>
            <Col xs={12} md={6} className="order-md-2">
              <Card className="bg-light border ml-md-4">
                <Card.Body>
                  <p className="mb-2">Password requirements</p>
                  <p className="small text-muted mb-2">
                    To create a new password, you have to meet all of the following requirements:
                  </p>
                  <ul className="small text-muted ps-4 mb-0">
                    <li>Minimum 8 character</li>
                    <li>At least one special character</li>
                    <li>At least one number</li>
                    <li>Can’t be the same as a previous password</li>
                  </ul>
                </Card.Body>
              </Card>
            </Col>
            <Col xs={12} md={6} className="col-12 col-md-6">
              <form onSubmit={handleSubmit}>
                <CurrencyField
                  label="Total Savings"
                  name="totalSavings"
                  value={state.totalSavings}
                  error={errors.totalSavings}
                  onChange={handleChange}
                  mutedText=""
                />
                <CurrencyField
                  label="Total Property"
                  name="totalProperty"
                  value={state.totalProperty}
                  error={errors.totalProperty}
                  onChange={handleChange}
                  mutedText=""
                />
                <CurrencyField
                  label="Total Pensions"
                  name="totalPensions"
                  value={state.totalPensions}
                  error={errors.totalPensions}
                  onChange={handleChange}
                  mutedText=""
                />
                <CurrencyField
                  label="Total Investments"
                  name="totalInvestments"
                  value={state.totalInvestments}
                  error={errors.totalInvestments}
                  onChange={handleChange}
                  mutedText=""
                />
                <CurrencyField
                  label="Total Debt"
                  name="totalDebt"
                  value={state.totalDebt}
                  error={errors.totalDebt}
                  onChange={handleChange}
                  mutedText=""
                />
                <CurrencyField
                  label="Total Mortgage"
                  name="totalMortgage"
                  value={state.totalMortgage}
                  error={errors.totalMortgage}
                  onChange={handleChange}
                  mutedText="Might need to list Mortgages differently, revisit this at some point"
                />
                <Button type="submit" className="lift w-100">Calculate</Button>
              </form>
            </Col>
          </Row>
        </Col>
      </Row>
    </>
  );
}

const PageHeader = () => (
  <Row className="justify-content-center">
    <Col xs={12} lg={10} xl={8} className="py-6">
      <Header>
        <Header.Body>
          <Row className="align-items-center">
            <Col>
              <Header.Pretitle>development</Header.Pretitle>
              <Header.Title>Calculators</Header.Title>
            </Col>
            <Col xs="auto">
              {/*<Button>Reset all</Button>*/}
            </Col>
          </Row>
        </Header.Body>
      </Header>
    </Col>
  </Row>
)

export default CalculatorsPage
