import FeatherIcon from "feather-icons-react";
import PlusCircle from "feather-icons-react";
import React, { useState } from "react";
import {
  Alert,
  Button,
  ButtonGroup,
  Card,
  Col,
  Dropdown,
  ListGroup,
  OverlayTrigger,
  Row,
  Popover,
  ProgressBar,
} from "react-bootstrap";
import { Avatar } from "../";
import { Link } from "react-router-dom";
import { formatCurrency } from "helpers";

export default function AccountWidget({
  title,
  accounts,
  path = `/`,
  deleteAccount = null,
  id = null,
  totals,
  ...props
}) {
  const [user, setUser] = useState("all");

  // Get's the total value of all accounts
  const totalValue = totals?.reduce((sum, obj) => sum + obj.value, 0);

  // Get's the percentage of each asset, 100% being the total value of all assets
  const percentages = [];
  totals.forEach((obj) => {
    const percentage = (obj.value / totalValue) * 100;
    percentages.push(percentage.toFixed());
  });

  const [show, setShow] = useState(false);

  if (`all` !== user) {
    accounts = accounts.filter((account) => account.user === user);
  }

  const items = accounts.map((account, index) => (
    <>
      <Col xl={4} lg={6}>
        <Link to="/">
          <Card className="bg-white account-card">
            <Row>
              <Col className="col-8"></Col>
            </Row>
            <div className="card-statistic-3 p-4">
              <div className="account-icon">
                <Avatar>
                  {account.imgSrc && (
                    <Avatar.Image
                      className="rounded"
                      src={account.imgSrc}
                      alt="..."
                    />
                  )}
                  {!account.imgSrc && (
                    <Avatar.Title className="rounded bg-white text-secondary">
                      <FeatherIcon
                        icon={account.icon ? account.icon : `check-square`}
                        size="5em"
                      />
                    </Avatar.Title>
                  )}
                </Avatar>
              </div>
              <div className="mb-4">
                <h4 className="mb-0">{account.title}</h4>
              </div>
              <Row className="align-items-center mb-2 d-flex">
                <Col className="col-8">
                  <h3 className="d-flex align-items-center mb-0">
                    {" "}
                    {formatCurrency(account.value)} {account.extension}
                  </h3>
                </Col>
              </Row>
              <div data-height="8" style={{ height: `8px` }}>
                <ProgressBar className="progress-sm" now={percentages[index]} />
              </div>
              <span>
                {percentages[index]}% <i className="fa fa-arrow-up"></i>
              </span>
              <div className="mt-4">
                <h5 className="mb-0">
                  <Link to={`${path}/${account.id}`}>Edit</Link> |{" "}
                  <Link
                    to={`/`}
                    onClick={(e) => {
                      if (
                        window.confirm(
                          "Are you sure you want to delete this account? This action can not be reversed."
                        )
                      ) {
                        deleteAccount(account.id);
                      }
                    }}
                  >
                    Delete
                  </Link>
                </h5>
              </div>
            </div>
          </Card>
        </Link>
      </Col>

      {/* <ListGroup.Item key={index}>
        <Row className="align-items-center">
          <Col xs="auto">
            <Avatar>
              {account.imgSrc && (
                <Avatar.Image
                  className="rounded"
                  src={account.imgSrc}
                  alt="..."
                />
              )}
              {!account.imgSrc && (
                <Avatar.Title className="rounded bg-white text-secondary">
                  <FeatherIcon
                    icon={account.icon ? account.icon : `check-square`}
                    size="1em"
                  />
                </Avatar.Title>
              )}
            </Avatar>
          </Col>
          <Col className="ms-n2">
            <h4 className="fw-normal mb-1">{account.title}</h4>
            <Card.Text className="small text-muted">
              {formatCurrency(account.value)} {account.extension}
            </Card.Text>
          </Col>
          <Col xs="auto">
            <Dropdown align="end">
              <Dropdown.Toggle
                as="span"
                className="dropdown-ellipses"
                role="button"
              >
                <FeatherIcon icon="more-vertical" size="17" />
              </Dropdown.Toggle>
              <Dropdown.Menu>
                <Dropdown.Item as={Link} to={`${path}/${account.id}`}>
                  Edit
                </Dropdown.Item>
                <Dropdown.Item
                  as={Link}
                  to={`/`}
                  onClick={(e) => {
                    if (
                      window.confirm(
                        "Are you sure you want to delete this account? This action can not be reversed."
                      )
                    ) {
                      deleteAccount(account.id);
                    }
                  }}
                >
                  Delete
                </Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>
          </Col>
        </Row>
      </ListGroup.Item> */}
    </>
  ));

  return (
    <Card id={id} {...props} className="h-100">
      <Card.Header>
        <h2 className="card-header-title">{title}</h2>
        {
          <ButtonGroup size="sm" className="me-3">
            <Button
              variant={user === "all" ? "dark" : "secondary"}
              onClick={() => setUser("all")}
              active={user === "all"}
            >
              All
            </Button>
            <Button
              variant={user === "me" ? "dark" : "secondary"}
              onClick={() => setUser("me")}
              active={user === "me"}
            >
              Just Mine
            </Button>
            <Button
              variant={user === "partner" ? "dark" : "secondary"}
              onClick={() => setUser("partner")}
              active={user === "partner"}
            >
              My Partners
            </Button>
          </ButtonGroup>
        }

        {/* <Button
          variant="outline-primary"
          as={Link}
          to={`${path}/add`}
          size="md"
        >
          Add +
        </Button> */}
      </Card.Header>
      <Card.Body>
        <Row>
          {show && (
            <Alert variant="info" onClose={() => setShow(false)} dismissible>
              <Alert.Heading>Is your data up to date?</Alert.Heading>
              <p>
                We have noticed that you have not updated your data since{" "}
                <b>18/01/2022</b>. Please update your data to get the latest
                insights.
              </p>
            </Alert>
          )}
          {items}
          <Col md={4}>
            <Link to={`${path}/add`}>
              <Card className="d-flex justify-content-center align-items-center add-card">
                <FeatherIcon icon="plus" size="3em" className="add-icon" />
                <div className="add-text">Add</div>
              </Card>
            </Link>
          </Col>
        </Row>
        {/* <ListGroup className="list-group-flush my-n3">{items}</ListGroup> */}
      </Card.Body>
      {false && (
        <Card.Footer className="float-center text-center">
          <Link className="small" to={path}>
            View all
          </Link>
        </Card.Footer>
      )}
    </Card>
  );
}
