export default [
  { value: `Lloyds`, label: `Lloyds` },
  { value: `Nationwide`, label: `Nationwide` },
  { value: `Santander`, label: `Santander` },
  { value: `Royal Bank of Scotland`, label: `Royal Bank of Scotland` },
  { value: `Barclays`, label: `Barclays` },
  { value: `HSBC`, label: `HSBC` },
  { value: `First Direct`, label: `First Direct` },
  { value: `Habito`, label: `Habito` },
  { value: `Halifax`, label: `Halifax` },
  { value: `L&C`, label: `L&C` },
  { value: `M&S Bank`, label: `M&S Bank` },
  { value: `Mojo Mortgages`, label: `Mojo Mortgages` },
  { value: `NatWest`, label: `NatWest` },
  { value: `Paragon`, label: `Paragon` },
  { value: `Platform`, label: `Platform` },
  { value: `Post Office`, label: `Post Office` },
  { value: `Coventry Building Society`, label: `Coventry Building Society` },
  { value: `Yorkshire Building Society`, label: `Yorkshire Building Society` },
  { value: `The Mortgage Works`, label: `The Mortgage Works` },
  { value: `TSB`, label: `TSB` },
  { value: `Virgin Money`, label: `Virgin Money` },
  { value: `Yorkshire Bank`, label: `Yorkshire Bank` },
  { value: `Skipton Building Society`, label: `Skipton Building Society` },
]
