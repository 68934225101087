const success = `Saved successfully.`

export const saveBasics = basics => {
  return (dispatch, getState, { getFirebase, getFirestore }) => {
    // make async call to database
    const firestore = getFirestore()
    const profile = getState().firebase.profile
    const uid = getState().firebase.auth.uid

    firestore
      .collection("users")
      .doc(uid)
      .set(
        {
          ...basics.state,
          basicsComplete: true,
          userId: uid,
        },
        { merge: true }
      )
      .then((res) => {
        dispatch({ type: `SAVE_BASICS`, success, basics })
      })
      .then(res => {
        // navigate(`/account/wizard/income`)
      })
      .catch(err => {
        dispatch({ type: `SAVE_BASICS_ERROR`, err })
      })
  }
}

export const saveIncome = income => {
  return (dispatch, getState, { getFirebase, getFirestore }) => {
    // make async call to database
    const firestore = getFirestore()
    const profile = getState().firebase.profile
    const uid = getState().firebase.auth.uid

    firestore
      .collection("users")
      .doc(uid)
      .set(
        {
          ...income.state,
          incomeComplete: true,
          userId: uid,
        },
        { merge: true }
      )
      .then(() => {
        dispatch({ type: `SAVE_INCOME`, income })
      })
      .catch(err => {
        dispatch({ type: `SAVE_INCOME_ERROR`, err })
      })
  }
}

export const setViewedIntro = () => {
  return (dispatch, getState, { getFirebase, getFirestore }) => {
    // make async call to database
    const firestore = getFirestore()
    const profile = getState().firebase.profile
    const uid = getState().firebase.auth.uid

    console.log('Saving?');

    firestore
      .collection("users")
      .doc(uid)
      .set(
        {
          viewedIntro: true,
          userId: uid,
        },
        { merge: true }
      )
      .then(() => {
        dispatch({ type: `SAVE_VIEWED_INTRO` })
      })
      .catch(err => {
        dispatch({ type: `SAVE_VIEWED_INTRO_ERROR`, err })
      })
  }
}


export const saveAboutYou = aboutYouData => {
  return (dispatch, getState, { getFirebase, getFirestore }) => {
    // make async call to database
    const firestore = getFirestore()
    const profile = getState().firebase.profile
    const uid = getState().firebase.auth.uid

    firestore
      .collection("users")
      .doc(uid)
      .set(
        {
          ...aboutYouData.state,
          basicsComplete: true,
          userId: uid,
        },
        { merge: true }
      )
      .then((res) => {
        dispatch({ type: `SAVE_ABOUT_YOU`, success, aboutYouData })
      })
      .then(res => {
        // navigate(`/account/wizard/income`)
      })
      .catch(err => {
        dispatch({ type: `SAVE_ABOUT_YOU_ERROR`, err })
      })
  }
}

export const saveAboutPartner = aboutPartnerData => {
  return (dispatch, getState, { getFirebase, getFirestore }) => {
    // make async call to database
    const firestore = getFirestore()
    const profile = getState().firebase.profile
    const uid = getState().firebase.auth.uid

    firestore
      .collection("users")
      .doc(uid)
      .set(
        {
          ...aboutPartnerData.state,
          includesPartnerInfo: true,
          basicsComplete: true,
          userId: uid,
        },
        { merge: true }
      )
      .then((res) => {
        dispatch({ type: `SAVE_ABOUT_PARTNER`, success, aboutPartnerData })
      })
      .then(res => {
        // navigate(`/account/wizard/income`)
      })
      .catch(err => {
        dispatch({ type: `SAVE_ABOUT_PARTNER_ERROR`, err })
      })
  }
}

