import BankProviders from './banks'
import InvestmentProviders from './investment'
import CreditCardProviders from './credit-cards'
import LoanProviders from './loan'
import MortgageProviders from './mortgage'
import CryptoProviders from './crypto'

export default {
  investments: InvestmentProviders,
  getLenderByType: (type) => {
    switch (type) {
      case `Mortgage`:
        return MortgageProviders
      case `Credit Card`:
        return CreditCardProviders
      case `Car Finance`:
      case `Personal Loan`:
        return LoanProviders
      case `Student Loan`:
      case `Other`:
      default:
        return [{ value: `Other`, label: `Other` }]
    }
  },
  getByAccountType: (type) => {
    switch (type) {
      case `Current Account`:
      case `Savings Account`:
      case `Cash ISA`:
      case `Cash LISA`:
      case `Business Account`:
        return BankProviders;
      case `Stocks ISA`:
      case `Stocks (non ISA)`:
      case `Junior ISA`:
      case `Lifetime ISA`:
        return InvestmentProviders;
      case `Crypto Wallet Balance(s)`:
        return CryptoProviders;
      case `My Business Shares`:
      case `Other`:
      default:
        return [{ value: `Other`, label: `Other` }]
    }
  }
}
