import { useState } from "react";
import { Badge, Button, Card, Form } from "react-bootstrap";

import CountUp from "react-countup";
import { useInView } from "react-intersection-observer";

export default function DebtFreeWidget({
  data,
  property,
  value = 0.0,
  ...props
}) {
  const { ref, inView } = useInView({
    threshold: 0.5, // Countdown becomes visible when at least 50% of it is visible in the viewport
  });

  const { outgoings } = data;
  const [includeMortgage, setIncludeMortgage] = useState(false);
  const mortgageRepayments = outgoings.mortgage;
  const debtRepayments =
    outgoings.debtRepayments + (includeMortgage ? mortgageRepayments : 0);

  const mortgageIncluded = includeMortgage ? property : 0;
  const debtTotal = Math.abs(value) + mortgageIncluded;
  const repaymentTime = calculateDebtRepaymentTime(debtTotal, debtRepayments);

  function calculateDebtRepaymentTime(debtTotal, debtRepayments) {
    const total = Number(debtTotal);
    const repayment = Number(debtRepayments);

    const months = Math.ceil(total / repayment);
    const years = Math.floor(months / 12);
    const remainingMonths = months % 12;

    let currentDate = new Date();
    currentDate.setMonth(currentDate.getMonth() + remainingMonths);
    currentDate.setFullYear(currentDate.getFullYear() + years);

    const options = { day: "2-digit", month: "2-digit", year: "numeric" };
    const formattedDate = currentDate.toLocaleDateString("en-GB", options);

    return {
      years: years,
      months: remainingMonths,
      debtFreeDate: formattedDate,
    };
  }

  return (
    <Card className="debtfree-widget" {...props}>
      <Card className="debtfree-image"></Card>

      <Card.Body className="debtfree-content d-flex flex-column text-center justify-content-center">
        <div className="d-flex justify-content-between mb-4 mt-n4">
          <Form>
            <Form.Check
              type="switch"
              id="custom-switch"
              label="Inc. mortgage"
              className="text-light"
              onChange={(e) => setIncludeMortgage(e.target.checked)}
            />
          </Form>
          <Badge
            bg="success"
            // className="position-absolute"
            // style={{ top: 20, right: 20 }}
          >
            New!
          </Badge>
        </div>
        <div className="position-relative">
          <h1 className="text-light">Debt free in</h1>
          <div className="d-flex justify-content-center">
            <div
              className="d-flex fs-1 text-light justify-content-center me-3"
              ref={ref}
            >
              {inView && (
                <CountUp
                  start={0}
                  end={repaymentTime.years}
                  delay={0}
                  duration={2.75}
                >
                  {({ countUpRef }) => (
                    <div>
                      <span ref={countUpRef} />
                    </div>
                  )}
                </CountUp>
              )}
              <span className="ms-2 mt-3 fs-4">Years</span>
            </div>

            <div
              className="d-flex fs-1 text-light justify-content-center me-3"
              ref={ref}
            >
              {inView && (
                <CountUp
                  start={0}
                  end={repaymentTime.months}
                  delay={0}
                  duration={2.75}
                >
                  {({ countUpRef }) => (
                    <div>
                      <span ref={countUpRef} />
                    </div>
                  )}
                </CountUp>
              )}
              <span className="ms-2 mt-3 fs-4">Months</span>
            </div>
          </div>
          <div>
            <p className="text-light mt-3">
              Debt free date: {repaymentTime.debtFreeDate}
            </p>
          </div>
          <div>
            <Button
              variant="outline-light"
              className="mt-1 stretched-link"
              href="/disaster-plan"
            >
              See breakdown
            </Button>
          </div>
        </div>
      </Card.Body>
    </Card>
  );
}
