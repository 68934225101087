import React from 'react';
import { Badge, Button, Card, Col, Row } from 'react-bootstrap';
import image from 'assets/img/files/call-image.png';

export default function DashboardMarketingWidget({ ...props }) {
  return (
    <Card className="bg-light h-100 " {...props}>
      <Card.Body className="text-center d-flex flex-column justify-content-center align-items-center">
        <h3>Liking our app?</h3>
        <p className="text-muted">
          Early-adopters please book in to give us your ideas and feedback
        </p>
        <Button variant="outline-secondary">
          <a href="https://calendar.app.google/kT1QJ5duBJCRFkiUA" target="_blank">Book an appointment 🗓</a>
        </Button>
      </Card.Body>
    </Card>
  );
}
