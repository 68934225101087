import FeatherIcon from 'feather-icons-react';
import { Link } from 'react-router-dom';
import React from 'react';
import { Button, Col, Form, InputGroup, Row } from 'react-bootstrap';
import logo from 'assets/img/wealthsteer-logo.png';

export default function SignUpForm({ onChange, onSubmit, authError }) {
  return (
    <>
      <h1 className="display-5 text-center mb-3">
        <img className="navbar-brand-img" src={logo} alt="..." />
        <span> | Sign up </span>
        <span className="badge bg-dark-soft small"  style={{fontSize: `10px`}}>beta</span>
      </h1>
      <p className="text-muted text-center mb-5">Free access to our dashboard.</p>
      <form onSubmit={onSubmit}>
        <div className="form-group">
          <Form.Label>Email Address</Form.Label>
          <Form.Control type="email" placeholder="name@address.com" name="email" onChange={onChange} />
        </div>
        <div className="form-group">
          <Row>
            <Col>
              <Form.Label>Password</Form.Label>
            </Col>
            <Col xs="auto">
              <Form.Text as={Link} to="/forgot-password" className="small text-muted">
                Forgot password?
              </Form.Text>
            </Col>
          </Row>
          <InputGroup className="input-group-merge">
            <Form.Control type="password" placeholder="Enter your password" name="password" onChange={onChange} />
            <InputGroup.Text>
              <FeatherIcon icon="eye" size="1em" />
            </InputGroup.Text>
          </InputGroup>
        </div>
        {authError && (
          <div className="alert alert-danger">{authError}</div>
        )}
        <Button type="submit" size="lg" className="w-100 mb-3">
          Sign up
        </Button>
        <p className="text-center">
          <small className="text-muted text-center">
            Already have an account?{' '}
            <Link to="/sign-in">
              Log in
            </Link>
            .
          </small>
        </p>
      </form>
    </>
  );
}
