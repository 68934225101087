import React from "react"
import Input from "react-phone-number-input/input"
import { Form } from 'react-bootstrap'

const PhoneNumberField = ({
  id,
  label,
  value,
  error,
  onChange = null,
  onBlur = null,
  mutedText = null,
  labelClass = null,
  inputClass = null,
  readOnly = false,
  placeholder = ``,
  isNumber = false,
}) => (
  <Form.Group className="mb-3">
    <Form.Label className={labelClass}>{label}</Form.Label>
    {mutedText && <small className="form-text text-muted">{mutedText}</small>}
    <Input
      className={`form-control ${inputClass} ${error ? "is-invalid" : ""}`}
      id={id}
      value={value}
      country="GB"
      onChange={value => {
        onChange({
          target: {
            id: id,
            name: id,
            value: value || ``,
          },
        })
      }}
      onBlur={onBlur}
      readOnly={readOnly}
      placeholder="00000 000000"
      withCountryCallingCode
    />
    {error && <div className="invalid-feedback">{error}</div>}
  </Form.Group>
)

export default PhoneNumberField
