import { useState, useEffect } from 'react'
import FeatherIcon from 'feather-icons-react';
import { Button, Card, CloseButton, Col, Form, InputGroup, ListGroup, Modal as BootstrapModal, Row } from 'react-bootstrap';

const Modal = ({ buttonText, heading, children, isVisible = false, onClose = null, ...props }) => {
  const [visible, setVisible] = useState(isVisible);

  useEffect((props) => {
    if (false === visible && typeof onClose === `function`) {
      onClose();
    }
  }, [visible]);

  return (
    <>
      <a onClick={() => setVisible(true)}>
        {buttonText && (buttonText + " ")}
        <span className="fe fe-info"></span>
      </a>
      <BootstrapModal show={visible} onHide={() => setVisible(false)} centered {...props}>
        <Card className="modal-card">
          <Card.Header>
            <h4 className="card-header-title">{heading}</h4>
            <CloseButton onClick={() => setVisible(false)} />
          </Card.Header>
          <Card.Body>
            {children}
          </Card.Body>
        </Card>
      </BootstrapModal>
    </>
  );
}

export default Modal
