import React, { useState } from "react";
import { Col, Container, Nav, Row } from "react-bootstrap";
import { Line } from "react-chartjs-2";
import { Header } from "..";
import { Chart } from "../vendor";
import { formatCurrency } from "helpers";
import { ModalTotalWealth } from "components/modals";

export default function DashboardHeader({
  totalWealth = 0.0,
  netWealth = 0.0,
  data,
  profile,
  ...props
}) {
  const [index, setIndex] = useState(0);
  const firstName = profile && profile.fullName ? profile.fullName.split(' ')[0] : '';

  const breakdowns = {
    // investments: calculateBreakdowns({  })
  };

  // https://stackoverflow.com/questions/22064577/limit-labels-number-on-chart-js-line-chart
  // const labels = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
  // const labels = ['Now', '', 'Year 1', '', 'Year 2', '', 'Year 3', '', 'Year 4', '', 'Year 5'];

  const labels = [`Now`];
  for (var i = 1; i < 61; i++) {
    // labels.push(i)

    // console.log(i, i % 12, i % 12 === 0, i / 12)
    labels.push(i % 12 === 0 ? `Year ${i / 12}` : "");

    // console.log(i, i % 2, i % 2 === 0)
    // labels.push(i % 2 === 0 ? i : '')
  }

  // console.log(labels)

  const datasets = [
    {
      data: data.totalWealth.map((breakdown) => breakdown.endingPrinciple),
      yAxisID: "yAxisOne",
      xAxisID: "xAxisOne",
    },
    {
      data: [50, 75, 35, 25, 55, 87, 67, 53, 25, 80, 87, 45],
      yAxisID: "yAxisOne",
    },
  ];

  const options = {
    plugins: {
      tooltip: {
        enabled: false,
      },
      title: {
        display: true,
        text: (ctx) => "Point Style: " + ctx.chart.data.datasets[0].pointStyle,
      },
    },
    scales: {
      yAxisOne: {
        display: "auto",
        grid: {
          color: "#283E59",
        },
        pointStyle: "circle",
        pointLabel: {
          display: false,
        },
        ticks: {
          callback: function (value) {
            return `£${Math.round(value / 1000)}k`;
          },
        },
      },
      yAxisTwo: {
        display: "auto",
        grid: {
          color: "#283E59",
        },
        ticks: {
          callback: function (value) {
            return value + "%";
          },
        },
      },
    },
  };

  return (
    <Header
      className="pb-5"
      style={{ backgroundColor: "black", height: "35vh" }}
    >
      <Container>
        <Header.Body>
          <Row className="align-items-end mt-6">
            <Col lg={3}>
              <Header.Title
                as="h1"
                className="text-white"
                style={{ fontSize: "30px" }}
              >
                Welcome, {firstName}
              </Header.Title>
              <Header.Title as="h3" className="text-secondary mt-2">
                To your Wealth Dashboard
              </Header.Title>
              <Header.Tabs>
                <Nav.Item>
                  <Nav.Link
                    className="text-center"
                    active={index === 0}
                    onClick={() => setIndex(0)}
                    role="button"
                  >
                    <Header.Pretitle className="text-secondary mb-1 d-flex justify-content-start text-capitalize fs-4">
                      <ModalTotalWealth buttonText="Total Wealth" />
                    </Header.Pretitle>
                    <h3 as="h1" className="h2 text-white mb-0">
                      {formatCurrency(totalWealth) /*73.2k*/}
                    </h3>
                  </Nav.Link>
                </Nav.Item>
              </Header.Tabs>
            </Col>
            <Col md={3} className="d-flex justify-content-end pe-4"></Col>
          </Row>
        </Header.Body>
        <Header.Footer>
          {/* <Row>
            <Chart>
              <Line data={{ labels: labels, datasets: [{ ...datasets[index] }] }} options={options} />
			  {console.log(labels)}
            </Chart>
          </Row> */}
          {/* <Row>
            <Header.Pretitle className="text-secondary text-center">
              <ModalTotalWealth buttonText="How did we calculate this?" />
            </Header.Pretitle>
          </Row> */}
        </Header.Footer>
      </Container>
    </Header>
  );
}
