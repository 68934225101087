export default [
  { value: `American Express`, label: `American Express` },
  { value: `AA`, label: `AA` },
  { value: `Amazon`, label: `Amazon` },
  { value: `Apple`, label: `Apple` },
  { value: `Asda Money`, label: `Asda Money` },
  { value: `Barclaycard`, label: `Barclaycard` },
  { value: `Bank of Scotland`, label: `Bank of Scotland` },
  { value: `RBS`, label: `RBS` },
  { value: `Capital One`, label: `Capital One` },
  { value: `Clydesdale Bank`, label: `Clydesdale Bank` },
  { value: `Debenhams`, label: `Debenhams` },
  { value: `First Direct`, label: `First Direct` },
  { value: `HSBC`, label: `HSBC` },
  { value: `Halifax`, label: `Halifax` },
  { value: `John Lewis`, label: `John Lewis` },
  { value: `Lloyds`, label: `Lloyds` },
  { value: `M&S Bank`, label: `M&S Bank` },
  { value: `MBNA`, label: `MBNA` },
  { value: `Metro Bank`, label: `Metro Bank` },
  { value: `Tesco`, label: `Tesco` },
  { value: `Co-operative`, label: `Co-operative` },
  { value: `TSB`, label: `TSB` },
  { value: `Ulster Bank`, label: `Ulster Bank` },
  { value: `Virgin Money`, label: `Virgin Money` },
  { value: `Santander`, label: `Santander` },
  { value: `Yorkshire Bank`, label: `Yorkshire Bank` },
  { value: `Zopa`, label: `Zopa` },
]
