import PropTypes from 'prop-types'
import { Button, Form } from 'react-bootstrap';
import { Dropzone, Flatpickr, Quill, Select } from 'components/vendor';
import FeatherIcon from 'feather-icons-react';
import { FormWrapper } from 'containers'
import { BasicsForm } from 'components'
import { basics as schema } from 'config/schemas'
import { Spinner } from 'react-bootstrap';
import { toast } from 'react-toastify';

// Data
import { connect } from "react-redux"
import { saveBasics } from "store/actions/userActions"

const ProfileBasics = ({ auth, profile, saveBasics }) => {
  const activeStep = 1
  const stepCount = 2

  if (!profile.isLoaded) {
    return <Spinner />;
  }

  const component = ({ handleSubmit, ...props}) => {
    return (
      <>
        <form onSubmit={handleSubmit}>
          <BasicsForm {...props} />
          <Button size="lg" type="submit">
            Save Changes
          </Button>
        </form>
      </>
    )
  }

  return (
    <>
      <FormWrapper
        schema={schema}
        component={component}
        auth={auth}
        state={profile}
        onSubmit={saveBasics}
        onSuccess={() => {
          toast.success('Basics saved successfully.')
        }}
      />
    </>
  );
}


const mapStateToProps = state => {
  return {
    auth: state.firebase.auth,
    profile: state.firebase.profile,
  }
}

const mapDispatchToProps = dispatch => {
  return {
    saveBasics: basics => dispatch(saveBasics(basics)),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(ProfileBasics)

// export default ProfileBasics
