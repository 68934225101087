import { Col, Container, Nav, Row } from 'react-bootstrap';
import PropTypes from 'prop-types'
import { Outlet } from 'react-router-dom'
import { Header, TabbedNavigation } from 'components'
import { financeLinks as links } from 'config/routing'

const Finances = ({ }) => {
  return (
    <div
      className="main-content bg-fixed-bottom"
      // style={{
      //   backgroundImage: 'url(/footer.svg)',
      // }}
    >
      <Container>
        <Row className="justify-content-center">
          <Col xs={12} lg={10} xl={8} className="py-6">
            <Outlet />
          </Col>
        </Row>
      </Container>
    </div>
  );
}

export default Finances
