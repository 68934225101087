import { Badge, Card, Col, Dropdown, FormControl, InputGroup, Row, Table } from 'react-bootstrap';
import { formatCurrency } from 'helpers';

export default function InsuranceCoverTable({ personOne, personTwo, title = ``, ...props }) {
  // personTwo = true;


  console.log(title, personOne, personTwo)

  console.log('why', personTwo.min <= 0, personTwo.min)

  const classPositive = title === `Life insurance position` ? `table-danger` : `table-success`;
  const classNegative = title === `Life insurance position` ? `table-success` : `table-danger`;

  return (
    <Card>
      <Table className="card-table" responsive>
        <thead>
          <tr>
            <th>{title}</th>
            <th className="text-center">You</th>
            {personTwo && (<th className="text-center">Your partner</th>)}
          </tr>
        </thead>
        <tbody>
          <tr>
            <th>Minimum suggested cover</th>
            <td className="text-center">{formatCurrency(personOne.min)}</td>
            {personTwo && (
              <td className="text-center">{formatCurrency(personTwo.min)}</td>
            )}
          </tr>
          <tr>
            <th>Shortfall / Surplus</th>
              <td className={`text-center ${personOne.minShortfall <= 0 ? classPositive : classNegative}`}>{formatCurrency(personOne.minShortfall)}</td>
            {personTwo && (
              <td className={`text-center ${personTwo.minShortfall <= 0 ? classPositive : classNegative}`}>{formatCurrency(personTwo.minShortfall)}</td>
            )}
          </tr>
          <tr>
            <th>Maximum suggested cover</th>
              <td className="text-center">{formatCurrency(personOne.max)}</td>
            {personTwo && (
              <td className="text-center">{formatCurrency(personTwo.max)}</td>
            )}
          </tr>
          <tr>
            <th>Shortfall / Surplus</th>
            <td className={`text-center ${personOne.maxShortfall <= 0 ? classPositive : classNegative}`}>{formatCurrency(personOne.maxShortfall)}</td>
            {personTwo && (
              <td className={`text-center ${personTwo.maxShortfall <= 0 ? classPositive : classNegative}`}>{formatCurrency(personTwo.maxShortfall)}</td>
            )}
          </tr>
        </tbody>
      </Table>
    </Card>
  );
}
