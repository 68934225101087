import PropTypes from 'prop-types'
import { Button, Card, Col, Container, Form, Nav, Row } from 'react-bootstrap';
import { Dropzone, Flatpickr, Quill, Select } from 'components/vendor';
import FeatherIcon from 'feather-icons-react';
import { WizardFooter, WizardHeader } from 'components';
import { FormWrapper } from 'containers'
import { PeopleWidget } from 'components/widgets'
import { useNavigate } from "react-router-dom";

import { Spinner } from 'react-bootstrap';
import { toast } from 'react-toastify';

// Data
import { connect } from "react-redux"
import { saveBasics } from "store/actions/userActions"

const GettingStartedPeople = ({ auth, profile, saveBasics }) => {
  let navigate = useNavigate();
  const activeStep = 2
  const stepCount = 2

  if (!profile.isLoaded) {
    return <Spinner />;
  }

  const summary = profile.includesPartnerInfo ? `Your users` : `Do you want to include your partner in our calculations?`;

  return (
    <>
      <form>
        <WizardHeader
          title={`Your users`}
          summary={summary}
          activeStep={activeStep}
          stepCount={stepCount}
        />
        <PeopleWidget gettingStarted={true} profile={profile} />
        <WizardFooter
          activeStep={activeStep}
          stepCount={stepCount}
          cancelPath={`/getting-started/about-you`}
          continuePath={`/`}
        />
      </form>
    </>
  )

}


const mapStateToProps = state => {
  return {
    auth: state.firebase.auth,
    profile: state.firebase.profile,
  }
}

const mapDispatchToProps = dispatch => {
  return {
    // saveBasics: basics => dispatch(saveBasics(basics)),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(GettingStartedPeople)
