export default [
  { value: `Nationwide`, label: `Nationwide` },
  { value: `Halifax`, label: `Halifax` },
  { value: `NatWest`, label: `NatWest` },
  { value: `Barclaycard`, label: `Barclaycard` },
  { value: `Monzo`, label: `Monzo` },
  { value: `Starling`, label: `Starling` },
  { value: `Barclays`, label: `Barclays` },
  { value: `Co-Op`, label: `Co-Op` },
  { value: `Lloyds`, label: `Lloyds` },
  { value: `Tesco`, label: `Tesco` },
  { value: `NS&I`, label: `NS&I` },
  { value: `Bank of Scotland`, label: `Bank of Scotland` },
  { value: `TSB`, label: `TSB` },
  { value: `RBS`, label: `RBS` },
  { value: `M&S Bank`, label: `M&S Bank` },
  { value: `Yorkshire BS`, label: `Yorkshire BS` },
  { value: `Britannia`, label: `Britannia` },
  { value: `First Direct`, label: `First Direct` },
  { value: `Virgin Money`, label: `Virgin Money` },
]
