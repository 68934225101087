import PropTypes from 'prop-types'
import { Col, Row } from 'react-bootstrap'
import { TextField, CurrencyField } from 'components/form'

const IncomeForm = ({
  profile,
  state,
  errors,
  success,
  handleChange,
  handleSubmit
}) => (
  <form onSubmit={handleSubmit}>
    {success && <div className="alert alert-success">{success}</div>}

    <CurrencyField
      label="What is your after tax monthly income?"
      mutedText="If you have multiple income sources or variable income, please try to make an estimated monthly average."
      inputClass="form-control"
      name="totalIncome"
      id="totalIncome"
      placeholder="£"
      value={state?.totalIncome}
      onChange={handleChange}
      error={errors?.totalIncome}
    />

    <CurrencyField
      label="What is your annual salary before tax?"
      inputClass="form-control"
      name="annualSalary"
      id="annualSalary"
      placeholder="£"
      mutedText="Please try to estimate your annual pay before tax but ideally after big deductions like car, healthcare, pension etc."
      value={state?.annualSalary}
      onChange={handleChange}
      error={errors?.annualSalary}
    />

    {profile?.includesPartnerInfo && (
      <div>
        <CurrencyField
          label="What is your partners after-tax monthly income?"
          mutedText="If your partner has multiple income sources or variable income, please try to make an estimated monthly average."
          inputClass="form-control"
          name="totalIncomePartner"
          id="totalIncomePartner"
          placeholder="£"
          value={state.totalIncomePartner}
          onChange={handleChange}
          error={errors.totalIncomePartner}
        />

        <CurrencyField
          label="What is your partners annual salary before tax?"
          inputClass="form-control"
          name="annualSalaryPartner"
          id="annualSalaryPartner"
          placeholder="£"
          value={state.annualSalaryPartner}
          onChange={handleChange}
          error={errors.annualSalaryPartner}
        />
      </div>
    )}

    <CurrencyField
      label="What is your monthly rent?"
      mutedText="If you own your home or make a mortgage payment, don’t worry, that is on the main dashboard."
      inputClass="form-control"
      name="totalPaidRent"
      id="totalPaidRent"
      placeholder="£"
      value={state?.totalPaidRent}
      onChange={handleChange}
      error={errors?.totalPaidRent}
    />

    <CurrencyField
      label="Approximately how much are your monthly bills?"
      mutedText="Please just include ‘the basics’ e.g. electricity, water etc, we ask you about things like mortgages, credit cards and regular savings later on."
      inputClass="form-control"
      name="totalFixedBills"
      id="totalFixedBills"
      value={state?.totalFixedBills}
      onChange={handleChange}
      error={errors?.totalFixedBills}
    />
{/*
    <button className="btn btn-primary">Save changes</button>*/}
  </form>
)

IncomeForm.propTypes = {}

export default IncomeForm
