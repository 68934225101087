import { AccountWrapper } from 'containers'
import { insurance as schema } from 'config/schemas'
import { InsuranceForm } from 'components'
import { useParams } from 'react-router-dom'

const FinancesInsurancesForm = ({
  profile,
  account,
  errors,
  success,
  handleChange,
  handleSubmit
}) => {
  return <AccountWrapper accountType="INSURANCE" schema={schema} form={InsuranceForm} location={useParams()} />;
}

export default FinancesInsurancesForm
