import React from "react";
import PropTypes from "prop-types";
import { Col, Container, Row } from "react-bootstrap";
import { Header } from "components";
import { HashLink } from "react-router-hash-link";
import { ModalDashboardIntro } from "components/modals";

import {
  AccountWidget,
  ActionsWidget,
  DashboardHeader,
  DashboardMarketingWidget,
  DebtFreeWidget,
  DisasterPlanWidget,
  CashflowChart,
  DebtOverview,
  InsuranceAnalysisWidget,
  InvestmentsOverview,
  EmergencyFundOverview,
  EmergencyFundWidget,
  NotificationTimelineWidget,
  PensionOverview,
  PeopleWidget,
  PropertyOverview,
  RetirementModellerWidget,
  SavingsOverview,
  TotalWealthWidget,
} from "components/widgets";

import { DisasterPlan } from 'components'

const Dashboard = ({
  overview,
  savingsAndInvestments,
  debts,
  pensions,
  property,
  profile,
  insurances,
  deleteAccount,
  setViewedIntro,
  chartData,
  accountData,
  viewedIntro = false,
}) => {
  return (
    <div className="main-content">
      <DashboardHeader data={chartData} profile={profile} {...overview} />
      <Container className="mt-n4 mt-md-n6 mt-lg-n8">
        {/* <Row className="mt-n4"> */}
          {/* <Col xs={12} lg={6} xl>
            <HashLink to="#policies-and-assets">
              <InvestmentsOverview value={overview.investments} />
            </HashLink>
          </Col>
          <Col xs={12} lg={6} xl>
            <HashLink to="#policies-and-assets">
              <PropertyOverview value={overview.property} />
            </HashLink>
          </Col>
          <Col xs={12} lg={6} xl>
            <HashLink to="#policies-and-assets">
              <DebtOverview value={overview.debt} />
            </HashLink>
          </Col>
          <Col xs={12} lg={6} xl>
            <HashLink to="#policies-and-assets">
              <SavingsOverview value={overview.savings} />
            </HashLink>
          </Col> */}
          {/*
            We might need to hide pension overview depending on pension types
          */}
          {/* {true && (
            <Col xs={12} lg={6} xl>
              <HashLink to="#policies-and-assets">
                <PensionOverview value={overview.retirementFund} />
              </HashLink>
            </Col>
          )} */}
        {/* </Row> */}
        <Row className="">
          <Col xs={{ span: 12, order: 2}} lg={{ span: 6, order: 1}} className="d-flex flex-column justify-content-center">
          <PeopleWidget title={`People`} profile={profile} />
          </Col>
          <Col xs={{ span: 12, order: 1}} lg={{ span: 6, order: 2}} className="mb-5">
            <CashflowChart data={chartData.cashflow} />
          </Col>
        </Row>
        <Row>
          {/* <Col xs={12} xl={12}>
          <TotalWealthWidget data={chartData} {...overview} />
          </Col>
          <Col xs={12} xl={12}>
            <NotificationTimelineWidget />
          </Col> */}
          {/* <Col xs={12} xl={6}>
            <CashflowChart />
            <EmergencyFundWidget />
          </Col>
          <Col xs={12} xl={4}>
            <ActionsWidget />
          </Col>*/}
        </Row>
        <Row className="mt-lg-n6">
          <Header id="policies-and-assets">
            <Header.Body>
              <Header.Pretitle>Overview</Header.Pretitle>
              <Header.Title>Policies & Assets</Header.Title>
            </Header.Body>
          </Header>
        </Row>
        <Row>
          <Col xs={12} xl={12} md={12} className="mb-5">
            <AccountWidget
              id="savings-and-investments"
              path="/finances/savings-and-investments"
              title="Savings & Investments"
              accounts={savingsAndInvestments}
              deleteAccount={deleteAccount}
              totals={savingsAndInvestments}
            />
          </Col>
          <Col xs={12} xl={12} md={12} className="mb-5">
            <AccountWidget
              id="debts"
              path="/finances/debts"
              title="Debts"
              accounts={debts}
              deleteAccount={deleteAccount}
              totals={debts}
            />
          </Col>
          <Col xs={12} xl={12} md={12} className="mb-5">
            <AccountWidget
              id="pensions"
              path="/finances/pensions"
              title="Pensions"
              accounts={pensions}
              deleteAccount={deleteAccount}
              totals={pensions}
            />
          </Col>
        </Row>
        <Row>
          <Col xs={12} xl={12} className="mb-5">
            <AccountWidget
              id="properties"
              path="/finances/properties"
              title="Property"
              accounts={property}
              deleteAccount={deleteAccount}
              totals={property}
            />
          </Col>
          <Col xs={12} xl={12} className="mb-5">
            <AccountWidget
              id="insurance"
              path="/finances/insurance"
              title="Insurance"
              accounts={insurances}
              deleteAccount={deleteAccount}
              totals={insurances}
            />
          </Col>
          <Col xs={12} xl={4} className="mb-5">
            <DashboardMarketingWidget />
          </Col>
        </Row>

        <Row>
          <Header id="policies-and-assets">
            <Header.Body>
              <Header.Pretitle>Features</Header.Pretitle>
              <Header.Title>Your toolkit</Header.Title>
            </Header.Body>
          </Header>
        </Row>

        <Row className="">
          <DisasterPlan profile={profile} accounts={accountData} />
        </Row>

        <Row>
          <Col xs={12} lg={8}>
            <DisasterPlanWidget />
          </Col>
          {overview.debt < 0 && (
            <Col xs={12} lg={4}>
              <DebtFreeWidget
                value={overview.debt}
                property={overview.property}
                data={chartData.cashflow}
              />
            </Col>
          )}
        </Row>
        <Row>
          <Col xs={12} lg={4}>
            <InsuranceAnalysisWidget />
          </Col>
          <Col xs={12} lg={8}>
            <RetirementModellerWidget />
          </Col>
        </Row>
      </Container>
      <ModalDashboardIntro isVisible={!viewedIntro} onClose={setViewedIntro} />
    </div>
  );
};

Dashboard.propTypes = {};

export default Dashboard;
