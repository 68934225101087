import React from 'react'
import { connect } from 'react-redux'
import { Navigate } from 'react-router-dom'

const PrivateRoute = ({ component: Component, location, auth, ...rest }) => {
  if (!auth.uid) {
    return <Navigate to="/sign-in" />
  }

  return <Component {...rest} />
}

const mapStateToProps = state => {
  return {
    auth: state.firebase.auth,
  }
}

export default connect(mapStateToProps)(PrivateRoute)
