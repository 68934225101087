import PropTypes from 'prop-types'
import { Col, Row } from 'react-bootstrap'
import { TextField, PhoneNumberField, SelectField, RadioToggleField, DayPickerField } from 'components/form'
import { useForm } from 'helpers'
import { employmentStatusOptions, relationshipStatusOptions } from 'config/schemas'

const BasicsForm = ({ state, errors, success, handleChange }) => {
  return (
    <>
      {success && <div className="alert alert-success">{success}</div>}
      <TextField
        label="What is your full name?"
        inputClass="form-control"
        name="fullName"
        id="fullName"
        value={state.fullName}
        onChange={handleChange}
        error={errors.fullName}
      />
      <PhoneNumberField
        label="What is your mobile number"
        inputClass="form-control"
        name="mobileNumber"
        id="mobileNumber"
        value={state.mobileNumber}
        onChange={handleChange}
        error={errors.mobileNumber}
      />
      <TextField
        name="occupation"
        label="What is your occupation?"
        labelClass="mb-1"
        inputClass="form-control"
        value={state.occupation}
        onChange={handleChange}
        error={errors.occupation}
      />
      <SelectField
        id="employmentStatus"
        name="employmentStatus"
        label="What is your employment status?"
        mutedText=""
        onChange={handleChange}
        value={state.employmentStatus}
        options={employmentStatusOptions}
        error={errors.employmentStatus}
      />
      <DayPickerField
        id="dateOfBirth"
        label="What is your date of birth?"
        labelClass="mb-1"
        inputClass="form-control"
        value={state.dateOfBirth}
        onChange={handleChange}
        error={errors.dateOfBirth}
      />

      <RadioToggleField
        id="includesPartnerInfo"
        name="includesPartnerInfo"
        label="Would you like to add your partners information?"
        value={state.includesPartnerInfo}
        onChange={handleChange}
        error={errors.includesPartnerInfo}
      />

      {state.includesPartnerInfo && (
        <div>
          <TextField
            label="What is your partners full name?"
            inputClass="form-control"
            name="fullNamePartner"
            id="fullNamePartner"
            value={state.fullNamePartner}
            onChange={handleChange}
            error={errors.fullNamePartner}
          />
          <SelectField
            id="relationshipStatus"
            name="relationshipStatus"
            label="What is your relationship?"
            mutedText=""
            onChange={handleChange}
            value={state.relationshipStatus}
            options={relationshipStatusOptions}
            error={errors.relationshipStatus}
          />
          <SelectField
            id="employmentStatusPartner"
            name="employmentStatusPartner"
            label="What is your partners employment status?"
            mutedText=""
            onChange={handleChange}
            value={state.employmentStatusPartner}
            options={employmentStatusOptions}
            error={errors.employmentStatusPartner}
          />
          <TextField
            id="occupationPartner"
            name="occupationPartner"
            label="What is your partners occupation?"
            labelClass="mb-1"
            inputClass="form-control"
            value={state.occupationPartner}
            onChange={handleChange}
            error={errors.occupationPartner}
          />
          <DayPickerField
            id="dateOfBirthPartner"
            label="What is your partners date of birth?"
            labelClass="mb-1"
            inputClass="form-control"
            value={state.dateOfBirthPartner}
            onChange={handleChange}
            error={errors.dateOfBirthPartner}
          />
        </div>
      )}
    </>
  )
}

BasicsForm.propTypes = {}

export default BasicsForm
