import { useState } from 'react'
import PropTypes from 'prop-types'
import { Alert, Button, Col, Row } from 'react-bootstrap'
import { FormHeader } from 'components'
import {
  CreatableSelectField,
  CurrencyField,
  PercentageField,
  RadioToggleField,
  SelectField,
  TextField,
} from 'components/form'
import { useForm } from 'helpers'
import { pensionProviderOptions, accountHolderOptions, pensionTypeOptions } from 'config/schemas'
import FinanceFormActions from './FinanceFormActions'
import { ModalPensionType } from 'components/modals'
import { Modal } from 'containers'

import ReactTooltip from 'react-tooltip';

const PensionForm = ({ state, errors, success, handleChange, handleSubmit }) => {

  const pensionTypeLabel = (
    <p>
      What type of pension is this? <ModalPensionType />
    </p>
  )

  const pensionTypeLabel2 = (
    <p>
      What type of pension is this? New button
    </p>
  )

  return (
    <form className="" id="PensionForm" onSubmit={handleSubmit}>
      <FormHeader title="Add a new pension" />

      <SelectField
        name="accountHolder"
        label="Account Holder"
        mutedText=""
        onChange={handleChange}
        value={state.accountHolder}
        error={errors.accountType}
        options={accountHolderOptions}
      />

      <SelectField
        name="provider"
        label="Please tell us the pension company or scheme"
        onChange={handleChange}
        value={state.provider}
        options={pensionProviderOptions}
        error={errors.provider}
      />

      {state.provider === `Other` && (
        <TextField
          label="Please explain..."
          // mutedText="A friendly label so you know what's what"
          inputClass="form-control"
          name="providerOther"
          id="providerOther"
          value={state.providerOther}
          onChange={handleChange}
          error={errors.providerOther}
        />
      )}

      {false && (
        <CreatableSelectField
          name="provider2"
          isMulti={false}
          label="Provider (allows custom input)?"
          mutedText="Note: You can type to add more."
          onChange={handleChange}
          value={state.provider2}
          options={pensionProviderOptions}
        />
      )}

      <RadioToggleField
        id="extraDetailAvailable"
        name="extraDetailAvailable"
        label="Can you give us some detail on this policy?"
        value={state.extraDetailAvailable}
        onChange={handleChange}
        error={errors.extraDetailAvailable}
      />

      {state.extraDetailAvailable === true && (
        <div>
          <Alert variant="light">If you’re unsure of any of the following questions, please leave blank</Alert>
          <SelectField
            name="type"
            label={pensionTypeLabel}
            onChange={handleChange}
            value={state.type}
            options={pensionTypeOptions}
          />
          {state.type === pensionTypeOptions[0] && (
            <DefinedContributionForm
              state={state}
              errors={errors}
              onChange={handleChange}
            />
          )}
          {state.type === pensionTypeOptions[1] && (
            <DefinedBenefitForm
              state={state}
              errors={errors}
              onChange={handleChange}
            />
          )}
        </div>
      )}
      <FinanceFormActions cancelPath="/finances/debts" />
    </form>
  )
}

PensionForm.propTypes = {}

const DefinedContributionForm = ({ state, errors, onChange }) => (
  <div>
    <CurrencyField
      label="What is the total value of this pension?"
      inputClass="form-control"
      name="value"
      id="value"
      value={state.value}
      onChange={onChange}
      error={errors.value}
    />
    <RadioToggleField
      id="personalContributions"
      name="personalContributions"
      label="Do you make personal contributions monthly?"
      value={state.personalContributions}
      onChange={onChange}
      error={errors.personalContributions}
    />
    <CurrencyField
      label="If yes, how much?"
      // mutedText="This should be a percentage of your salary"
      inputClass="form-control"
      name="monthlyPersonalContributions"
      id="monthlyPersonalContributions"
      value={state.monthlyPersonalContributions}
      onChange={onChange}
      error={errors.monthlyPersonalContributions}
    />
    <RadioToggleField
      id="workContributions"
      name="workContributions"
      label="Does your employer make a monthly contribution?"
      value={state.workContributions}
      onChange={onChange}
      error={errors.workContributions}
    />
    <CurrencyField
      label="If yes, how much?"
      // mutedText="This should be a percentage of your salary"
      inputClass="form-control"
      name="monthlyWorkContributions"
      id="monthlyWorkContributions"
      value={state.monthlyWorkContributions}
      onChange={onChange}
      error={errors.monthlyWorkContributions}
    />
  </div>
)

const DefinedBenefitForm = ({ state, errors, onChange }) => (
  <div>
    <RadioToggleField
      id="activeSchemeMember"
      name="activeSchemeMember"
      label="Are you still paying into this pension scheme?"
      value={state.activeSchemeMember}
      onChange={onChange}
      error={errors.activeSchemeMember}
    />
    {false && (
      <CurrencyField
        label="How much is contributed each month?"
        inputClass="form-control"
        name="monthlySchemeContribution"
        id="monthlySchemeContribution"
        value={state.monthlySchemeContribution}
        onChange={onChange}
        error={errors.monthlySchemeContribution}
      />
    )}
    <CurrencyField
      label={
        state.activeSchemeMember
          ? "What is your yearly pension estimated at retirement age?"
          : "What is your expected yearly pension?"
      }
      inputClass="form-control"
      name="yearlyPension"
      id="yearlyPension"
      value={state.yearlyPension}
      onChange={onChange}
      error={errors.yearlyPension}
    />
    <CurrencyField
      label="What is your expected lump sum value?"
      inputClass="form-control"
      name="lumpSum"
      id="lumpSum"
      value={state.lumpSum}
      onChange={onChange}
      error={errors.lumpSum}
    />
    <TextField
      label="What age can you access your pension?"
      placeholder="60"
      inputClass="form-control"
      name="schemeRetirementAge"
      id="schemeRetirementAge"
      value={state.schemeRetirementAge}
      onChange={onChange}
      error={errors.schemeRetirementAge}
    />
  </div>
)

export default PensionForm
