import PropTypes from 'prop-types'
import { Button, Card, Col, Container, Form, Nav, Row } from 'react-bootstrap';
import { Dropzone, Flatpickr, Quill, Select } from 'components/vendor';
import FeatherIcon from 'feather-icons-react';
import { WizardFooter, WizardHeader, AboutYouForm, AboutYourPartnerForm } from 'components';
import { FormWrapper } from 'containers'
import { PeopleWidget } from 'components/widgets'
import { useNavigate } from "react-router-dom";

import { Spinner } from 'react-bootstrap';
import { toast } from 'react-toastify';

// Data
import { connect } from "react-redux"
import { saveAboutYou, saveAboutPartner } from "store/actions/userActions"

// Should be mirgrated with form
import { TextField, PhoneNumberField, SelectField, RadioToggleField, DayPickerField, CurrencyField } from 'components/form'
import { employmentStatusOptions, relationshipStatusOptions, aboutYou, aboutYourPartner } from 'config/schemas'

const aboutYouPageData = {
  activeStep: 1,
  title: `Let’s start with the basics.`,
  summary: `This should take 5 minutes. Don’t worry you don’t need to do it all at once and we’re on hand if you’re stuck on anything.`
}

const aboutPartnerPageData = {
  activeStep: 2,
  title: `Let’s add your partners info.`,
  summary: ``
}

const GettingStartedUser = ({ auth, profile, saveAboutYou, saveAboutPartner, partner = false }) => {
  let navigate = useNavigate();
  const stepCount = 2

  const state = {}
  const errors = {}
  const handleChange = {}

  if (!profile.isLoaded) {
    return <Spinner />;
  }

  const { activeStep, title, summary } = !partner ? aboutYouPageData : aboutPartnerPageData;

  const component = ({ handleSubmit, ...props}) => {
    return (
      <>
        <form onSubmit={handleSubmit}>
          <WizardHeader
            title={title}
            summary={summary}
            activeStep={activeStep}
            stepCount={stepCount}
          />
          {!partner ? <AboutYouForm {...props} /> : <AboutYourPartnerForm {...props} /> }
          <WizardFooter
            activeStep={activeStep}
            stepCount={stepCount}
            cancelPath={partner ?` /getting-started/about-you` : null}
          />
        </form>
      </>
    )
  }

  return (
    <>
      <FormWrapper
        schema={!partner ? aboutYou : aboutYourPartner}
        component={component}
        auth={auth}
        state={profile}
        onSubmit={!partner ? saveAboutYou : saveAboutPartner}
        onSuccess={() => {
          toast.success(`Profile saved successfully.`)
          navigate(`/getting-started/people`);
        }}
      />
    </>
  );

}

const mapStateToProps = state => {
  return {
    auth: state.firebase.auth,
    profile: state.firebase.profile,
  }
}

const mapDispatchToProps = dispatch => {
  return {
    saveAboutYou: data => dispatch(saveAboutYou(data)),
    saveAboutPartner: data => dispatch(saveAboutPartner(data)),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(GettingStartedUser)

