import Joi from "joi-browser"
import assign from "lodash/assign"
import keys from "lodash/keys"
import pick from "lodash/pick"
import clone from "lodash/clone"

export const getDefaultState = (schema, profile) => {
  const { defaultValues } = schema

  return assign(
    clone(schema.defaultValues),
    pick(clone(profile), keys(defaultValues))
  )
}

export const relationshipStatusOptions = [
  `Married`,
  `In a relationship`,
  `Civil partnership`,
]

export const employmentStatusOptions = [
  `Full time`,
  `Part time`,
  `Freelance / Self-employed`,
  `Business Owner`,
  `Company Director (without ownership)`,
  `Unemployed`,
]

export const accountHolderOptions = [`Me`, `Partner`]
export const accountHolderExtendedOptions = [
  `Me`,
  `Partner`,
  `Joint`,
  `Business`,
]

export const basics = {
  defaultValues: {
    fullName: ``,
    mobileNumber: ``,
    occupation: ``,
    employmentStatus: ``,
    dateOfBirth: ``,
    includesPartnerInfo: false,
    fullNamePartner: ``,
    relationshipStatus: ``,
    mobileNumberPartner: ``,
    occupationPartner: ``,
    employmentStatusPartner: ``,
    dateOfBirthPartner: ``,
  },
  validator: Joi.object().keys({
    fullName: Joi.string().required().label(`name`),
    mobileNumber: Joi.string().required().label(`mobile number`),
    occupation: Joi.string().required().label(`occupation`),
    employmentStatus: Joi.string().required().label(`employment status`),
    dateOfBirth: [
      Joi.date().optional().label(`date of birth`),
      Joi.allow(null),
    ],

    includesPartnerInfo: Joi.boolean().required().label(`add partner info`),
    relationshipStatus: [
      Joi.string().optional().label(`relationship status`),
      Joi.allow(null),
    ],
    fullNamePartner: [Joi.string().optional().label(`name`), Joi.allow(null)],
    mobileNumberPartner: [
      Joi.string().optional().label(`mobile number`),
      Joi.allow(null),
    ],
    occupationPartner: [
      Joi.string().optional().label(`occupation`),
      Joi.allow(null),
    ],
    employmentStatusPartner: [
      Joi.string().optional().label(`employment status partner`),
      Joi.allow(null),
    ],
    dateOfBirthPartner: [
      Joi.date().optional().label(`date of birth`),
      Joi.allow(null),
    ],
  }),
}

export const income = {
  defaultValues: {
    totalIncome: 0,
    totalIncomePartner: 0,
    annualSalary: 0,
    annualSalaryPartner: 0,
    totalPaidRent: 0,
    totalFixedBills: 0,
  },
  validator: Joi.object().keys({
    totalIncome: Joi.number().required().label(`total income`),
    annualSalary: Joi.number().required().label(`annual salary`),
    totalIncomePartner: Joi.number().required().label(`total income partner`),
    annualSalaryPartner: Joi.number().required().label(`annual salary partner`),
    totalPaidRent: Joi.number().required().label(`total paid rent`),
    totalFixedBills: Joi.number().required().label(`total fixed bills`),
  }),
}

export const aboutYou = {
  defaultValues: {
    // Personal
    fullName: ``,
    mobileNumber: ``,
    occupation: ``,
    employmentStatus: ``,
    dateOfBirth: ``,
    // Income
    totalIncome: 0,
    // totalIncomePartner: 0,
    annualSalary: 0,
    // annualSalaryPartner: 0,
    totalPaidRent: 0,
    totalFixedBills: 0,
  },
  validator: Joi.object().keys({
    fullName: Joi.string().required().label(`name`),
    mobileNumber: Joi.string().required().label(`mobile number`),
    occupation: Joi.string().required().label(`occupation`),
    employmentStatus: Joi.string().required().label(`employment status`),
    dateOfBirth: [
      Joi.date().optional().label(`date of birth`),
      Joi.allow(null),
    ],
    relationshipStatus: [
      Joi.string().optional().label(`relationship status`),
      Joi.allow(null),
    ],
    totalIncome: Joi.number().required().label(`total income`),
    annualSalary: Joi.number().required().label(`annual salary`),
    totalPaidRent: Joi.number().required().label(`total paid rent`),
    totalFixedBills: Joi.number().required().label(`total fixed bills`),
    // Income
  }),
}

export const aboutYourPartner = {
  defaultValues: {
    // Personal
    includesPartnerInfo: true,
    fullNamePartner: ``,
    relationshipStatus: ``,
    mobileNumberPartner: ``,
    occupationPartner: ``,
    employmentStatusPartner: ``,
    dateOfBirthPartner: ``,
    // Income
    totalIncomePartner: 0,
    annualSalaryPartner: 0,
    // totalPaidRent: 0,
    // totalFixedBills: 0,
  },
  validator: Joi.object().keys({
    includesPartnerInfo: Joi.boolean().required().label(`add partner info`),
    relationshipStatus: [
      Joi.string().optional().label(`relationship status`),
      Joi.allow(null),
    ],
    fullNamePartner: [Joi.string().optional().label(`name`), Joi.allow(null)],
    mobileNumberPartner: [
      Joi.string().optional().label(`mobile number`),
      Joi.allow(null),
    ],
    occupationPartner: [
      Joi.string().optional().label(`occupation`),
      Joi.allow(null),
    ],
    employmentStatusPartner: [
      Joi.string().optional().label(`employment status partner`),
      Joi.allow(null),
    ],
    dateOfBirthPartner: [
      Joi.date().optional().label(`date of birth`),
      Joi.allow(null),
    ],
    totalIncomePartner: Joi.number().required().label(`total income partner`),
    annualSalaryPartner: Joi.number().required().label(`annual salary partner`),
  }),
}

export const financials = {
  defaultValues: {
    totalFixedBills: 0,
    hasCreditCards: true,
    totalCreditCards: 0,
    hasPersonalLoans: true,
    totalPersonalLoans: 0,
    hasMortgages: true,
    totalMortgages: 0,
    hasOtherDebt: true,
    totalOtherDebt: 0,
  },
  validator: Joi.object().keys({
    totalFixedBills: Joi.number().required().label(`total fixed bills`),
    hasCreditCards: Joi.boolean().required().label(`has credit cards`),
    totalCreditCards: Joi.number().required().label(`total credit cards`),
    hasPersonalLoans: Joi.boolean().required().label(`has personal loans`),
    totalPersonalLoans: Joi.number().required().label(`total personal loans`),
    hasMortgages: Joi.boolean().required().label(`has mortgages`),
    totalMortgages: Joi.number().required().label(`total mortgages`),
    hasOtherDebt: Joi.boolean().required().label(`has other debt`),
    totalOtherDebt: Joi.number().required().label(`total other debt`),
  }),
}

// export const otherInsurancePolicyOptions = [
//   { value: `Workplace Pension`, label: `Workplace Pension` },
//   { value: `Private Healthcare`, label: `Private Healthcare` },
//   { value: `Other`, label: `Other` },
// ]

export const otherInsurancePolicyOptions = [
  `Workplace Pension`,
  `Private Healthcare`,
  `Other`,
]

export const currentCover = {
  defaultValues: {
    hasPersonalLifeCover: true,
    hasIncomeProtectionInsurance: true,
    otherInsurancePolicies: [],
  },
  validator: Joi.object().keys({
    hasPersonalLifeCover: Joi.boolean()
      .required()
      .label(`has personal life cover`),
    hasIncomeProtectionInsurance: Joi.boolean()
      .required()
      .label(`has income protection insurance`),
    otherInsurancePolicies: Joi.array()
      .items(Joi.string())
      .required()
      .label(`other insurance policies`),
  }),
}

export const accountTypeOptions = [
  `Current Account`,
  `Savings Account`,
  `Cash ISA`,
  `Cash LISA`,
  `Business Account`,

  `Stocks ISA`,
  `Stocks (non ISA)`,
  `Junior ISA`,
  `Lifetime ISA`,

  `My Business Shares`,

  `Crypto Wallet Balance(s)`,

  `Other`,
]

export const savingAccount = {
  defaultValues: {
    // id: null,
    // name: ``,
    balance: 0,
    monthlySavings: 0,
    type: accountTypeOptions[0], // dropdown: current account / savings account / cash ISA // Needs info button
    provider: ``,
    accountHolder: accountHolderOptions[0], // Myself, Joint // Needs info button
    statement: ``,
  },
  validator: Joi.object().keys({
    // id: [Joi.string().optional().label(`id`), Joi.allow(null)],
    // name: Joi.string().required().label(`name`),
    balance: Joi.number().required().label(`balance`),
    monthlySavings: Joi.number().required().label(`monthly savings`),
    type: Joi.string().required().label(`account type`),
    provider: Joi.string().required().label(`provider`),
    accountHolder: Joi.string().required().label(`account holder`),
    statement: [Joi.string().optional().label(`savings`), Joi.allow(null)],
  }),
}

export const pensionTypeOptions = [`Defined Contribution`, `Defined Benefit`]

export const pensionProviderOptions = [
  // DC
  `Aegon`,
  `AJ Bell Youinvest`,
  `Aviva`,
  `Embark`,
  `Evestor`,
  `Fidelity`,
  `Halifax`,
  `Hargreaves Landsdown`,
  `Interactive Investor`,
  `Legal & General`,
  `Moneybox`,
  `Nest`,
  `Nutmeg`,
  `PensionBee`,
  `Royal London`,
  `Scottish Widows`,
  `Smart Pension`,
  `Standard Life`,
  `The People's Pension`,
  `True Potential`,
  `Vanguard`,

  // DB
  `NHS`,
  `Teachers`,
  `USS`,
  `BT`,
  `Armed Forces`,

  `Other`,
]

export const pension = {
  defaultValues: {
    // id: null,
    accountHolder: accountHolderOptions[0],
    provider: ``,
    // provider2: ``,
    providerOther: ``,
    extraDetailAvailable: false,
    statement: null,
    type: null,
    value: 0.0,
    personalContributions: false,
    monthlyPersonalContributions: 0.0,
    workContributions: false,
    monthlyWorkContributions: 0.0,
    activeSchemeMember: false,
    monthlySchemeContribution: 0.0,
    yearlyPension: 0.0,
    lumpSum: 0.0,
    schemeRetirementAge: 60,
  },
  validator: Joi.object().keys({
    // id: [Joi.string().optional().label(`id`), Joi.allow(null)],
    // both
    accountHolder: Joi.string().required().label(`account holder`),
    provider: Joi.string().required().label(`pension provider`),
    // provider2: Joi.string().required().label(`pension provider 2`),
    providerOther: [
      Joi.string().optional().label(`statement`),
      Joi.allow(null),
    ],
    extraDetailAvailable: Joi.boolean().label(`extra data available`),
    statement: [Joi.string().optional().label(`statement`), Joi.allow(null)],
    type: [Joi.string().optional().label(`pension type`), Joi.allow(null)],
    // Defined contribution

    value: Joi.number().required().label(`pension value`),
    personalContributions: Joi.boolean()
      .required()
      .label(`personal contributions`),
    monthlyPersonalContributions: Joi.number()
      .required()
      .label(`total monthly personal contributions`),
    workContributions: Joi.boolean().required().label(`work contributions`),
    monthlyWorkContributions: Joi.number()
      .required()
      .label(`total monthly work contributions`),
    // Defined benefit
    activeSchemeMember: Joi.boolean().optional().label(`active scheme member`),
    monthlySchemeContribution: Joi.number()
      .optional()
      .label(`monthly scheme contribution`),
    yearlyPension: Joi.number().optional().label(`yearly pension`),
    lumpSum: Joi.number().optional().label(`lump sum`),
    schemeRetirementAge: Joi.number().optional().label(`scheme retirement age`),
  }),
}

export const investmentAccountTypeOptions = [
  `Stocks ISA`,
  `Stocks (non ISA)`,
  `Junior ISA`,
  `Lifetime ISA`,
  `My Business Shares`,
  `Crypto Wallet Balance(s)`,
  `Other`,
]

export const investment = {
  defaultValues: {
    // id: null,
    // name: ``,
    balance: 0,
    monthlyInvestment: 0,
    accountType: ``, // Needs info button
    accountTypeOther: ``,
    accountHolder: accountHolderOptions[0], // Needs info button
    statement: ``,
  },
  validator: Joi.object().keys({
    // id: [Joi.string().optional().label(`id`), Joi.allow(null)],
    // name: Joi.string().required().label(`name`),
    balance: Joi.number().required().label(`balance`),
    monthlyInvestment: Joi.number().required().label(`monthly investment`),
    accountType: Joi.string().required().label(`account type`), // Needs info button
    accountTypeOther: Joi.string()
      .optional()
      .allow(``)
      .label(`account type other`), // Needs info button
    accountHolder: Joi.string().required().label(`account holder`), // Needs info button
    statement: ``,
  }),
}

export const interestTypeOptions = [`Standard Variable`, `Fixed`, `Tracker`]
export const propertyTypeOptions = [`Main Residence`, `Buy-to-let`]

export const property = {
  defaultValues: {
    // id: null,
    name: ``,
    propertyValue: 0.0,
    // isMortgaged: false,
    // mortgageBalance: 0.0,
    // monthlyMortgagePayment: 0.0,
    // interestType: null,
    // remainingTerm: 0, // remainingMortgageTerm And only in years from now on
    // remainingTermFrequency: null,
    propertyType: null,
    monthlyIncome: 0.0,
    // owner: null,
  },
  validator: Joi.object().keys({
    // id: [Joi.string().optional().label(`id`), Joi.allow(null)],
    name: Joi.string().required().label(`name`),
    propertyValue: Joi.number().required().label(`property value`),
    // isMortgaged: Joi.boolean().required().label(`is mortgaged`),
    // mortgageBalance: Joi.number().optional().label(`montly balance`),
    // monthlyMortgagePayment: Joi.number().required().label(`montly payment`),
    // interestType: Joi.string().optional().label(`interest type`),
    // remainingTerm: Joi.number().optional().label(`remaining term`),
    // remainingTermFrequency: Joi.string().optional().label(`remaining term frequency`) ,
    propertyType: Joi.string().required().label(`property purpose`),
    monthlyIncome: Joi.number().optional().label(`monthly income`),
    // owner: Joi.string().required().label(`owner`),
  }),
}

export const insuranceTypeOptions = [
  `Life Cover`,
  `Critical Illness`,
  `Income Protection`,
]

export const policyTypeOptions = [`Personal`, `Work`]
export const policyHolderOptions = accountHolderOptions;

export const insurancePayoutScheduleOptions = [
  `Life Cover`,
  `Critical Illness`,
  `Income Protection`,
]

export const insuranceProviderOptions = [
  `Aegon`,
  `AIG`,
  `Aviva`,
  `Guardian`,
  `Legal & General`,
  `Liverpool Victoria`,
  `Royal London`,
  `Scottish Widows`,
  `The Exeter`,
  `Vitality`,
  `Zurich`,
  `Other`,
]

export const deferredPeriodOptions = [
  `2 Weeks`,
  `4 Weeks`,
  `8 Weeks`,
  `13 Weeks`,
  `26 Weeks`,
  `52 Weeks`,
]

export const insurance = {
  defaultValues: {
    // id: null,
    provider: ``,
    insuranceType: ``,
    policyType: ``,
    policyHolder: ``,
    policyValue: 0.0,
    policyTerm: ``,

    monthlyBenefit: ``,
    deferredPeriod: ``,
  },
  validator: Joi.object().keys({
    id: [Joi.string().optional().label(`id`), Joi.allow(null)],
    provider: Joi.string().required().label(`insurance provider`),
    insuranceType: Joi.string().required().label(`insurance type`),
    policyType: [Joi.string().optional().label(`policy type`), Joi.allow(null)],
    policyHolder: Joi.string().optional().label(`policy holder`),
    policyValue: Joi.number().optional().label(`policy value`),
    policyTerm: Joi.number().optional().label(`policy term`),

    monthlyBenefit: Joi.string().optional().allow(``).label(`monthly benefit`),
    deferredPeriod: Joi.string().optional().allow(``).label(`deferred period`),

    // coverType: Joi.string().required().label(`coverType`),
    // payoutSchedule: Joi.string().required().label(`payoutSchedule`),
    // coverAmount: Joi.number().required().label(`coverAmount`),
    // coverLength: Joi.string().required().label(`coverLength`),
  }),
}

export const debtTypeOptions = [
  `Mortgage`,
  `Credit Card`,
  `Car Finance`,
  `Personal Loan`,
  `Student Loan`,
  `Other`,
]

export const debt = {
  defaultValues: {
    accountHolder: accountHolderOptions[0],
    lender: ``,
    type: ``,
    typeOther: ``,
    balance: 0.0,
    monthlyCost: 0.0,
    remainingTerm: 0,
    remainingTermFrequency: `Months`,
    interestType: ``,
    interestRate: ``,
  },
  validator: Joi.object().keys({
    id: [Joi.string().optional().label(`id`), Joi.allow(null)],
    accountHolder: Joi.string().required().label(`account holder`),
    lender: Joi.string().required().label(`lender`),
    type: Joi.string().required().label(`type`),
    typeOther: [Joi.string().optional().label(`type other`), Joi.allow(null)],
    balance: Joi.number().required().label(`balance`),
    monthlyCost: Joi.number().required().label(`monthly cost`),
    remainingTerm: Joi.number().required().label(`remaining term`),
    remainingTermFrequency: Joi.string()
      .required()
      .label(`remaining term frequency`),
    interestType: [
      Joi.string().optional().label(`interest type`),
      Joi.allow(null),
    ],
    interestRate: Joi.number().required().label(`interest rate`),
  }),
}
