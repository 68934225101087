import { Link } from 'react-router-dom'
import { Button, ButtonGroup } from 'react-bootstrap'

const FinanceFormActions = ({ cancelPath }) => (
    <ButtonGroup className="me-2 mb-3 my-5">
      <Button variant="primary" type="submit">Save</Button>
      <Button variant="outline-primary" as={Link} to={cancelPath}>Cancel</Button>
    </ButtonGroup>
)

export default FinanceFormActions
