const calculateBreakdown = ({ pmt, pv, interestRate, periods }) => {
  const breakdown = [];

  var startingPrinciple = 0.00
  var endingPrinciple = pv;

  for (var i = 1; i <= periods; i++) {
    startingPrinciple = endingPrinciple + pmt;
    endingPrinciple = startingPrinciple + startingPrinciple * ( interestRate / 12 )

    breakdown.push({
      period: i,
      deposit: pmt,
      startingPrinciple: startingPrinciple,
      endingPrinciple: endingPrinciple, // =E9+E9*($D$4/12) //// startingPrinciple + startingPrinciple * ( interestRate / 12 )
    })
  }

  return breakdown;
}

export default calculateBreakdown
