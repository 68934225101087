const formatAccountTitle = account => {
  switch (account.accountType) {
      case `PROPERTY`:
        return `${account.propertyType}`;
      case `DEBT`:
        return `${account.lender} - ${account.type}`;
      case `INSURANCE`:
        return `${account.accountHolder === 'Me' ? 'My' : 'Our'} ${account.provider} ${account.insuranceType}`;
      case `PENSION`:
        return `${account.provider} Pension`;
      case `SAVING_INVESTMENT`:
        return `${account.accountHolder === 'Me' ? 'My' : 'Our'} ${account.provider} ${account.type}`;
  }
}

export default formatAccountTitle
