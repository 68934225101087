import PropTypes from 'prop-types'
import { Button, Card, Col, Container, Form, Nav, Row } from 'react-bootstrap';
import { Dropzone, Flatpickr, Quill, Select } from 'components/vendor';
import FeatherIcon from 'feather-icons-react';
import { IncomeForm, WizardFooter, WizardHeader } from 'components';
import { FormWrapper } from 'containers'
import { income as schema } from 'config/schemas'
import { Spinner } from 'react-bootstrap';
import { useNavigate } from "react-router-dom";
import { toast } from 'react-toastify';

// Data
import { connect } from "react-redux"
import { saveIncome } from "store/actions/userActions"

const GettingStartedIncome = ({ auth, profile, success, saveIncome }) => {
  let navigate = useNavigate();
  const activeStep = 2;
  const stepCount = 2;

  if (!profile.isLoaded) {
    return <Spinner />;
  }

  const component = ({ handleSubmit, ...props}) => {
    return (
      <>
        <form onSubmit={handleSubmit}>
          <WizardHeader
            title="Your income."
            summary="Before you sign-up, we make sense of where you are, where you need to be and what needs to be done. You then decide if we’re a good fit."
            activeStep={activeStep}
            stepCount={stepCount}
          />
          <IncomeForm {...props} />
          <WizardFooter
            activeStep={activeStep}
            stepCount={stepCount}
            cancelPath="/getting-started/basics"
          />
        </form>
      </>
    )
  }

  return (
    <>
      <FormWrapper
        schema={schema}
        component={component}
        auth={auth}
        state={profile}
        onSubmit={saveIncome}
        onSuccess={() => {
          toast.success('Income saved successfully.')
          navigate(`/`);
        }}
      />
    </>
  );
}

const mapStateToProps = state => {
  return {
    auth: state.firebase.auth,
    profile: state.firebase.profile,
    success: state.user.success,
  }
}

const mapDispatchToProps = dispatch => {
  return {
    saveIncome: income => dispatch(saveIncome(income)),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(GettingStartedIncome)
