
const sumValues = obj => Object.values(obj).reduce((a, b) => a + b);

const calculateIncome = (profile, accounts) => {
  return parseFloat(profile.totalIncome)
    + parseFloat(profile.totalIncomePartner)
    + accounts.PROPERTY.reduce((a, v) => a + v.income, 0)
  ;
}

const calculateOutgoingsTotal = (outgoings) => {
  return sumValues(outgoings) - outgoings.pension;
}

const calculateOutgoings = (profile, accounts) => {
  const housing = parseFloat(profile.totalPaidRent) + accounts.DEBT
    .reduce((a, v) => a + (v.debtType === `Mortgage` ? v.monthlyCost : 0), 0);

  return {
    mortgage: parseFloat(housing),
    livingCosts: parseFloat(profile.totalFixedBills),
    savings: accounts.SAVING_INVESTMENT.reduce((a, v) => a + parseFloat(v.monthlyCost), 0),
    pension: accounts.PENSION.reduce((a, v) => a + parseFloat(v.monthlyCost), 0),
    debtRepayments: accounts.DEBT.reduce((a, v) => a + (v.debtType !== `Mortgage` ? parseFloat(v.monthlyCost) : 0), 0)
  }
}

const calculateCashflow = (profile, accounts) => {
  const results = {
    surplus: { outgoings: 0, surplus: 0 },
    outgoings: { mortgage: 0, livingCosts: 0, savings: 0, pension: 0, debtRepayments: 0 }
  }

  const outgoings = calculateOutgoings(profile, accounts)
  const outgoingsTotal = calculateOutgoingsTotal(outgoings)
  const income = calculateIncome(profile, accounts)
  const surplus = income - outgoingsTotal;

  return {
    surplus: { outgoings: outgoingsTotal, surplus: surplus },
    outgoings: outgoings
  }

  return results;
}

export default calculateCashflow
