import { Col, Container, Row } from 'react-bootstrap';
import PropTypes from 'prop-types'
import { AccountTable } from 'components'

import { connect } from "react-redux"
import { firestoreConnect } from "react-redux-firebase"
import { compose } from "redux"
import { deleteAccount } from "store/actions/financesActions"
import { formatCurrency } from "helpers"
import { AccountTableActions } from "components"

const tableCols = [`Provider`, `Insurance Type`, `Amount`, `Actions`]

const FinancesInsuranceTable = ({ accounts, auth, deleteAccount }) => {
  const data = accounts.map(account => {
    return [
      account.provider,
      account.insuranceType,
      formatCurrency(account.policyValue),
      <AccountTableActions
        id={account.id}
        editPath={`/finances/insurance/${account.id}`}
        onClickDelete={deleteAccount}
      />,
    ]
  })

  return (
    <AccountTable
      heading="Insurances"
      columns={tableCols}
      data={data}
      noData="No data"
      addNewPath="/finances/insurance/add"
    />
  );
}

const mapStateToProps = state => {
  return {
    accounts: state.firestore.ordered.accounts
      ? state.firestore.ordered.accounts
      : [],
    auth: state.firebase.auth,
  }
}

const mapDispatchToProps = dispatch => {
  return {
    deleteAccount: id => dispatch(deleteAccount(id)),
  }
}

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  firestoreConnect(props => {
    if (!props.auth.uid) return []

    return [
      {
        collection: `accounts`,
        where: [
          ["uid", "==", props.auth.uid],
          ["accountType", "==", `INSURANCE`],
        ],
        // orderBy: [`balance`, `desc`],
      },
    ]
  })
)(FinancesInsuranceTable)
