import React from 'react'
import { Form } from 'react-bootstrap'

const TextField = ({
  id,
  name,
  label,
  value,
  error,
  onChange = null,
  onBlur = null,
  mutedText = null,
  labelClass = null,
  inputClass = null,
  readOnly = false,
  placeholder = ``,
  isNumber = false,
}) => (
  <Form.Group className="mb-3">
    <Form.Label className={labelClass}>{label}</Form.Label>
    {mutedText && <small className="form-text text-muted">{mutedText}</small>}
    <Form.Control
      type="text"
      id={id}
      name={name}
      value={isNumber && value === `0` ? `` : value}
      onChange={onChange}
      onBlur={onBlur}
      readOnly={readOnly}
      placeholder={placeholder}
      isInvalid={error}
    />
    {error && <Form.Control.Feedback className="invalid-feedback">{error}</Form.Control.Feedback>}
  </Form.Group>
)

export default TextField
