import { connect } from "react-redux"
import { firestoreConnect } from "react-redux-firebase"
import { compose } from "redux"
import { createUpdateAccount } from "store/actions/financesActions"
import { FormWrapper } from './';
import { Spinner } from 'react-bootstrap';
import { useNavigate } from "react-router-dom";
import { toast } from 'react-toastify';

const AccountWrapper = ({
  schema,
  form,
  action,
  account,
  createUpdateAccount,
  accountType,
  id = null,
}) => {
  let navigate = useNavigate();
  // Need to work out how to handle docs that don't exist
  if (!account && action === 'update') {
    return <Spinner />;
  }

  return (
    <FormWrapper
      schema={schema}
      component={form}
      state={account}
      onSubmit={createUpdateAccount}
      onSubmitProps={{
        accountType: accountType,
        id: id
      }}
      onSuccess={() => {
        toast.success('Account saved successfully.', [

        ])
        navigate(`/`);
      }}
    />
  )
}

const mapStateToProps = (state, ownProps) => {
  const id = ownProps.location.id
  const accounts = state.firestore.data.accounts
  const account = accounts ? accounts[id] : null

  return {
    id: id,
    type: ownProps.type,
    action: `undefined` === typeof id ? 'create' : 'update',
    account: account,
    auth: state.firebase.auth,
    location: ownProps,
    success: state.finances.success ? true : false
  }
}

const mapDispatchToProps = dispatch => {
  return {
    createUpdateAccount: (props) =>
      dispatch(createUpdateAccount(props)),
  }
}

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  firestoreConnect(props => {
    if (!props.auth.uid) return [];

    return [
      {
        collection: `accounts`,
        doc: props.id,
      },
    ]
  })
)(AccountWrapper)
