import React from "react"
import { Form } from 'react-bootstrap'

const FrequencyField = ({
  id,
  name,
  idFrequency,
  nameFrequency,
  label,
  value,
  valueFrequency,
  error,
  errorFrequency,
  onChange = null,
  onChangeFrequency = null,
  onBlur = null,
  mutedText = null,
  labelClass = null,
  inputClass = null,
  readOnly = false,
  placeholder = ``,
  isNumber = false,
}) => (
  <Form.Group>
    <label className={labelClass}>{label}</label>
    {mutedText && <small className="form-text text-muted">{mutedText}</small>}
    <div className="input-group">
      <input
        type="text"
        className={`form-control ${inputClass} ${error ? "is-invalid" : ""}`}
        id={id}
        name={name}
        value={isNumber && value === `0` ? `` : value}
        onChange={onChange}
        onBlur={onBlur}
        readOnly={readOnly}
        placeholder={placeholder}
      />
      <select className="form-control" id={idFrequency} name={nameFrequency} onChange={onChange}>
        <option selected={valueFrequency === "Months" ? `selected` : ""}>
          Months
        </option>
        <option selected={valueFrequency === "Years" ? `selected` : ""}>
          Years
        </option>
      </select>
    </div>
    {error && <div className="invalid-feedback"  style={{ display: `block` }}>{error}</div>}
    {errorFrequency && <div className="invalid-feedback"  style={{ display: `block` }}>{errorFrequency}</div>}
  </Form.Group>
)

export default FrequencyField
