import PropTypes from 'prop-types'
import { Col, Row } from 'react-bootstrap'

const WizardHeader = ({
  title,
  summary,
  activeStep,
  stepCount
}) => (
  <Row className="justify-content-center">
    <Col xs={12} md={10} lg={8} xl={6} className="text-center">
      <h6 className="mb-4 text-uppercase text-muted">Step {activeStep} of {stepCount}</h6>
      <h1 className="mb-3">{title}</h1>
      <p className="mb-5 text-muted">
        {summary}
      </p>
    </Col>
  </Row>
)

WizardHeader.propTypes = {}

export default WizardHeader
