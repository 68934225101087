import React, { useState } from 'react'
import { connect } from 'react-redux'
import { forgotPassword } from "store/actions/authActions"
import { ForgotPasswordForm as Form } from 'components/widgets'
import { useNavigate } from "react-router-dom";

const ForgotPasswordForm = ({ data, location, auth, authError, success, forgotPassword }) => {
  let navigate = useNavigate();

  if (auth.uid) {
    navigate("/", { replace: true });
  }

  const [state, setState] = useState({
    email: ``,
  })

  const handleChange = e => {
    setState({
      ...state,
      [e.target.name]: e.target.value,
    })
  }

  const handleSubmit = e => {
    e.preventDefault()

    forgotPassword(state)
  }

  return (
    <Form
      onChange={handleChange}
      onSubmit={handleSubmit}
      authError={authError}
      success={success}
    />
  )
}

const mapStateToProps = state => {
  return {
    auth: state.firebase.auth,
    success: state.auth.success,
    authError: state.auth.authError,
  }
}

const mapDispatchToProps = dispatch => {
  return {
    forgotPassword: creds => dispatch(forgotPassword(creds)),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(ForgotPasswordForm)
