import { AccountWrapper } from 'containers'
import { property as schema } from 'config/schemas'
import { PropertyForm } from 'components'
import { useParams } from 'react-router-dom'

const FinancesPropertyForm = ({}) => {
  return <AccountWrapper accountType="PROPERTY" schema={schema} form={PropertyForm} location={useParams()} />;
}

export default FinancesPropertyForm
