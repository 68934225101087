import React from "react";
import { Badge, Button, Card } from "react-bootstrap";

export default function DisasterPlanWidget({ ...props }) {
  return (
    <Card className="disaster-widget" {...props}>
      <Card className="disaster-image"></Card>

      <Card.Body className="disaster-content d-flex flex-column text-center justify-content-center">
        <Badge
          bg="success"
          className="position-absolute"
          style={{ top: 20, right: 20 }}
        >
          New!
        </Badge>  
        <h1 className="text-light">Disaster plan</h1>
        <p className="text-light">
          See where you'd be financially if you had to take time off work for a
          serious illness, accident …or worse!
        </p>
        <div>
          <Button variant="outline-light" className="stretched-link" href="/disaster-plan">See your plan</Button>
        </div>
      </Card.Body>
    </Card>
  );
}
