import { useForm } from 'helpers'
import { Spinner } from 'react-bootstrap';
import { useNavigate } from "react-router-dom";

// Turn this into a form wrapper
const FormWrapper = ({ auth, state, onSubmit, schema, component: Component, onSubmitProps, onSuccess }) => {
  let navigate = useNavigate();
  const form = useForm({
    initialState: state,
    schema: schema,
    onSuccess: onSuccess,
    onSubmit: (data) => onSubmit(data),
    onSubmitProps,
  });

  return (
    <Component {...form}  />
  )
}

export default FormWrapper
