export default [
  {value: `Coinbase`, label: `Coinbase` },
  {value: `Binance`, label: `Binance` },
  {value: `Etorro`, label: `Etorro` },
  {value: `Gemini`, label: `Gemini` },
  {value: `Bitcoin`, label: `Bitcoin` },
  {value: `Ethereum`, label: `Ethereum` },
  {value: `XRP`, label: `XRP` },
  {value: `Binance Coin`, label: `Binance Coin` },
  {value: `Tether`, label: `Tether` },
  {value: `Cardano`, label: `Cardano` },
]
