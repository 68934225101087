import { useState } from 'react'
import { Collapse, Container, Dropdown, Form, InputGroup, Nav, Navbar, NavDropdown } from 'react-bootstrap';
import { Avatar } from 'components';
import { ModalNotifications, ModalSearch } from 'components/modals';
import { Link, useLocation } from "react-router-dom";
import FeatherIcon from 'feather-icons-react';

import logo from 'assets/img/wealthsteer-logo.png';
import { connect } from "react-redux"
import { signOut } from "store/actions/authActions"
import { financeLinks, profileLinks } from 'config/routing'

import { HashLink } from 'react-router-hash-link';

const excludeNavPages = [
  '/error-illustration',
  '/error',
  '/forgot-password',
  '/getting-started',
  '/password-reset-cover',
  '/password-reset-illustration',
  '/password-reset',
  '/sign-in-cover',
  '/sign-in-illustration',
  '/sign-in',
  '/sign-up-cover',
  '/sign-up-illustration',
  '/sign-up',
];

const Topnav = ({ signOut }) => {
  const router = useLocation();

  if (undefined !== excludeNavPages.find(path => router.pathname.startsWith(path))) {
    return (null);
  }

  const brand = (
    <Link to="/">
      <Navbar.Brand>
        <img className="navbar-brand-img" src={logo} alt="..." />
      </Navbar.Brand>
       &nbsp; <span className="badge bg-dark-soft small"  style={{fontSize: `10px`}}>beta</span>
    </Link>
  );

  return (
    <Navbar expand="lg">
      <Container>
        {brand}
        <Navbar.Toggle aria-controls="basic-navbar-nav" />
        <Navbar.Collapse id="basic-navbar-nav">
          <Nav className="me-auto">
            {/*<Nav.Link as={Link} to={`/`}>Home</Nav.Link>*/}
            <Nav.Link as={HashLink} to={`/#policies-and-assets`}>Policies & Assets</Nav.Link>
            {/*
              <NavDropdown title="Your Finances" id="finances-dropdown">
                {financeLinks.map(({label, to}, i) => (
                  <NavDropdown.Item key={i}>
                    <Link to={to}>{label}</Link>
                  </NavDropdown.Item>
                ))}
              </NavDropdown>
            */}

            {profileLinks.map(({label, to}, i) => (
              <Nav.Link as={Link} to={to} key={i}>{label}</Nav.Link>
            ))}
            <Nav.Link onClick={signOut}>Logout</Nav.Link>
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  )
}


const mapDispatchToProps = dispatch => {
  return {
    signOut: () => dispatch(signOut()),
  }
}

const mapStateToProps = state => {
  return {
    auth: state.firebase.auth,
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Topnav)
