import PropTypes from 'prop-types'
import { Col, Row } from 'react-bootstrap'
import { FormHeader } from 'components'
import {
  TextField,
  CurrencyField,
  SelectField,
} from 'components/form'
import { useForm } from 'helpers'
import { accountTypeOptions, accountHolderExtendedOptions } from 'config/schemas'
import providers from 'config/providers'
import FinanceFormActions from './FinanceFormActions'

const SavingsAndInvestmentsForm = ({ state, errors, success, handleChange, handleSubmit }) => {
  const providerOptions = providers.getByAccountType(state.type).map(p => (p.label))

  return (
    <form className="" id="SavingsAndInvestmentsForm" onSubmit={handleSubmit}>
      <div role="tabpanel" aria-labelledby="savingsForm">
        <FormHeader title="Add a new savings or investment account" />
        <SelectField
          name="type"
          label="Account Type"
          mutedText=""
          onChange={handleChange}
          value={state.type}
          error={errors.type}
          options={accountTypeOptions}
        />
        <SelectField
          name="provider"
          label="Provider"
          mutedText=""
          onChange={handleChange}
          value={state.provider}
          error={errors.provider}
          options={providerOptions}
          formattedData
        />
        <SelectField
          name="accountHolder"
          label="Account Holder"
          mutedText=""
          onChange={handleChange}
          value={state.accountHolder}
          error={errors.accountHolder}
          options={accountHolderExtendedOptions}
        />
        <CurrencyField
          label="What is the total balance of this account?"
          inputClass="form-control"
          name="balance"
          id="balance"
          value={state.balance}
          onChange={handleChange}
          error={errors.balance}
        />
        <CurrencyField
          label="How much do you save each month?"
          inputClass="form-control"
          name="monthlySavings"
          id="monthlySavings"
          value={state.monthlySavings}
          onChange={handleChange}
          error={errors.monthlySavings}
        />
      </div>
      <FinanceFormActions cancelPath="/finances/savings-and-investments" />
    </form>
  )
}

SavingsAndInvestmentsForm.propTypes = {}

export default SavingsAndInvestmentsForm
