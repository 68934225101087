import { AccountWrapper } from 'containers'
import { savingAccount as schema } from 'config/schemas'
import { SavingsAndInvestmentsForm } from 'components'
import { useParams } from 'react-router-dom'

const FinancesSavingsAndInvestmentsForm = ({ }) => {
  return (
    <AccountWrapper
      accountType="SAVING_INVESTMENT"
      schema={schema}
      form={SavingsAndInvestmentsForm}
      location={useParams()}
    />
  )
}

export default FinancesSavingsAndInvestmentsForm
