import { Header } from 'components'
import { Col, Nav, Row } from 'react-bootstrap'
import { Link, useResolvedPath, useMatch } from 'react-router-dom'

const NavLink = ({ children, to, ...props }) => {
  let resolved = useResolvedPath(to);
  let match = useMatch(resolved.pathname + "/*");

  return (
    <Nav.Item>
      <Nav.Link as={Link} to={to} active={match}>
        {children}
      </Nav.Link>
    </Nav.Item>
  )
}

const TabbedNavigation = ({ title, pretitle = null, links }) => {
  return (
    <Header>
      <Header.Body>
        <Row className="align-items-end">
          <Col>
           {pretitle && <Header.Pretitle>{pretitle}</Header.Pretitle>}
            <Header.Title>{title}</Header.Title>
          </Col>
          <Col xs="auto">
            <Header.Tabs>
              {links.map((link, i) => (
                <NavLink to={link.to} key={i}>
                  {link.label}
                </NavLink>
              ))}
            </Header.Tabs>
          </Col>
        </Row>
      </Header.Body>
    </Header>
  )
}

export default TabbedNavigation
