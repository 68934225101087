import React from "react";
import { Badge, Button, Card } from "react-bootstrap";

export default function RetirementModellerWidget({ ...props }) {
  return (
    <Card className="retirement-widget" {...props}>
      <Card className="retirement-image"></Card>

      <Card.Body className="retirement-content d-flex flex-column text-center justify-content-center">
        <Badge
          bg="success"
          className="position-absolute"
          style={{ top: 20, right: 20 }}
        >
          New!
        </Badge>
        <h1 className="text-light">Retirement Modeller</h1>
        <p className="text-light">
          Get an estimate of when you can retire based today's lifestyle and
          your potential future finances
        </p>
        <div>
          <Button
            variant="outline-light"
            className="stretched-link"
            href="/retirement-plan"
          >
            See modeller
          </Button>
        </div>
      </Card.Body>
    </Card>
  );
}
