import { Modal } from 'containers'

const ModalDashboardIntro = ({ isVisible, onClose }) => {
  return (
    <Modal heading="Welcome to WealthSteer" isVisible={isVisible} onClose={onClose}>
      <h3>You’re almost there, here are three steps to get you started. </h3>
      <ol>
        <li>Now just add some info on your savings, debts, pensions, investments etc </li>
        <li>Explore your ‘Monthly Cash Flow’, see how your money is really allocated monthly</li>
        <li>Look at your ‘Future Finance’, we’ve projected your money over the next 5 years!</li>
      </ol>
      <p>
        This is just the start, we’re going to be launching new financial planning features over the coming weeks and months - your feedback is hugely important!”
      </p>
    </Modal>
  )
}

export default ModalDashboardIntro
