import { Header } from 'components'

const FormHeader = ({ title, subtext }) => (
  <Header className="mt-md-5">
    <Header.Title>{title}</Header.Title>
    {subtext && (
      <Header.Subtitle>
        {subtext}
      </Header.Subtitle>
    )}
  </Header>
)

export default FormHeader
