import React from "react";
import { connect } from 'react-redux'
import { compose } from 'redux'
import { firestoreConnect } from 'react-redux-firebase'
import { deleteAccount } from 'store/actions/financesActions'
import { Spinner } from "react-bootstrap";
import { DisasterPlan } from 'components'


const DisasterPlanPage = ({ accountData, auth, profile, ...props }) => {
  if (!accountData) {
    return <Spinner />;
  }

  return <DisasterPlan />
}

DisasterPlanPage.propTypes = {}

const mapStateToProps = state => {
  return {
    accountData: state.firestore.ordered.accounts
      ? state.firestore.ordered.accounts
      : [],
    auth: state.firebase.auth,
    profile: state.firebase.profile,
  }
}

const mapDispatchToProps = dispatch => {
  return {
    deleteAccount: id => dispatch(deleteAccount(id)),
  }
}

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  firestoreConnect(props => {
    if (!props.auth.uid) return []

    return [
      {
        collection: `accounts`,
        where: [["uid", "==", props.auth.uid]],
        orderBy: [`accountType`, `desc`],
      },
    ]
  })
)(DisasterPlanPage)


console.groupEnd();
