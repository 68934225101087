export default {
  action: [
    {
      icon: 'mail',
      title: 'Launchday 1.4.0 update email sent',
      content: 'Sent to all 1,851 subscribers over a 24 hour period',
    },
    {
      icon: 'archive',
      title: 'New project "Goodkit" created',
      content: 'Looks like there might be a new theme soon.',
    },
    {
      icon: 'code',
      title: 'Dashkit 1.5.0 was deployed.',
      content: 'A successful to deploy to production was executed.',
    },
    {
      icon: 'git-branch',
      title: '"Update Dependencies" branch was created.',
      content: 'This branch was created off of the "master" branch.',
    },
    {
      icon: 'mail',
      title: 'Launchday 1.4.0 update email sent',
      content: 'Sent to all 1,851 subscribers over a 24 hour period',
    },
    {
      icon: 'archive',
      title: 'New project "Goodkit" created',
      content: 'Looks like there might be a new theme soon.',
    },
    {
      icon: 'code',
      title: 'Dashkit 1.5.0 was deployed.',
      content: 'A successful to deploy to production was executed.',
    },
    {
      icon: 'git-branch',
      title: '"Update Dependencies" branch was created.',
      content: 'This branch was created off of the "master" branch.',
    },
    {
      icon: 'mail',
      title: 'Launchday 1.4.0 update email sent',
      content: 'Sent to all 1,851 subscribers over a 24 hour period',
    },
    {
      icon: 'archive',
      title: 'New project "Goodkit" created',
      content: 'Looks like there might be a new theme soon.',
    },
    {
      icon: 'code',
      title: 'Dashkit 1.5.0 was deployed.',
      content: 'A successful to deploy to production was executed.',
    },
    {
      icon: 'git-branch',
      title: '"Update Dependencies" branch was created.',
      content: 'This branch was created off of the "master" branch.',
    },
  ],
  user: [
    {
      imgSrc: '/img/avatars/profiles/avatar-1.jpg',
      title: 'Dianna Smiley',
      status: 'online',
      content: 'Uploaded the files "Launchday Logo" and "New Design".',
      date: '2m ago',
    },
    {
      imgSrc: '/img/avatars/profiles/avatar-2.jpg',
      title: 'Ab Hadley',
      status: 'online',
      content: 'Shared the "Why Dashkit?" post with 124 subscribers.',
      date: '1h ago',
    },
    {
      imgSrc: '/img/avatars/profiles/avatar-3.jpg',
      title: 'Adolfo Hess',
      status: 'offline',
      content: "Exported sales data from Launchday's subscriber data.",
      date: '3h ago',
    },
    {
      imgSrc: '/img/avatars/profiles/avatar-1.jpg',
      title: 'Dianna Smiley',
      status: 'online',
      content: 'Uploaded the files "Launchday Logo" and "New Design".',
      date: '2m ago',
    },
    {
      imgSrc: '/img/avatars/profiles/avatar-2.jpg',
      title: 'Ab Hadley',
      status: 'online',
      content: 'Shared the "Why Dashkit?" post with 124 subscribers.',
      date: '1h ago',
    },
    {
      imgSrc: '/img/avatars/profiles/avatar-3.jpg',
      title: 'Adolfo Hess',
      status: 'offline',
      content: "Exported sales data from Launchday's subscriber data.",
      date: '3h ago',
    },
    {
      imgSrc: '/img/avatars/profiles/avatar-1.jpg',
      title: 'Dianna Smiley',
      status: 'online',
      content: 'Uploaded the files "Launchday Logo" and "New Design".',
      date: '2m ago',
    },
    {
      imgSrc: '/img/avatars/profiles/avatar-2.jpg',
      title: 'Ab Hadley',
      status: 'online',
      content: 'Shared the "Why Dashkit?" post with 124 subscribers.',
      date: '1h ago',
    },
    {
      imgSrc: '/img/avatars/profiles/avatar-3.jpg',
      title: 'Adolfo Hess',
      status: 'offline',
      content: "Exported sales data from Launchday's subscriber data.",
      date: '3h ago',
    },
    {
      imgSrc: '/img/avatars/profiles/avatar-1.jpg',
      title: 'Dianna Smiley',
      status: 'online',
      content: 'Uploaded the files "Launchday Logo" and "New Design".',
      date: '2m ago',
    },
    {
      imgSrc: '/img/avatars/profiles/avatar-2.jpg',
      title: 'Ab Hadley',
      status: 'online',
      content: 'Shared the "Why Dashkit?" post with 124 subscribers.',
      date: '1h ago',
    },
    {
      imgSrc: '/img/avatars/profiles/avatar-3.jpg',
      title: 'Adolfo Hess',
      status: 'offline',
      content: "Exported sales data from Launchday's subscriber data.",
      date: '3h ago',
    },
  ],
};
