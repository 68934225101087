import { Col, Container, Row } from 'react-bootstrap';
import PropTypes from 'prop-types'
import { AccountTable } from 'components'

import { connect } from "react-redux"
import { firestoreConnect } from "react-redux-firebase"
import { compose } from "redux"
import { deleteAccount } from "store/actions/financesActions"
import { formatCurrency } from "helpers"
import { AccountTableActions } from "components"

const tableCols = [
  `Name`,
  `Property Type`,
  `Property Value`,
  `Rental Income`,
  `Actions`,
]

const FinancesPropertyTable = ({ accounts, deleteAccount }) => {
  const data = accounts.map(property => {
    return [
      <a href={`/finances/properties/${property.id}`}>{property.name}</a>,
      property.propertyType,
      formatCurrency(property.propertyValue),
      formatCurrency(property.montlyIncome),
      <AccountTableActions
        id={property.id}
        editPath={`/finances/properties/${property.id}`}
        onClickDelete={deleteAccount}
      />,
    ]
  })

  return (
    <AccountTable
      heading="Property"
      columns={tableCols}
      data={data}
      noData="No data"
      addNewPath="/finances/properties/add"
    />
  );
}

const mapStateToProps = state => {
  return {
    accounts: state.firestore.ordered.accounts
      ? state.firestore.ordered.accounts
      : [],
    auth: state.firebase.auth,
  }
}

const mapDispatchToProps = dispatch => {
  return {
    deleteAccount: id => dispatch(deleteAccount(id)),
  }
}

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  firestoreConnect(props => {
    if (!props.auth.uid) return []

    return [
      {
        collection: `accounts`,
        where: [
          ["uid", "==", props.auth.uid],
          ["accountType", "==", `PROPERTY`],
        ],
        orderBy: [`propertyValue`, `desc`],
      },
    ]
  })
)(FinancesPropertyTable)
