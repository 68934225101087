import { AdviceCard, DisasterPlanWidget } from "components/widgets";
import { Card, Col, Container, Row, Spinner } from "react-bootstrap";
import DisasterPlanHeader from "components/widgets/DisasterPlanHeader";
import EmergencyFundWidget from "components/widgets/EmergencyFundWidget";
import EmergencyFundTarget from "components/widgets/EmergencyFundTarget";
import InsuranceCoverTable from "components/widgets/InsuranceCoverTable";
import IncomeProtectionPosition from "components/widgets/IncomeProtectionPosition";

import {
  calculateBreakdown,
  calculateCashflow,
  DashboardHelpers,
  formatCurrency,
  formatAccountTitle,
} from "helpers";

import {
  aggregateUserData,
  calculateTotals,
  calculateNetWorth,
  calculateOutgoings,
  calculateHowLongNetWorthWouldLast,
  calculateRequiredEmergencyFund,
  calculateDistanceFromEmegencyFund,
  calculateMinLifeInsurancePosition,
  calculateMaxLifeInsurancePosition,
  calculateCriticalIllnessCoverPosition
} from 'calcs'

// Todo: debate putting in cover amount,
let aggregated = {
  personOne: {
    basicOutgoings: 900, // C5
    debtPayments: 200, // C6
    mortgages: 0, // C8
    debts: -6000, // C9
    savings: 30000, // C10
    investments: 1000, // C11
    lifeInsuranceCover: 500000, // C30
    criticalIllnessCover: 0,// C44
    incomeProtection: 500, // C58
  },
  personTwo: {
    basicOutgoings: 700, // D5
    debtPayments: 150,// D6
    mortgages: 0, // D8
    debts: -4000, // D9
    savings: 10000, // D10
    investments: 5000, // D11
    lifeInsuranceCover: 250000, // D30
    criticalIllnessCover: 0,// D44
    incomeProtection: 2000 // D58
  },
  joint: {
    basicOutgoings: 500, // F5
    debtPayments: 1000, // F6
    mortgages: -200000, // F8
    debts: 0, // F9
    savings: 2000, // DF0
    investments: 0, // DF1
    criticalIllnessCover: 30000// C44
  }
}

// aggregated = {
//   personOne: {
//     basicOutgoings: 0, // C5
//     debtPayments: 0, // C6
//     mortgages: 0, // C8
//     debts: 0, // C9
//     savings: 0, // C10
//     investments: 0 // C11
//   },
//   personTwo: {
//     basicOutgoings: 0, // D5
//     debtPayments: 0,// D6
//     mortgages: 0, // D8
//     debts: 0, // D9
//     savings: 0, // D10
//     investments: 0 // D11
//   },
//   joint: {
//     basicOutgoings: 0, // F5
//     debtPayments: 0, // F6
//     mortgages: 0, // F8
//     debts: 0, // F9
//     savings: 0, // DF0
//     investments: 0 // DF1
//   }
// }

aggregated = {
  ...aggregated,
  totals: calculateTotals(aggregated)
}

const personOneNetWorth = calculateNetWorth(aggregated.personOne, aggregated.joint)
const personOneOutgoings = calculateOutgoings(aggregated.personOne, aggregated.joint)
const personOneThreeMonthFund = calculateRequiredEmergencyFund(personOneOutgoings)
const personOneTwelveMonthFund = calculateRequiredEmergencyFund(personOneOutgoings, 12)
const personOneThreeMonthDistance = calculateDistanceFromEmegencyFund(personOneThreeMonthFund, personOneNetWorth)
const personOneTwelveMonthDistance = calculateDistanceFromEmegencyFund(personOneTwelveMonthFund, personOneNetWorth)

const personTwoNetWorth = calculateNetWorth(aggregated.personTwo, aggregated.joint)
const personTwoOutgoings = calculateOutgoings(aggregated.personTwo, aggregated.joint)
const personTwoThreeMonthFund = calculateRequiredEmergencyFund(personTwoOutgoings)
const personTwoTwelveMonthFund = calculateRequiredEmergencyFund(personTwoOutgoings, 12)
const personTwoThreeMonthDistance = calculateDistanceFromEmegencyFund(personTwoThreeMonthFund, personTwoNetWorth)
const personTwoTwelveMonthDistance = calculateDistanceFromEmegencyFund(personTwoTwelveMonthFund, personTwoNetWorth)

const _data = {
  personOne: {
    netWorth: personOneNetWorth,
    outgoings: personOneOutgoings,
    howLongWouldNetWorthLast: calculateHowLongNetWorthWouldLast(personOneNetWorth, personOneOutgoings),
    threeMonthFund: personOneThreeMonthFund,
    twelveMonthFund: personOneTwelveMonthFund,
    threeMonthFundDistance: personOneThreeMonthDistance,
    twelveMonthDistance: personOneTwelveMonthDistance
  },
  personTwo: {
    netWorth: personTwoNetWorth,
    outgoings: personTwoOutgoings,
    howLongWouldNetWorthLast: calculateHowLongNetWorthWouldLast(personTwoNetWorth, personTwoOutgoings),
    threeMonthFund: personTwoThreeMonthFund,
    twelveMonthFund: personTwoTwelveMonthFund,
    threeMonthFundDistance: personTwoThreeMonthDistance,
    twelveMonthDistance: personTwoTwelveMonthDistance
  },
  // joint: {

  // }
}

const DisasterPlan = (props) => {

  const aggregated = aggregateUserData(props.profile, props.accounts);

  const personOneNetWorth = calculateNetWorth(aggregated.personOne, aggregated.joint)
  const personOneOutgoings = calculateOutgoings(aggregated.personOne, aggregated.joint)
  const personOneThreeMonthFund = calculateRequiredEmergencyFund(personOneOutgoings)
  const personOneTwelveMonthFund = calculateRequiredEmergencyFund(personOneOutgoings, 12)
  const personOneThreeMonthDistance = calculateDistanceFromEmegencyFund(personOneThreeMonthFund, personOneNetWorth)
  const personOneTwelveMonthDistance = calculateDistanceFromEmegencyFund(personOneTwelveMonthFund, personOneNetWorth)


  const personOneMinLifeInsurancePosition = calculateMinLifeInsurancePosition(aggregated.personOne, aggregated.joint)
  const personOneMinLifeInsurancePositionShortfall = (aggregated.personOne.lifeInsuranceCover - personOneMinLifeInsurancePosition) * -1;
  const personOneMaxLifeInsurancePosition = calculateMaxLifeInsurancePosition(aggregated.personOne, aggregated.joint)
  const personOneMaxLifeInsurancePositionShortfall = (aggregated.personOne.lifeInsuranceCover - personOneMaxLifeInsurancePosition) * -1;

  const personOneLifeInsurancePosition = {
    min: personOneMinLifeInsurancePosition,
    minShortfall: personOneMinLifeInsurancePositionShortfall,
    max: personOneMaxLifeInsurancePosition,
    maxShortfall: personOneMaxLifeInsurancePositionShortfall
  }

  const personTwoMinLifeInsurancePosition = calculateMinLifeInsurancePosition(aggregated.personTwo, aggregated.joint)
  const personTwoMinLifeInsurancePositionShortfall = (aggregated.personTwo.lifeInsuranceCover - personTwoMinLifeInsurancePosition) * -1;
  const personTwoMaxLifeInsurancePosition = calculateMaxLifeInsurancePosition(aggregated.personTwo, aggregated.joint)
  const personTwoMaxLifeInsurancePositionShortfall = (aggregated.personTwo.lifeInsuranceCover - personTwoMaxLifeInsurancePosition) * -1;

  const personTwoLifeInsurancePosition = {
    min: personTwoMinLifeInsurancePosition,
    minShortfall: personTwoMinLifeInsurancePositionShortfall,
    max: personTwoMaxLifeInsurancePosition,
    maxShortfall: personTwoMaxLifeInsurancePositionShortfall
  }

  const personOneMinCriticalIllnessCoverPosition = calculateCriticalIllnessCoverPosition(personOneOutgoings, personOneNetWorth)
  const personOneMinCriticalIllnessCoverPositionShortfall = personOneMinCriticalIllnessCoverPosition - aggregated.joint.criticalIllnessCover
  const personOneMaxCriticalIllnessCoverPosition = calculateCriticalIllnessCoverPosition(personOneOutgoings, personOneNetWorth, 60)
  const personOneMaxCriticalIllnessCoverPositionShortfall = personOneMaxCriticalIllnessCoverPosition - aggregated.joint.criticalIllnessCover

  const personOneCriticalIllnessCoverPosition = {
    min: personOneMinCriticalIllnessCoverPosition,
    minShortfall: personOneMinCriticalIllnessCoverPositionShortfall,
    max: personOneMaxCriticalIllnessCoverPosition,
    maxShortfall: personOneMaxCriticalIllnessCoverPositionShortfall
  }

  // const cicMinPosition = calculateCriticalIllnessCoverPosition(personOneOutgoings, personOneNetWorth);
  // const cicMinshortfall = cicMinPosition - aggregated.joint.criticalIllnessCover;

  // console.log(cicMinPosition, cicMinshortfall,  aggregated.joint.criticalIllnessCover)

  const personTwoNetWorth = calculateNetWorth(aggregated.personTwo, aggregated.joint)
  const personTwoOutgoings = calculateOutgoings(aggregated.personTwo, aggregated.joint)
  const personTwoThreeMonthFund = calculateRequiredEmergencyFund(personTwoOutgoings)
  const personTwoTwelveMonthFund = calculateRequiredEmergencyFund(personTwoOutgoings, 12)
  const personTwoThreeMonthDistance = calculateDistanceFromEmegencyFund(personTwoThreeMonthFund, personTwoNetWorth)
  const personTwoTwelveMonthDistance = calculateDistanceFromEmegencyFund(personTwoTwelveMonthFund, personTwoNetWorth)

  const personTwoMinCriticalIllnessCoverPosition = calculateCriticalIllnessCoverPosition(personTwoOutgoings, personTwoNetWorth)
  const personTwoMinCriticalIllnessCoverPositionShortfall = personTwoMinCriticalIllnessCoverPosition - aggregated.joint.criticalIllnessCover
  const personTwoMaxCriticalIllnessCoverPosition = calculateCriticalIllnessCoverPosition(personTwoOutgoings, personTwoNetWorth, 60)
  const personTwoMaxCriticalIllnessCoverPositionShortfall = personTwoMaxCriticalIllnessCoverPosition - aggregated.joint.criticalIllnessCover

  const personTwoCriticalIllnessCoverPosition = {
    min: personTwoMinCriticalIllnessCoverPosition,
    minShortfall: personTwoMinCriticalIllnessCoverPositionShortfall,
    max: personTwoMaxCriticalIllnessCoverPosition,
    maxShortfall: personTwoMaxCriticalIllnessCoverPositionShortfall
  }

  const data = {
    personOne: {
      netWorth: personOneNetWorth,
      outgoings: personOneOutgoings,
      howLongWouldNetWorthLast: calculateHowLongNetWorthWouldLast(personOneNetWorth, personOneOutgoings),
      threeMonthFund: personOneThreeMonthFund,
      twelveMonthFund: personOneTwelveMonthFund,
      threeMonthFundDistance: personOneThreeMonthDistance,
      twelveMonthDistance: personOneTwelveMonthDistance
    },
    personTwo: {
      netWorth: personTwoNetWorth,
      outgoings: personTwoOutgoings,
      howLongWouldNetWorthLast: calculateHowLongNetWorthWouldLast(personTwoNetWorth, personTwoOutgoings),
      threeMonthFund: personTwoThreeMonthFund,
      twelveMonthFund: personTwoTwelveMonthFund,
      threeMonthFundDistance: personTwoThreeMonthDistance,
      twelveMonthDistance: personTwoTwelveMonthDistance
    },
    joint: {

    }
  }

  console.log({
    aggregated,
    data
  })

  return (
    <>
      <Row className="bg-dark">
        <Col>
          {/*<DisasterPlanHeader />*/}
        </Col>
      </Row>
      <Row className="mt-3">
        <Col md={5}>
          <EmergencyFundWidget />
        </Col>
        <Col md={7}>
          <EmergencyFundTarget
            title={"3 Month emergency fund target"}
            current={data.personOne.netWorth}
            target={data.personOne.threeMonthFund}
          />
          <EmergencyFundTarget
            title={"12 Month emergency fund target"}
            current={data.personOne.netWorth}
            target={data.personOne.twelveMonthFund}
          />
          <Row>
            <Col md={4}>
              <AdviceCard icon="frown" heading="Reduce expenses" />
            </Col>
            <Col md={4}>
              <AdviceCard icon="anchor" heading="Top-up savings" />
            </Col>
            <Col md={4}>
              <AdviceCard icon="home" heading="Lower Mortgage" />
            </Col>
          </Row>
        </Col>
      </Row>
      <Row>
        <Col md={6}>
          <InsuranceCoverTable
            title="Life insurance position"
            personOne={personOneLifeInsurancePosition}
            personTwo={personTwoLifeInsurancePosition}
          />
        </Col>
        <Col md={6}>
          {/* Critical illness is exactly the same */}
          <InsuranceCoverTable
            title="Critical Illness Cover"
            personOne={personOneCriticalIllnessCoverPosition}
            personTwo={personTwoCriticalIllnessCoverPosition}
          />
        </Col>
      </Row>
      <Row>
        <Col md={6}>
          <IncomeProtectionPosition
            title={`Your income protection position`}
            current={null}
            target={null}
          />
        </Col>
        <Col md={6}>
          <IncomeProtectionPosition
            title={`Your partners income protection position`}
            current={null}
            target={null}
          />
        </Col>
      </Row>
    </>
  );
}

export default DisasterPlan
