export const createUpdateAccount = (props) => {
  const { state, accountType, id = null } = props

  return (dispatch, getState, { getFirebase, getFirestore }) => {
    // make async call to database
    const firestore = getFirestore()
    const profile = getState().firebase.profile
    const uid = getState().firebase.auth.uid

    if (null === id) {
      firestore
        .collection(`accounts`)
        .add({
          ...state,
          accountType: accountType,
          uid: uid,
          createdAt: new Date(),
        })
        .then(() => {
          dispatch({ type: `CREATE_ACCOUNT`, state })
        })
        .catch(err => {
          console.log(err)
          dispatch({ type: `CREATE_ACCOUNT_ERROR`, err })
        })
    } else {
      firestore
        .collection(`accounts`)
        .doc(id)
        .set(
          {
            ...state,
            accountType: accountType,
            uid: uid,
            updatedAt: new Date(),
          },
          { merge: true }
        )
        .then(() => {
          dispatch({ type: `UPDATE_ACCOUNT`, state })
        })
        .catch(err => {
          dispatch({ type: `UPDATE_ACCOUNT_ERROR`, err })
        })
    }
  }
}

export const deleteAccount = id => {
  return (dispatch, getState, { getFirebase, getFirestore }) => {
    const firestore = getFirestore()
    const profile = getState().firebase.profile
    const uid = getState().firebase.auth.uid

    firestore
      .collection("accounts")
      .doc(id)
      .delete()
      .then(res => {
        dispatch({ type: `DELETE_ACCOUNT` })
      })
      .catch(err => {
        dispatch({ type: `DELETE_ACCOUNT_ERROR`, err })
      })
  }
}
