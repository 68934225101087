import React from "react"
import Select from "react-select/creatable"
import { Form } from 'react-bootstrap'

const formatValue = value => {
  return { label: value, value: value }
}

const SelectField = ({
  id,
  name,
  label,
  value = [],
  options,
  error,
  onChange = null,
  onBlur = null,
  mutedText = null,
  labelClass = null,
  inputClass = null,
  readOnly = false,
  placeholder = ``,
  isNumber = false,
}) => {
  value = typeof value === "string" ? [value] : value

  const handleChange = (value, action) => {
    onChange({
      target: {
        id: action.name,
        name: action.name,
        value: value.value,
      }
    })
  }

  return (
    <Form.Group className="mb-3">
      <Form.Label className={labelClass}>
        {label}
      </Form.Label>
      {mutedText && <small className="form-text text-muted">{mutedText}</small>}
      <Select
        name={name}
        defaultValue={value && value.map(formatValue)}
        options={options && options.map(formatValue)}
        onChange={handleChange}
      />
      {error && (
        <div className="invalid-feedback" style={{ display: `block` }}>
          {error}
        </div>
      )}
    </Form.Group>
  )
}

export default SelectField
