export default {
  base: {
    children: ['dashboards'],
    id: 'base',
  },
  docs: {
    children: ['basics', 'knowledgeBase'],
    id: 'docs',
    title: 'Documentation',
  },
  dashboards: {
    children: ['default', 'eCommerce'],
    icon: 'home',
    id: 'dashboards',
    title: 'Dashboards',
  },
  basics: {
    children: ['savingInvestment', 'pensions', 'property', 'insurance', 'debts'],
    icon: 'activity',
    id: 'basics',
    title: 'Your Finances',
  },
  knowledgeBase: {
    url: `/`,
    icon: 'book-open',
    id: 'knowledgeBase',
    title: 'Knowledge Base',
  },
  default: {
    id: 'default',
    title: 'Default',
    url: '/',
  },
  eCommerce: {
    id: 'eCommerce',
    title: 'E-Commerce',
    url: '/dashboard-ecommerce',
  },
  savingInvestment: {
    id: 'savingInvestment',
    title: 'Savings & Investments',
    url: '/finances/savings-and-investments',
  },
  pensions: {
    id: 'pensions',
    title: 'Pensions',
    url: '/finances/pensions',
  },
  debts: {
    id: 'debts',
    title: 'Debts',
    url: '/finances/debts',
  },
  property: {
    id: 'property',
    title: 'Property',
    url: '/finances/property',
  },
  insurance: {
    id: 'insurance',
    title: 'Insurance',
    url: '/finances/insurance',
  },
  accountSecurity: {
    id: 'accountSecurity',
    title: 'Security',
    url: '/account-security',
  },
};
