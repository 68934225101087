import { Col, Container, Nav, Row } from 'react-bootstrap';
import PropTypes from 'prop-types'
import { Outlet } from 'react-router-dom'
import { Header, TabbedNavigation } from 'components'
import { profileLinks as links } from 'config/routing'

const Profile = ({  }) => {
  return (
    <div className="main-content">
      <Container fluid>
        <Row className="justify-content-center">
          <Col xs={12} lg={10} xl={8} className="py-6">
            <TabbedNavigation
              title="Profile"
              pretitle="Overview"
              links={links}
            />
            <Outlet />
          </Col>
        </Row>
      </Container>
    </div>
  );
}

export default Profile
