import PropTypes from 'prop-types'
import { Button, Card, Col, Container, Form, Nav, Row } from 'react-bootstrap';
import { Dropzone, Flatpickr, Quill, Select } from 'components/vendor';
import FeatherIcon from 'feather-icons-react';
import { WizardFooter, WizardHeader } from 'components';
import { FormWrapper } from 'containers'
import { BasicsForm } from 'components'
import { basics as schema } from 'config/schemas'
import { useNavigate } from "react-router-dom";

import { Spinner } from 'react-bootstrap';
import { toast } from 'react-toastify';

// Data
import { connect } from "react-redux"
import { saveBasics } from "store/actions/userActions"

const GettingStartedBasics = ({ auth, profile, saveBasics }) => {
  let navigate = useNavigate();
  const activeStep = 1
  const stepCount = 2

  if (!profile.isLoaded) {
    return <Spinner />;
  }

  const component = ({ handleSubmit, ...props}) => {
    return (
      <>
        <form onSubmit={handleSubmit}>
          <WizardHeader
            title="Let’s start with the basics."
            summary="This should take 5 minutes. Don’t worry you don’t need to do it all at once and we’re on hand if you’re stuck on anything."
            activeStep={activeStep}
            stepCount={stepCount}
          />
          <BasicsForm {...props} />
          <WizardFooter
            activeStep={activeStep}
            stepCount={stepCount}
          />
        </form>
      </>
    )
  }

  return (
    <>
      <FormWrapper
        schema={schema}
        component={component}
        auth={auth}
        state={profile}
        onSubmit={saveBasics}
        onSuccess={() => {
          toast.success('Basics saved successfully.')
          navigate(`/getting-started/people`);
        }}
      />
    </>
  );
}


const mapStateToProps = state => {
  return {
    auth: state.firebase.auth,
    profile: state.firebase.profile,
  }
}

const mapDispatchToProps = dispatch => {
  return {
    saveBasics: basics => dispatch(saveBasics(basics)),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(GettingStartedBasics)

// export default GettingStartedBasics
