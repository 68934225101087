import PropTypes from 'prop-types'
import { Col, Row } from 'react-bootstrap'
import { FormHeader } from 'components'
import {
  CurrencyField,
  RadioToggleField,
  SelectField,
  TextField,
} from 'components/form'
import { useForm } from 'helpers'
import { interestTypeOptions, propertyTypeOptions } from 'config/schemas'
import FinanceFormActions from './FinanceFormActions'

const PropertyForm = ({ state, errors, success, handleChange, handleSubmit }) => {
  return (
    <form className="" id="PropertyForm" onSubmit={handleSubmit}>
    <FormHeader title="Add a new property" />
      <SelectField
        name="propertyType"
        label="Property Type"
        mutedText=""
        onChange={handleChange}
        value={state.propertyType}
        options={propertyTypeOptions}
        error={errors.propertyType}
      />

      {state.propertyType && (
        <TextField
          label="Name"
          mutedText="A friendly label so you know what's what"
          inputClass="form-control"
          name="name"
          id="name"
          placeholder="My home"
          value={state.name}
          onChange={handleChange}
          error={errors.name}
        />
      )}

      {state.propertyType === `Main Residence` && (
        <MainResidenceForm
          state={state}
          errors={errors}
          onChange={handleChange}
          onSelectChange={handleChange}
        />
      )}

      {state.propertyType === `Buy-to-let` && (
        <BuyToLetForm state={state} errors={errors} onChange={handleChange} />
      )}

      <FinanceFormActions cancelPath="/finances/debts" />
    </form>
  )
}

PropertyForm.propTypes = {}

const MortgageFields = ({ state, errors, onChange, onSelectChange }) => (
  <div>
    <RadioToggleField
      id="isMortgaged"
      label="Do you have a mortgage on this property?"
      value={state.isMortgaged}
      onChange={onChange}
      error={errors.isMortgaged}
    />
    {state.isMortgaged && (
      <div>
        <CurrencyField
          label="What is the balance on your mortgage?"
          inputClass="form-control"
          name="mortgageBalance"
          id="mortgageBalance"
          value={state.mortgageBalance}
          onChange={onChange}
          error={errors.mortgageBalance}
        />
        <SelectField
          id="interestType"
          name="interestType"
          label="Interest Type"
          mutedText=""
          onChange={onSelectChange}
          value={state.interestType}
          options={interestTypeOptions}
          error={errors.interestType}
        />
        <CurrencyField
          label="What is the total monthly mortgage payment?"
          inputClass="form-control"
          name="monthlyMortgagePayment"
          id="monthlyMortgagePayment"
          value={state.monthlyMortgagePayment}
          onChange={onChange}
          error={errors.monthlyMortgagePayment}
        />
      </div>
    )}
  </div>
)

const MainResidenceForm = ({ state, errors, onChange, onSelectChange }) => (
  <div>
    <CurrencyField
      label="What is the total value of this property?"
      inputClass="form-control"
      name="propertyValue"
      id="propertyValue"
      value={state.propertyValue}
      onChange={onChange}
      error={errors.propertyValue}
    />
  </div>
)

const BuyToLetForm = ({ state, errors, onChange }) => (
  <div>
    <CurrencyField
      label="What is the total value of this property?"
      inputClass="form-control"
      name="propertyValue"
      id="propertyValue"
      value={state.propertyValue}
      onChange={onChange}
      error={errors.propertyValue}
    />
    <CurrencyField
      label="What is your total monthly income on this property?"
      inputClass="form-control"
      name="monthlyIncome"
      id="monthlyIncome"
      value={state.monthlyIncome}
      onChange={onChange}
      error={errors.monthlyIncome}
    />
  </div>
)


export default PropertyForm
