import PropTypes from 'prop-types'
import { Col, Nav, Button } from 'react-bootstrap'
import { Link } from 'react-router-dom'

const WizardFooter = ({
  activeStep,
  stepCount,
  cancelPath,
  continuePath = null,
}) => (
  <>
    <hr className="my-5" />
    <Nav className="row align-items-center">
      <Col xs="auto">
        {!cancelPath && (
          <Button variant="white" size="lg" disabled>
            Back
          </Button>
        )}
        {cancelPath && (
          <Button as={Link} to={cancelPath} variant="white" size="lg">
            Back
          </Button>
        )}
      </Col>
      <Col className="text-center">
        <h6 className="text-uppercase text-muted mb-0">Step {activeStep} of {stepCount}</h6>
      </Col>
      <Col xs="auto">
      {!continuePath && (
        <Button size="lg" type="submit">
          Continue
        </Button>
      )}
      {continuePath && (
        <Button size="lg" as={Link} to={continuePath} >
          Continue
        </Button>
      )}
      </Col>
    </Nav>
  </>
)

WizardFooter.propTypes = {}

export default WizardFooter
