import PropTypes from 'prop-types'
import { Button, Col, Row } from 'react-bootstrap'
import { FormHeader } from 'components'
import {
  TextField,
  CurrencyField,
  FrequencyField,
  SelectField,
  PercentageField,
} from 'components/form'
import { useForm } from 'helpers'
import { debtTypeOptions, accountHolderOptions, interestTypeOptions } from 'config/schemas'
import FinanceFormActions from './FinanceFormActions'
import providers from 'config/providers'

const DebtForm = ({ state, errors, success, handleChange, handleSubmit }) => {
  const lenders = providers.getLenderByType(state.type).map(p => (p.label))
  return (
    <form className="" id="DebtsForm" onSubmit={handleSubmit}>
      <FormHeader title="Add a new debt" />

        <SelectField
          id="accountHolder"
          name="accountHolder"
          label="Account Holder"
          mutedText=""
          onChange={handleChange}
          value={state.accountHolder}
          error={errors.accountHolder}
          options={accountHolderOptions}
        />

        <SelectField
          name="type"
          label="Type"
          mutedText=""
          onChange={(props) => {
            state.lender = null

            handleChange({
              target: {
                id: `lender`,
                name: `lender`,
                value: null,
              }
            })
            handleChange(props)
          }}
          value={state.type}
          error={errors.type}
          options={debtTypeOptions}
        />

        <SelectField
          name="lender"
          label="Lender"
          mutedText=""
          onChange={handleChange}
          value={state.lender}
          error={errors.lender}
          options={lenders}
          formattedData
        />

        {state.type === `Other` && (
          <TextField
            label="Please explain..."
            inputClass="form-control"
            name="typeOther"
            id="typeOther"
            value={state.typeOther}
            onChange={handleChange}
            error={errors.typeOther}
          />
        )}

        <CurrencyField
          label="What is the total balance of this debt?"
          inputClass="form-control"
          name="balance"
          id="balance"
          value={state.balance}
          onChange={handleChange}
          error={errors.balance}
        />

        <CurrencyField
          label="Monthly cost"
          inputClass="form-control"
          name="monthlyCost"
          id="monthlyCost"
          value={state.monthlyCost}
          onChange={handleChange}
          error={errors.monthlyCost}
        />

        {state.type !== `Credit Card` && (
          <FrequencyField
            label="Remaining term"
            name="remainingTerm"
            id="remainingTerm"
            idFrequency="remainingTermFrequency"
            name="remainingTerm"
            nameFrequency="remainingTermFrequency"
            value={state.remainingTerm}
            valueFrequency={state.remainingTermFrequency}
            onChange={handleChange}
            onChangeFrequency={handleChange}
            error={errors.remainingTerm}
            errorFrequency={errors.remainingTermFrequency}
          />
        )}

        <PercentageField
          label="Annual Interest Rate"
          name="interestRate"
          value={state.interestRate}
          error={errors.interestRate}
          onChange={handleChange}
          mutedText=""
        />
      {state.type === `Mortgage` && (
        <div>
          <SelectField
            id="interestType"
            name="interestType"
            label="Interest Type"
            mutedText=""
            onChange={handleChange}
            value={state.interestType}
            options={interestTypeOptions}
            error={errors.interestType}
          />
        </div>
      )}
      <FinanceFormActions cancelPath="/finances/debts" />
    </form>
  )
}

DebtForm.propTypes = {}

export default DebtForm
