import React, { useState } from 'react';
import { Button, Card, Form, Nav } from 'react-bootstrap';
import { Doughnut, Pie } from 'react-chartjs-2';
import { Chart, ChartLegend } from '../vendor';
import cloneDeep from 'lodash/cloneDeep';
import { formatCurrency, getWindowDimensions } from 'helpers'

const sumValues = obj => Object.values(obj).reduce((a, b) => a + b);

const convertValues = (total, obj, showPercentage) => {
  if (showPercentage) {
    Object.keys(obj).forEach(function (key) {
      obj[key] = ((obj[key] / total) * 100).toFixed(0);
    });
  } else {
    Object.keys(obj).forEach(function (key) {
      obj[key] = formatCurrency(obj[key]);
    });
  }

  return obj;
};



export default function CashflowChart({ data: { surplus, outgoings }, ...props }) {
  const [index, setIndex] = useState(0);
  const [showPercentage, setShowPercentage] = useState(true);
  const { width } = getWindowDimensions();

  const toggleDisplay = () => {
    setShowPercentage(!showPercentage);
  };

  const op = convertValues(sumValues({ ...outgoings }), { ...outgoings }, showPercentage);
  const sp = convertValues(sumValues({ ...surplus }), { ...surplus }, showPercentage);

  const labels = [
    [
      `Mortgage (${showPercentage ? op.mortgage + "%" : op.mortgage})`,
      `Living Costs (${showPercentage ? op.livingCosts + "%" : op.livingCosts})`,
      `Savings (${showPercentage ? op.savings + "%" : op.savings})`,
      `Pension (${showPercentage ? op.pension + "%" : op.pension})`,
      `Debt (${showPercentage ? op.debtRepayments + "%" : op.debtRepayments})`
    ],
    [
      `Outgoings (${showPercentage ? sp.outgoings + "%" : sp.outgoings})`,
      `Available Cash (${showPercentage ? sp.surplus + "%" : sp.surplus})`
    ],
  ];
  
  

  const datasets = [
    {
      data: Object.values(outgoings),
      backgroundColor: ['#7E59B0', '#62C5EA', '#E94B8B', '#FCB425', '#2C7BE5'],
      borderWidth: 0,
    },
    {
      data: Object.values(surplus),
      backgroundColor: ['#7E59B0', '#62C5EA', '#E94B8B', '#FCB425', '#2C7BE5'],
      borderWidth: 0,
    },
  ];

  const options = {
    plugins: {
      legend: {
        display: true,
        labels: {
          color: '',
        },
        position: width > 500 ? `right` : `bottom`,
      },
      tooltip: {
        enabled: false,
        callbacks: {
          label: function (context) {
            if (showPercentage) {
              return context.label
            } else {
              return formatCurrency(context.raw);
            }
          },
        },
      },
    },
  };

  return (
    <Card {...props} className="">
      <Card.Header>
        {/* <h4 className="card-header-title">Money In / Money Out</h4> */}
        <Nav variant="tabs" className="nav-tabs-sm">
          <Nav.Item>
            <Nav.Link active={index === 0} onClick={() => setIndex(0)} role="button">
              Outgoings
            </Nav.Link>
          </Nav.Item>
          <Nav.Item>
            <Nav.Link active={index === 1} onClick={() => setIndex(1)} role="button">
              Surplus
            </Nav.Link>
          </Nav.Item>
        </Nav>
        {/* <Button variant="light" className="btn-sm ms-4" onClick={toggleDisplay}>
          % / £
        </Button> */}
        <Form>
      <Form.Check // prettier-ignore
        type="switch"
        id="custom-switch"
        label="£ / %"
        onClick={toggleDisplay}
      />
    </Form>
      </Card.Header>
      <Card.Body>
        <Chart layout="appended">
          <Pie data={{ labels: labels[index], datasets: [{ ...datasets[index] }] }} options={options} />
        </Chart>
        {/*<ChartLegend data={{ labels: labels[index], datasets: datasets }} />*/}
      </Card.Body>
    </Card>
  );
}
