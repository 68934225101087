const initState = {}

const userReducer = (state = initState, action) => {
  switch (action.type) {
    case `SAVE_BASICS`:
      return {
        ...state,
        // success: `Saved successfully.`
      }
    case `SAVE_BASICS_ERROR`:
      return state
    case `SAVE_INCOME`:
      return state
    case `SAVE_INCOME_ERROR`:
      return state
    case `SAVE_FINANCIALS`:
      return state
    case `SAVE_FINANCIALS_ERROR`:
      return state
    case `SAVE_CURRENT_COVER`:
      return state
    case `SAVE_CURRENT_COVER_ERROR`:
      return state
    default:
      return state
  }
}

export default userReducer
