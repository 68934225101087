import { Col, Container, Row } from 'react-bootstrap';
import PropTypes from 'prop-types'
import { AccountTable } from 'components'

import { connect } from "react-redux"
import { firestoreConnect } from "react-redux-firebase"
import { compose } from "redux"
import { deleteAccount } from "store/actions/financesActions"
import { formatCurrency } from "helpers"
import { AccountTableActions } from "components"

const tableCols = [
  `Provider`,
  `Balance / Yearly Pension (pa)`,
  `Monthly Contributions`,
  `Actions`,
]

const FinancesPensionsTable = ({ accounts, auth, deleteAccount }) => {
  const data = accounts.map(pension => {
    const balance =
      `Defined Contribution` === pension.type
        ? formatCurrency(pension.value)
        : formatCurrency(pension.yearlyPension)

    const provider =
      `Other` === pension.provider ? pension.providerOther : pension.provider

    return [
      provider,
      balance,
      formatCurrency(pension.monthlyPersonalContributions + pension.monthlyWorkContributions),
      <AccountTableActions
        id={pension.id}
        editPath={`/finances/pensions/${pension.id}`}
        onClickDelete={deleteAccount}
      />,
    ]
  })

  return (
    <AccountTable
      heading="Pensions"
      columns={tableCols}
      data={data}
      noData="No data"
      addNewPath="/finances/pensions/add"
    />
  );
}

const mapStateToProps = state => {
  return {
    accounts: state.firestore.ordered.accounts
      ? state.firestore.ordered.accounts
      : [],
    auth: state.firebase.auth,
  }
}

const mapDispatchToProps = dispatch => {
  return {
    deleteAccount: id => dispatch(deleteAccount(id)),
  }
}

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  firestoreConnect(props => {
    if (!props.auth.uid) return []

    return [
      {
        collection: `accounts`,
        where: [
          ["uid", "==", props.auth.uid],
          ["accountType", "==", `PENSION`],
        ],
        // orderBy: [`balance`, `desc`],
      },
    ]
  })
)(FinancesPensionsTable)
