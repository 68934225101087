import { Link } from 'react-router-dom'

const AccountTableActions = ({ id, editPath, onClickDelete = null }) => (
  <div>
    <Link to={editPath}>
      <span className="fe fe-edit"></span>
    </Link>
    &nbsp;&nbsp;
    {onClickDelete && (
      <span
        className="fe fe-trash-2"
        onClick={() => {
          if (
            window.confirm(
              "Are you sure you want to delete this account? This action can not be reversed."
            )
          ) {
            onClickDelete(id)
          }
        }}
      ></span>
    )}
  </div>
)

export default AccountTableActions
