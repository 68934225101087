import React, { useState } from 'react';
import { Card, Nav } from 'react-bootstrap';
import { Doughnut } from 'react-chartjs-2';
import { Chart, ChartLegend } from '../vendor';

export default function EmergencyFundWidget({ ...props }) {
  const [index, setIndex] = useState(0);

  const labels = ['Direct', 'Organic', 'Referral'];

  const datasets = [
    {
      data: [60, 25, 15,],
      backgroundColor: ['#00D97D', '#86D9B6', '#C1D9CF'],
    },
    {
      data: [15, 45, 20],
      backgroundColor: ['#2C7BE5', '#A6C5F7', '#D2DDEC'],
    },
  ];

  const options = {
    plugins: {
      tooltip: {
        callbacks: {
          afterLabel: function () {
            return '%';
          },
        },
      },
    },
  };

  return (
    <Card {...props} className="bg-dark">
      <Card.Header>
        <h4 className="card-header-title text-light">Your Net Worth</h4>
      </Card.Header>
      <Card.Body>
        <Chart layout="appended">
          <Doughnut data={{ labels: labels, datasets: [{ ...datasets[index] }] }} options={options} />
        </Chart>
        <ChartLegend data={{ labels: labels, datasets: datasets }} />
      </Card.Body>
    </Card>
  );
}
