import FeatherIcon from "feather-icons-react";
import React from "react";
import { Card, Col, ProgressBar, Row } from "react-bootstrap";
import { formatCurrency } from 'helpers';

export default function EcommerceConversionRate({ title, current = 0, target, ...props }) {

  const now = (current >= target) ? 100 : Math.floor((current / target) * 100);
  const percent = target / current * 100;
  const start = current > target ? target : current;

  const variant = now >= 70 ? "success" : now >= 40 ? "warning" : "danger";

  return (
    <Card {...props} className="bg-dark">
      <Card.Body>
        <Row className="align-items-center">
          <Col>
            <h6 className="text-uppercase text-muted mb-2">
              {title}
            </h6>
            <Row className="align-items-center g-0">
              <Col xs="auto">
                <span className="h2 me-2 mb-0 text-light">£{parseFloat((start / 1000).toFixed(1))}k</span>
              </Col>
              <Col>
                <ProgressBar
                  variant={variant}
                  className="progress-md"
                  now={now}
                  label={`${now}%`}
                />
              </Col>
              <Col xs="auto">
                <span className="h2 ms-2 mb-0 text-light">£{parseFloat(target)}</span>
              </Col>
            </Row>
          </Col>
          <Col xs="auto">
            <FeatherIcon icon="award" size="30" className="text-light" />
          </Col>
        </Row>
      </Card.Body>
    </Card>
  );
}
