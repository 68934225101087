import React from 'react'
import PropTypes from 'prop-types'
import { Dashboard } from 'components'
import { connect } from 'react-redux'
import { firestoreConnect } from 'react-redux-firebase'
import { compose } from 'redux'
import { deleteAccount } from 'store/actions/financesActions'
import { setViewedIntro } from 'store/actions/userActions'
import { Spinner } from 'react-bootstrap'
import {
  calculateBreakdown,
  calculateCashflow,
  DashboardHelpers,
  formatCurrency,
  formatAccountTitle,
  formatAccountUser
} from 'helpers'

const requireProviderLogo = (account) => {
  const obj = {
    'Abrdn': `icons/providers/abrdn.png`,
    'Armed Forces': `icons/providers/armed-forces.png`,
    'Britannia': `icons/providers/britannia.png`,
    'HSBC': `icons/providers/hsbc.png`,
    'IG': `icons/providers/ig.png`,
    'MBNA': `icons/providers/mbna.png`,
    'Mojo Mortgages': `icons/providers/mojo-mortgages.png`,
    'Nutmeg': `icons/providers/nutmeg.png`,
    'Smart Pension': `icons/providers/smart-pension.png`,
    'Sunlife': `icons/providers/sunlife.png`,
    'The Co-operative Bank': `icons/providers/the-co-operative-bank.png`,
    'The Exeter': `icons/providers/the-exeter.png`,
    'The Mortgage Works': `icons/providers/the-mortgage-works.png`,
    'The People_s Pension': `icons/providers/the-people_s-pension.png`,
    'True Potential': `icons/providers/true-potential.png`,
    'Ulster Bank': `icons/providers/ulster-bank.png`,
    'Wesleyan': `icons/providers/wesleyan.png`,
    'Yorkshire Building Society': `icons/providers/yorkshire-building-society.png`,
    'AA': `icons/providers/aa.png`,
    'Admiral': `icons/providers/admiral.png`,
    'AIG': `icons/providers/aig.png`,
    'AJ Bell Youinvest': `icons/providers/aj-bell-youinvest.png`,
    'Amazon': `icons/providers/amazon.png`,
    'American Express': `icons/providers/american-express.png`,
    'Asda Money': `icons/providers/asda-money.png`,
    'Aviva': `icons/providers/aviva.png`,
    'Barclaycard': `icons/providers/barclaycard.png`,
    'Barclays': `icons/providers/barclays.png`,
    'Beagle Street': `icons/providers/beagle-street.png`,
    'British Friendly Society': `icons/providers/british-friendly-society.png`,
    'BT': `icons/providers/bt.png`,
    'Canada Life': `icons/providers/canada-life.png`,
    'Capital One': `icons/providers/capital-one.png`,
    'Clydesdale Bank': `icons/providers/clydesdale-bank.png`,
    'Coventry Building Society': `icons/providers/coventry-building-society.png`,
    'Debenhams': `icons/providers/debenhams.png`,
    'Direct Line': `icons/providers/direct-line.png`,
    'Embark': `icons/providers/embark.png`,
    'Etorro': `icons/providers/etorro.png`,
    'Evestor': `icons/providers/evestor.png`,
    'Fidelity': `icons/providers/fidelity.png`,
    'First Direct': `icons/providers/first-direct.png`,
    'Guardian': `icons/providers/guardian.png`,
    'Habito': `icons/providers/habito.png`,
    'Halifax': `icons/providers/halifax.png`,
    'Hargreaves Landsdown': `icons/providers/hargreaves-landsdown.png`,
    'Hitachi Personal Finance': `icons/providers/hitachi-personal-finance.png`,
    'Interactive Investor': `icons/providers/interactive-investor.png`,
    'John Lewis': `icons/providers/john-lewis.png`,
    'Lloyds Bank': `icons/providers/lloyds-bank.png`,
    'Local Government': `icons/providers/local-government.png`,
    'London _ Country': `icons/providers/london-_-country.png`,
    'LV': `icons/providers/lv.png`,
    'M_S Bank': `icons/providers/m_s-bank.png`,
    'Metro Bank': `icons/providers/metro-bank.png`,
    'Moneybox': `icons/providers/moneybox.png`,
    'Monzo': `icons/providers/monzo.png`,
    'Nationwide': `icons/providers/nationwide.png`,
    'NatWest': `icons/providers/natwest.png`,
    'Nest': `icons/providers/nest.png`,
    'NHS': `icons/providers/nhs.png`,
    'NS_I': `icons/providers/ns_i.png`,
    'PensionBee': `icons/providers/pensionbee.png`,
    'Post Office': `icons/providers/post-office.png`,
    'RateSetter': `icons/providers/ratesetter.png`,
    'Royal Bank of Scotland': `icons/providers/royal-bank-of-scotland.png`,
    'Royal London': `icons/providers/royal-london.png`,
    'Santander': `icons/providers/santander.png`,
    'Scottish Widows': `icons/providers/scottish-widows.png`,
    'Skipton Building Society': `icons/providers/skipton-building-society.png`,
    'Starling': `icons/providers/starling.png`,
    'Tesco Bank': `icons/providers/tesco-bank.png`,
    'Trading 212': `icons/providers/trading-212.png`,
    'USS': `icons/providers/uss.png`,
    'Vanguard': `icons/providers/vanguard.png`,
    'Virgin Money': `icons/providers/virgin-money.png`,
    'Vitality': `icons/providers/vitality.png`,
    'Wealthify': `icons/providers/wealthify.png`,
    'Yorkshire Bank': `icons/providers/yorkshire-bank.png`,
    'Zopa': `icons/providers/zopa.png`,
    'Zurich': `icons/providers/zurich.png`,
    'Aegon': `icons/providers/aegon.png`,
    'Apple': `icons/providers/apple.png`,
    'Bank of Scotland': `icons/providers/bank-of-scotland.png`,
    'Sainsbury_s Bank': `icons/providers/sainsbury_s-bank.png`,
    'TSB': `icons/providers/tsb.png`,
  };

  return obj[account] !== undefined ? obj[account] : null;
}

const formatAccount = account => {
  switch (account.accountType) {
    case `DEBT`:
      return {
        type: account.accountType,
        id: account.id,
        title: formatAccountTitle(account),
        value: account.balance,
        imgSrc: requireProviderLogo(account.lender),
        icon: `check-square`,
        monthlyCost: account.monthlyCost,
        debtType: account.type,
        user: formatAccountUser(account)
      }
    case `PROPERTY`:
      return {
        type: account.accountType,
        id: account.id,
        title: formatAccountTitle(account),
        value: account.propertyValue,
        imgSrc: requireProviderLogo(account.lender),
        icon: `check-square`,
        income: account.monthlyIncome,
        user: formatAccountUser(account)
      }
    case `SAVING_INVESTMENT`:
      return {
        type: account.accountType,
        id: account.id,
        title: formatAccountTitle(account),
        value: account.balance,
        imgSrc: requireProviderLogo(account.provider),
        icon: `check-square`,
        monthlyCost: account.monthlySavings,
        user: formatAccountUser(account)
      }
    case `INSURANCE`:
      // provider name && value
      return {
        type: account.accountType,
        id: account.id,
        title: formatAccountTitle(account),
        value: account.policyValue,
        imgSrc: requireProviderLogo(account.provider),
        icon: `check-square`,
        user: formatAccountUser(account)
      }
    case `PENSION`:
      // Either full amount or per annum
      return {
        type: account.accountType,
        id: account.id,
        title: formatAccountTitle(account),
        value: account.value,
        imgSrc: requireProviderLogo(account.provider),
        // icon: `check-square`,
        monthlyCost: account.monthlyPersonalContributions,
        user: formatAccountUser(account)
      }
  }
}

const totalWealthReducer = (acc, account) => {
  switch (account.accountType) {
      case `DEBT`:
        return acc;
        return acc - parseFloat(account.balance); // add mortgage logic
      case `INSURANCE`:
        return acc; // add logic
      case `PENSION`:
        if (`Defined Contribution` === account.type) {
           return acc + parseFloat(account.value);
        }

        return acc; // add logic
      case `SAVING_INVESTMENT`:
        return acc + parseFloat(account.balance);
      case `PROPERTY`:
        return acc + parseFloat(account.propertyValue);
      default:
        return acc;
  }
}

const netWealthReducer = (acc, account) => {
  switch (account.accountType) {
      case `DEBT`:
        return acc - parseFloat(account.balance); // add mortgage logic
      case `INSURANCE`:
        return acc; // add logic
      case `PENSION`:
        if (`Defined Contribution` === account.type) {
           return acc + parseFloat(account.value);
        }

        return acc; // add logic
      case `SAVING_INVESTMENT`:
        return acc + parseFloat(account.balance);
      case `PROPERTY`:
        return acc + parseFloat(account.propertyValue);
      default:
        return acc;
  }
}

const debtTotalReducer = (acc, account) => {
  switch (account.type) {
      case `DEBT`:
        return acc - parseFloat(account.value);
      case `SAVING_INVESTMENT`:
      case `INSURANCE`:
      case `PENSION`:
      case `PROPERTY`:
      default:
        return acc;
  }
}

const investmentsTotalReducer = (acc, account) => {
  const investmentAccounts = [`Cash ISA`, `Cash LISA`, `Business Account`, `Crypto`];

  switch (account.accountType) {
      case `SAVING_INVESTMENT`:
        if (investmentAccounts.includes(account.type)) {
          return acc + parseFloat(account.balance);
        }

        return acc;
      case `DEBT`:
      case `INSURANCE`:
      case `PENSION`:
      case `PROPERTY`:
      default:
        return acc;
  }
}

const propertyTotalReducer = (acc, account) => {
  switch (account.type) {
      case `PROPERTY`:
        return acc + parseFloat(account.value);
      case `DEBT`:
      case `INSURANCE`:
      case `PENSION`:
      case `SAVING_INVESTMENT`:
        return acc;
  }
}

const savingsTotalReducer = (acc, account) => {
  const savingsAccounts = [`Current Account`, `Savings Account`];

  switch (account.accountType) {
      case `SAVING_INVESTMENT`:
        if (savingsAccounts.includes(account.type)) {
          return acc + parseFloat(account.balance);
        }

        return acc;
      case `DEBT`:
      case `INSURANCE`:
      case `PENSION`:
      case `PROPERTY`:
      default:
        return acc;
  }
}

const retirementTotalReducer = (acc, account) => {
  switch (account.type) {
      case `PENSION`:
        return acc + parseFloat(account.value)
      case `DEBT`:
      case `INSURANCE`:
      case `SAVING_INVESTMENT`:
      case `PROPERTY`:
        return acc;
  }
}

const DashboardPage = ({
  auth,
  profile,
  accountData,
  deleteAccount,
  setViewedIntro,
}) => {
  if (!accountData) {
    return <Spinner />
  }

  const accounts = accountData.reduce(
    (acc, value) => {
      acc[value.accountType].push(formatAccount(value))

      return acc
    },
    {
      DEBT: [],
      INSURANCE: [],
      PENSION: [],
      SAVING_INVESTMENT: [],
      PROPERTY: [],
    }
  )

  const breakdowns = accountData.map(DashboardHelpers.createAccountBreakdown)
  const totalWealthChartData = breakdowns.reduce(DashboardHelpers.aggregateBreakdown, calculateBreakdown({
    pv: 0.00,
    pmt: 0,
    interestRate: 0,
    periods: 60
  }))

  const cashflow = calculateCashflow(profile, accounts);

  const overview = auth.overview ?? {
    totalWealth: accountData.reduce(totalWealthReducer, 0.00),
    netWealth: accountData.reduce(netWealthReducer, 0.00),
    investments: accountData.reduce(investmentsTotalReducer, 0.00),
    savings: accountData.reduce(savingsTotalReducer, 0.00),
    investmentIncrease: 3.5,
    debt: accounts.DEBT.reduce(debtTotalReducer, 0.00),
    emergencyFund: 0.00,
    property: accounts.PROPERTY.reduce(propertyTotalReducer, 0.00),
    retirementFund: accounts.PENSION.reduce(retirementTotalReducer, 0.00)
  }

  console.log(JSON.stringify({
    profile: profile,
    accounts: accountData
  }))

  return (
    <Dashboard
      profile={profile}
      overview={overview}
      savingsAndInvestments={accounts.SAVING_INVESTMENT}
      debts={accounts.DEBT}
      pensions={accounts.PENSION}
      property={accounts.PROPERTY}
      insurances={accounts.INSURANCE}
      deleteAccount={deleteAccount}
      viewedIntro={profile.viewedIntro}
      setViewedIntro={setViewedIntro}
      accounts={accounts}
      accountData={accountData}
      chartData={{
        totalWealth: totalWealthChartData,
        cashflow: cashflow
      }}
    />
  )
}

DashboardPage.propTypes = {}

const mapStateToProps = state => {
  return {
    accountData: state.firestore.ordered.accounts
      ? state.firestore.ordered.accounts
      : [],
    auth: state.firebase.auth,
    profile: state.firebase.profile,
  }
}

const mapDispatchToProps = dispatch => {
  return {
    deleteAccount: id => dispatch(deleteAccount(id)),
    setViewedIntro: () => dispatch(setViewedIntro()),
  }
}

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  firestoreConnect(props => {
    if (!props.auth.uid) return []

    return [
      {
        collection: `accounts`,
        where: [["uid", "==", props.auth.uid]],
        orderBy: [`accountType`, `desc`],
      },
    ]
  })
)(DashboardPage)
