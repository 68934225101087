export default [
  { value: `Aegon` , label: `Aegon`},
  { value: `AJ Bell Youinvest` , label: `AJ Bell Youinvest`},
  { value: `Aviva` , label: `Aviva`},
  { value: `Embark` , label: `Embark`},
  { value: `Evestor` , label: `Evestor`},
  { value: `Fidelity` , label: `Fidelity`},
  { value: `Halifax` , label: `Halifax`},
  { value: `Hargreaves Landsdown` , label: `Hargreaves Landsdown`},
  { value: `Interactive Investor` , label: `Interactive Investor`},
  { value: `Legal & General` , label: `Legal & General`},
  { value: `Moneybox` , label: `Moneybox`},
  { value: `Trading 212` , label: `Trading 212`},
  { value: `Nutmeg` , label: `Nutmeg`},
  { value: `Royal London` , label: `Royal London`},
  { value: `Scottish Widows` , label: `Scottish Widows`},
  { value: `Standard Life` , label: `Standard Life`},
  { value: `True Potential` , label: `True Potential`},
  { value: `Vanguard` , label: `Vanguard`},
  { value: `HSBC` , label: `HSBC`},
  { value: `IG` , label: `IG`},
  { value: `Barclays` , label: `Barclays`},
  { value: `iWeb` , label: `iWeb`},
  { value: `Wealthify` , label: `Wealthify`},
]
