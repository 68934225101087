import PropTypes from 'prop-types'
import { Col, Row } from 'react-bootstrap'
import { TextField, CurrencyField, PhoneNumberField, SelectField, RadioToggleField, DayPickerField } from 'components/form'
import { useForm } from 'helpers'
import { employmentStatusOptions, relationshipStatusOptions } from 'config/schemas'

const BasicsForm = ({ state, errors, success, handleChange }) => {
  return (
    <>
      {success && <div className="alert alert-success">{success}</div>}

      {/* Basics form */}

      <TextField
        label="What is your full name?"
        inputClass="form-control"
        name="fullName"
        id="fullName"
        value={state.fullName}
        onChange={handleChange}
        error={errors.fullName}
      />
      <PhoneNumberField
        label="What is your mobile number"
        inputClass="form-control"
        name="mobileNumber"
        id="mobileNumber"
        value={state.mobileNumber}
        onChange={handleChange}
        error={errors.mobileNumber}
      />
      <TextField
        name="occupation"
        label="What is your occupation?"
        labelClass="mb-1"
        inputClass="form-control"
        value={state.occupation}
        onChange={handleChange}
        error={errors.occupation}
      />
      <SelectField
        id="employmentStatus"
        name="employmentStatus"
        label="What is your employment status?"
        mutedText=""
        onChange={handleChange}
        value={state.employmentStatus}
        options={employmentStatusOptions}
        error={errors.employmentStatus}
      />
      <DayPickerField
        id="dateOfBirth"
        label="What is your date of birth?"
        labelClass="mb-1"
        inputClass="form-control"
        value={new Date()}
        onChange={handleChange}
        error={errors.dateOfBirth}
      />

      {/* Income form */}

      <CurrencyField
        label="What is your after tax monthly income?"
        mutedText="If you have multiple income sources or variable income, please try to make an estimated monthly average."
        inputClass="form-control"
        name="totalIncome"
        id="totalIncome"
        placeholder="£"
        value={state?.totalIncome}
        onChange={handleChange}
        error={errors?.totalIncome}
      />

      <CurrencyField
        label="What is your annual salary before tax?"
        inputClass="form-control"
        name="annualSalary"
        id="annualSalary"
        placeholder="£"
        mutedText="Please try to estimate your annual pay before tax but ideally after big deductions like car, healthcare, pension etc."
        value={state?.annualSalary}
        onChange={handleChange}
        error={errors?.annualSalary}
      />

      <CurrencyField
        label="What is your monthly rent?"
        mutedText="If you own your home or make a mortgage payment, don’t worry, that is on the main dashboard."
        inputClass="form-control"
        name="totalPaidRent"
        id="totalPaidRent"
        placeholder="£"
        value={state?.totalPaidRent}
        onChange={handleChange}
        error={errors?.totalPaidRent}
      />

      <CurrencyField
        label="Approximately how much are your monthly bills?"
        mutedText="Please just include ‘the basics’ e.g. electricity, water etc, we ask you about things like mortgages, credit cards and regular savings later on."
        inputClass="form-control"
        name="totalFixedBills"
        id="totalFixedBills"
        value={state?.totalFixedBills}
        onChange={handleChange}
        error={errors?.totalFixedBills}
      />
    </>
  )
}

BasicsForm.propTypes = {}

export default BasicsForm
