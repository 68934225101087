import React, { ChangeEventHandler, useRef, useState } from 'react';
import { Button, InputGroup, FormControl } from 'react-bootstrap'

import { format, isValid, parse } from 'date-fns';
import FocusTrap from 'focus-trap-react';
import { DayPicker } from 'react-day-picker';
import { usePopper } from 'react-popper';

const dateFormat = 'dd/MM/y';

const DayPickerInput = ({ value, inputProps, onChange }) => {
  const [selected, setSelected] = useState(value || null);
  const [inputValue, setInputValue] = useState(value ? format(value, dateFormat) : null);
  const [isPopperOpen, setIsPopperOpen] = useState(false);

  const popperRef = useRef(null);
  const buttonRef = useRef(null);
  const [popperElement, setPopperElement] = useState(
    null
  );

  const popper = usePopper(popperRef.current, popperElement, {
    placement: 'bottom-start'
  });

  const closePopper = () => {
    setIsPopperOpen(false);
    buttonRef?.current?.focus();
  };

  const handleInputChange: ChangeEventHandler<HTMLInputElement> = (e) => {
    setInputValue(e.currentTarget.value);
    const date = parse(e.currentTarget.value, dateFormat, new Date());
    if (isValid(date)) {
      setSelected(date);
      onChange(date) // Do this via useEffect
    } else {
      setSelected(undefined);
    }
  };

  const handleButtonClick = () => {
    setIsPopperOpen(true);
  };

  const handleDaySelect = (date: Date) => {
    setSelected(date);
    if (date) {
      setInputValue(format(date, dateFormat));
      onChange(date) // Do this via useEffect
      closePopper();
    } else {
      setInputValue('');
    }
  };

  return (
    <div>
      <div ref={popperRef}>
        <InputGroup className="mb-3">
          <FormControl
            placeholder={format(new Date(), dateFormat)}
            aria-label="Pick a date"
            aria-describedby="basic-addon2"
            value={inputValue}
            onChange={handleInputChange}
            {...inputProps}
          />
          <Button
            variant="outline-secondary"
            id="button-addon2"
            onClick={handleButtonClick}
          >
            📅
          </Button>
        </InputGroup>
      </div>
      {isPopperOpen && (
        <FocusTrap
          active
          focusTrapOptions={{
            initialFocus: false,
            allowOutsideClick: true,
            clickOutsideDeactivates: true,
            onDeactivate: closePopper
          }}
        >
          <div
            tabIndex={-1}
            style={{
              backgroundColor: `#fff`,
              zIndex: 1,
              ...popper.styles.popper
            }}
            className="dialog-sheet"
            {...popper.attributes.popper}
            ref={setPopperElement}
            role="dialog"
          >
            <DayPicker
              DayPicker fromYear={1922} toYear={(new Date()).getFullYear()} captionLayout="dropdown"
              initialFocus={isPopperOpen}
              mode="single"
              defaultMonth={selected}
              selected={selected}
              onSelect={handleDaySelect}
            />
          </div>
        </FocusTrap>
      )}
    </div>
  )

}

export default DayPickerInput
