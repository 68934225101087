import { AccountWrapper } from 'containers'
import { pension as schema } from 'config/schemas'
import { PensionForm } from 'components'
import { useParams } from 'react-router-dom'

const FinancesPensionsForm = ({
  profile,
  account,
  errors,
  success,
  handleChange,
  handleSubmit
}) => {
  return <AccountWrapper accountType="PENSION" schema={schema} form={PensionForm} location={useParams()} />;
}

export default FinancesPensionsForm
