import calculateBreakdown from './calculateBreakdown'
import formatAccountTitle from './formatAccountTitle'

const periods = 60;
const investmentAccounts = [`Cash ISA`, `Cash LISA`, `Business Account`, `Crypto`];

export const createAccountBreakdown = (account) => {
  // const periods = 60

  switch (account.accountType) {
      case `DEBT`:
      case `INSURANCE`:
        return createEmptyBreadown(account)
      case `PENSION`:
        // return createEmptyBreadown(account)
        return createPensionBreakdown(account)
      case `PROPERTY`:
        // return createEmptyBreadown(account)
        return createPropertyBreakdown(account)
      case `SAVING_INVESTMENT`:
        // return createEmptyBreadown(account)
        return createSavingsAndInvestmentBreakdown(account)
      default:
        console.error('woah hit default', account)

  }
}

const createSavingsAndInvestmentBreakdown = (account) => {
  const interestRate = investmentAccounts.includes(account.type) ? 0.07 : 0.005;

  // Need to consider crypto a bit differently

  const breakdown = calculateBreakdown({
    pv: parseFloat(account.balance),
    pmt: account.monthlySavings,
    interestRate: interestRate,
    periods
  })

  return { accountType: 'SAVING_INVESTMENT', title: formatAccountTitle(account), breakdown }
}

const createPropertyBreakdown = (account) => {
  const breakdown = calculateBreakdown({
    pv: parseFloat(account.propertyValue),
    pmt: 0,
    interestRate: 3.5 / 100,
    periods
  })

  return { accountType: 'PROPERTY', title: formatAccountTitle(account), breakdown }
}

const createPensionBreakdown = (account) => {
  const breakdown = calculateBreakdown({
    pv: parseFloat(account.value),
    pmt: parseFloat(account.monthlyWorkContributions) + parseFloat(account.monthlyPersonalContributions),
    interestRate: 5 / 100,
    periods
  })

  return { accountType: 'PROPERTY', title: formatAccountTitle(account), breakdown }
}

const createEmptyBreadown = (account) => {
  const breakdown = calculateBreakdown({
    pv: 0.00,
    pmt: 0,
    interestRate: 0,
    periods
  })

  return { accountType: account.accountType, title: formatAccountTitle(account), breakdown }
}


export const aggregateBreakdown = (acc, { breakdown }) => {
  for (const key in breakdown) {
    acc[key].deposit += breakdown[key].deposit;
    acc[key].startingPrinciple += breakdown[key].startingPrinciple;
    acc[key].endingPrinciple += breakdown[key].endingPrinciple;
  }

  return acc
}
