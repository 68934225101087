export default [
  { value: `Zopa`,label: `Zopa` },
  { value: `Sainsbury's Bank`,label: `Sainsbury's Bank` },
  { value: `Tesco Bank`,label: `Tesco Bank` },
  { value: `Santander`,label: `Santander` },
  { value: `M&S Bank`,label: `M&S Bank` },
  { value: `HSBC`,label: `HSBC` },
  { value: `Barclays`,label: `Barclays` },
  { value: `Natwest`,label: `Natwest` },
  { value: `Admiral`,label: `Admiral` },
  { value: `Clydsedale Bank`,label: `Clydsedale Bank` },
  { value: `First Direct`,label: `First Direct` },
  { value: `Halifax`,label: `Halifax` },
  { value: `Hitachi Personal Finance`,label: `Hitachi Personal Finance` },
  { value: `Lloyds Bank`,label: `Lloyds Bank` },
  { value: `MBNA`,label: `MBNA` },
  { value: `Metro`,label: `Metro` },
  { value: `Monzo`,label: `Monzo` },
  { value: `Nationwide`,label: `Nationwide` },
  { value: `Post Office`,label: `Post Office` },
  { value: `Royal Bank of Scotland`,label: `Royal Bank of Scotland` },
  { value: `Starling`,label: `Starling` },
  { value: `TSB`,label: `TSB` },
  { value: `Virgin Money`,label: `Virgin Money` },
  { value: `Yorkshire Bank`,label: `Yorkshire Bank` },
  { value: `RateSetter`,label: `RateSetter` },
]
