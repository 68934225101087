import React from 'react'
import PropTypes from 'prop-types'
import { Col, Container, Row } from 'react-bootstrap'
import { SignUpForm } from 'containers'

const SignUpCover = ({}) => (
  <div className="d-flex align-items-center min-vh-100 bg-auth border-top border-top-2 border-primary">
    <Container fluid>
      <Row className="align-items-center justify-content-center">
        <Col xs={12} md={5} lg={6} xl={4} className="px-lg-6 my-5">
          <SignUpForm />
        </Col>
        <Col xs={12} md={7} lg={6} xl={8} className="d-none d-lg-block">
          <div
            className="bg-cover vh-100 mt-n1 me-n3"
            style={{
              backgroundImage: 'url(/img/covers/auth-side-cover.jpg)',
            }}
          />
        </Col>
      </Row>
    </Container>
  </div>
)

const SignUpIllustration = ({}) => (
  <div className="d-flex align-items-center min-vh-100 bg-auth border-top border-top-2 border-primary">
    <Container>
      <Row className="align-items-center">
        <Col xs={12} md={6} className="offset-xl-2 offset-md-1 order-md-2 mb-5 mb-md-0">
          <div className="text-center">
            <img className="img-fluid" src="/happiness.svg" alt="..." />
          </div>
        </Col>
        <Col xs={12} md={5} xl={4} className="order-md-1 my-5">
          <SignUpForm />
        </Col>
      </Row>
    </Container>
  </div>
)

const SignUp = ({}) => (
  <div className="d-flex align-items-center min-vh-100 bg-auth border-top border-top-2 border-primary">
    <Container>
      <Row className="justify-content-center">
        <Col xs={12} md={5} xl={4} className="my-5">
          <SignUpForm />
        </Col>
      </Row>
    </Container>
  </div>
)

SignUp.propTypes = {}

export default SignUpIllustration
