import React from "react"

const NoScript = () => (
  <noscript>
    <div
      style={{
        position: "absolute",
        left: "50%",
        top: "50%",
        transform: "translate(-50%, -50%)",
      }}
    >
      <div className="alert alert-dark" role="alert">
        Sorry, you require Javascript to use this website.
      </div>
    </div>
  </noscript>
)

export default NoScript
