import React, { useState } from 'react'
import { Button, Card, Col, Container, Form, Row, Table } from 'react-bootstrap';
import { Header } from 'components'
import { connect } from 'react-redux'
import { firestoreConnect } from 'react-redux-firebase'
import { compose } from 'redux'
import { calculateBreakdown, DashboardHelpers, formatAccountTitle, formatCurrency } from 'helpers'
import { CurrencyField, PercentageField, NumberField, RadioToggleField } from 'components/form'
import { FormWrapper } from 'containers'
import Joi from 'joi-browser'
import { Line } from 'react-chartjs-2';
import { Chart } from 'components/vendor';


const GraphBreakdowns = ({ accounts }) => {
  return (
    <div className="main-content">
      <Container fluid>
        <PageHeader
          title="Graph Breakdowns"
          pretitle="development"
        />
        {!accounts && (
          <NoAccounts />
        )}
        {accounts && (
          <Breakdowns accounts={accounts} />
        )}
      </Container>
    </div>
  );
}


const Breakdowns = ({ accounts }) => {

  const breakdowns = accounts.map(DashboardHelpers.createAccountBreakdown)
  const aggregated = breakdowns.reduce(DashboardHelpers.aggregateBreakdown, calculateBreakdown({
    pv: 0.00,
    pmt: 0,
    interestRate: 0,
    periods: 60
  }))

  const [results, setResults] = useState([]);

  const calculateResults = ({ state: { pmt, pv, interestRate }}) => {
    const breakdown = calculateBreakdown({
      pmt,
      pv,
      interestRate: (interestRate / 100),
      periods: 60
    })

    setResults(breakdown)
  }

  return (
    <>
      <Container>
        <Row className="justify-content-center">
          <Card>
            <Card.Body>
              <FormWrapper
                initialState={{}}
                schema={{
                  defaultValues: {
                    accounts: [],
                  },
                  validator: Joi.object().keys()
                }}
                component={CalculateBreakdownsForm}
                onSubmit={calculateResults}
              />
            </Card.Body>
          </Card>

          <BreakdownGraph
            title={`Aggregated Breakdowns`} breakdown={aggregated}
          />

          <BreakdownTable
            title={`Aggregated Breakdowns`} breakdown={aggregated}
          />

          {breakdowns && breakdowns.map(breakdown => (
            <BreakdownTable title={breakdown.title} breakdown={breakdown.breakdown} />
          ))}
        </Row>
      </Container>
    </>
  )
}

const BreakdownGraph = ({ breakdown }) => {
  const [index, setIndex] = useState(0);

  // https://stackoverflow.com/questions/22064577/limit-labels-number-on-chart-js-line-chart
  // const labels = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
  // const labels = ['Now', '', 'Year 1', '', 'Year 2', '', 'Year 3', '', 'Year 4', '', 'Year 5'];


  const labels = []
  for (var i = 1; i < 61; i++) {
    labels.push(i)
    // labels.push(i % 12 === 0 ? 'Year' : 'Year')
  }

  // console.log(labels)

  const datasets = [
    {
      data: breakdown.map(breakdown => breakdown.startingPrinciple),
      yAxisID: 'yAxisOne',
    },
    {
      data: [50, 75, 35, 25, 55, 87, 67, 53, 25, 80, 87, 45],
      yAxisID: 'yAxisOne',
    },
  ];

  const options = {
    scales: {
      yAxisOne: {
        // type: 'logarithmic',
        // display: 'auto',
        // grid: {
        //   color: '#283E59',
        // },
        // ticks: {
        //   callback: function (value) {
        //     return value + 'k';
        //   },
        // },
      },
      yAxisTwo: {
        display: 'auto',
        grid: {
          color: '#283E59',
        },
        ticks: {
          callback: function (value) {
            return value + '%';
          },
        },
      },
    },
  };

  return (
    <Card>
      <Card.Body>
        <Chart>
          <Line data={{ labels: labels, datasets: [{ ...datasets[index] }] }} options={options} />
        </Chart>
      </Card.Body>
    </Card>
  )
}

const BreakdownTable = ({ title, breakdown }) => {
  return (
    <Card>
      <Card.Body>
        <>
          <p>{title}</p>
          <Table size="sm" className="mb-0" hover responsive>
            <thead>
              <tr>
                <th scope="col">Period</th>
                <th scope="col">New deposit at the start of the period</th>
                <th scope="col">Principal at the start of the period</th>
                <th scope="col">Amount at the end of the period</th>
              </tr>
            </thead>
            <tbody>
              {breakdown.map(result => (
                <tr>
                  <th scope="row">{result.period}</th>
                  <td>{formatCurrency(result.deposit)}</td>
                  <td>{formatCurrency(result.startingPrinciple)}</td>
                  <td>{formatCurrency(result.endingPrinciple)}</td>
                </tr>
              ))}
            </tbody>
          </Table>
        </>
      </Card.Body>
    </Card>
  )
}

const CalculateBreakdownsForm = ({ state, errors, handleChange, handleSubmit }) => {
  const options = [
    { name: `Test 1`, value: 1 },
    { name: `Test 2`, value: 2 },
  ]

  return (
    <Form onSubmit={handleSubmit}>
      <Row className="align-items-center">
        <Col sm={3} className="my-1">
          <CurrencyField
            label="Present Value"
            name="pv"
            value={state.pv}
            error={errors.pv}
            onChange={handleChange}
            mutedText=""
          />
        </Col>
        <Col>
          <Form.Control as="select" multiple value={options} onChange={null}>
            {options.map(option => (
              <option key={option.name} value={option.value}>
                {option.name}
              </option>
            ))}
          </Form.Control>
        </Col>
        <Col xs="auto" className="my-1">
          <Button type="submit" className="lift w-100">Calculate</Button>
        </Col>
      </Row>
    </Form>
  )
}

const NoAccounts = () => (
  <>
    No data
  </>
)

const PageHeader = ({ title, pretitle }) => (
  <Row className="justify-content-center">
    <Col xs={12} lg={10} xl={8} className="py-6">
      <Header>
        <Header.Body>
          <Row className="align-items-center">
            <Col>
              <Header.Pretitle>{pretitle}</Header.Pretitle>
              <Header.Title>{title}</Header.Title>
            </Col>
            <Col xs="auto">
              {/*<Button>Reset all</Button>*/}
            </Col>
          </Row>
        </Header.Body>
      </Header>
    </Col>
  </Row>
)


GraphBreakdowns.propTypes = {}

const mapStateToProps = state => {
  return {
    accounts: state.firestore.ordered.accounts
      ? state.firestore.ordered.accounts
      : [],
    auth: state.firebase.auth,
  }
}

export default compose(
  connect(mapStateToProps, null),
  firestoreConnect(props => {
    if (!props.auth.uid) return []

    return [
      {
        collection: `accounts`,
        where: [["uid", "==", props.auth.uid]],
        orderBy: [`accountType`, `desc`],
      },
    ]
  })
)(GraphBreakdowns)

