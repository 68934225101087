import { Col, Container, Row } from 'react-bootstrap';
import PropTypes from 'prop-types'
import { Outlet } from 'react-router-dom'

const GettingStarted = ({  }) => {
  return (
    <div className="main-content">
      <Container fluid="lg">
        <Row className="justify-content-center">
          <Col xs={12} lg={10} xl={8} className="py-6">
            <Outlet />
          </Col>
        </Row>
      </Container>
    </div>
  );
}

export default GettingStarted
