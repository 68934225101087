export const toFloat = (number) => parseFloat(number) || 0.00;

export function calculateTotals({ personOne, personTwo, joint }) {
  return {
    basicOutgoings: personOne.basicOutgoings + personTwo.basicOutgoings + joint.basicOutgoings,
    debtPayments: personOne.debtPayments + personTwo.debtPayments + joint.debtPayments,
    mortgages: personOne.mortgages + personTwo.mortgages + joint.mortgages,
    debts: personOne.debts + personTwo.debts + joint.debts,
    savings: personOne.savings + personTwo.savings + joint.savings,
    investments: personOne.investments + personTwo.investments + joint.investments
  }
}

export function calculateNetWorth(personal, joint, percentageShared = 50) {
  return personal.debts
    + personal.savings
    + personal.investments

    + joint.debts / (100 / percentageShared)
    + joint.savings / (100 / percentageShared)
    + joint.investments / (100 / percentageShared)
}

export function calculateOutgoings(personal, joint, percentageShared = 50) {
  return personal.basicOutgoings
    + personal.debtPayments
    + joint.basicOutgoings / (100 / percentageShared)
    + joint.debtPayments / (100 / percentageShared)
}

export function calculateHowLongNetWorthWouldLast(netWorth, outgoings) {
  return netWorth / outgoings;
}

export function calculateRequiredEmergencyFund(outgoings, monthsNeeded = 3) {
  return monthsNeeded * outgoings
}

export function calculateDistanceFromEmegencyFund(fund, netWorth) {
  return fund - netWorth;
}

export function calculateMinLifeInsurancePosition(personal, joint, percentageShared = 50) {
  return - (
      personal.debts
      + personal.mortgages
      + personal.investments
      + personal.savings

      + joint.debts / (100 / percentageShared)
      + joint.mortgages / (100 / percentageShared)
      + joint.investments / (100 / percentageShared)
      + joint.savings / (100 / percentageShared)
  )
}

export function calculateMaxLifeInsurancePosition(personal, joint) {
  return  - (
      personal.debts
      + personal.mortgages
      + personal.investments
      + personal.savings

      + joint.debts
      + joint.mortgages
      + joint.investments
      + joint.savings
  )
}

export function calculateCriticalIllnessCoverPosition(outgoings, netWorth, months = 12) {
  return months * outgoings - netWorth;
}


export function aggregateUserData(profiles, accounts) {
  const overview = {
    basicOutgoings: 0,
    debtPayments: 0,
    mortgages: 0,
    debts: 0,
    savings: 0,
    investments: 0,
    lifeInsuranceCover: 0,
    criticalIllnessCover: 0,
    incomeProtection: 0,
  }
  const personOne = { ...overview };
  const personTwo = { ...overview };
  const joint = { ...overview };

  // console.log(profiles)

  // Handle profiles
  personOne.basicOutgoings += toFloat(profiles.totalPaidRent)
  personOne.basicOutgoings += toFloat(profiles.totalFixedBills)
  personTwo.basicOutgoings += toFloat(profiles.totalPaidRentPartner)
  personTwo.basicOutgoings += toFloat(profiles.totalFixedBillsPartner)

  // Handle accounts
  accounts.forEach(account => {
    const holder = account.accountType === `INSURANCE` ? account.policyHolder : account.accountHolder;

    const p = (holder === 'Me')
      ? personOne
      : (holder === 'Partner' ? personTwo : joint)
    ;

    switch (account.accountType) {
      case `DEBT`:
        if (account.type === 'Mortgage') {
          p.mortgages =+ toFloat(account.balance)
          p.debtPayments += toFloat(account.monthlyCost)
        } else {
          p.debts =+ toFloat(account.balance)
          p.debtPayments += toFloat(account.monthlyCost)
        }
        break;
      case `INSURANCE`:
        if (account.insuranceType === 'Life Cover') {
          p.lifeInsuranceCover = toFloat(account.policyValue)
        } else if (account.insuranceType === 'Income Protection') {
          p.incomeProtection = toFloat(account.policyValue)
        } else if (account.insuranceType === 'Critical Illness') {

        }
        break;
      case `PENSION`:
        break;
      case `PROPERTY`:
        break;
      case `SAVING_INVESTMENT`:
        // Should lift this is account a savings vs investment account logic
        // up and out of here
        if ([`Current Account`, `Savings Account`].includes(account.type)) {
          // console.log(`savings`, account);
          p.savings += toFloat(account.monthlySavings);
        } else {
          p.investments += toFloat(account.monthlySavings)
        }
        break;
      default:
        // console.log(account)
    }
  })

  // console.log(profiles);
  // console.log({ personOne, personTwo, joint })

  return { personOne, personTwo, joint }
}
