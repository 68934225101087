import React from "react";
import { Badge, Button, Card } from "react-bootstrap";

export default function InsuranceAnalysisWidget({ ...props }) {
  return (
    <Card className="insurance-widget" {...props}>
      <Card className="insurance-image"></Card>

      <Card.Body className="insurance-content d-flex flex-column text-center justify-content-center">
        <Badge
          bg="success"
          className="position-absolute"
          style={{ top: 20, right: 20 }}
        >
          New!
        </Badge>
        <h1 className="text-light">Insurance Analysis</h1>
        <p className="text-light">
          An overview of your current insurance which gives you a suggested
          range of cover - there's a methodolgy
        </p>
        <div>
          <Button
            variant="outline-light"
            className="stretched-link"
            href="/insurance-plan"
          >
            See analysis
          </Button>
        </div>
      </Card.Body>
    </Card>
  );
}
