export const profileLinks = [
  { label: `Income & Outgoings`, to: `/profile/income` },
  { label: `Personal Information`, to: `/profile/basics` },
  // { label: `Bills & Debts`, to: `/basics/bills-and-debts` },
  // { label: `Insurances`, to: `/basics/insurances` },
]

export const financeLinks = [
  { label: `Savings & Investments`, to: `/finances/savings-and-investments` },
  { label: `Pensions`, to: `/finances/pensions` },
  // { label: `Investments`, to: `/finances/investments` },
  { label: `Property`, to: `/finances/properties` },
  { label: `Insurance`, to: `/finances/insurance` },
  { label: `Debts`, to: `/finances/debts` },
]
