import React from "react";
import FeatherIcon from "feather-icons-react";

import {
  Card,
  Col,
  Row,
  Badge,
} from "react-bootstrap";

import { Link } from "react-router-dom";

export default function PeopleWidget({
  title,
  profile,
  gettingStarted = false,
  ...props
}) {

  const baseDir = gettingStarted ? `getting-started` : `profile`;

  return (
        <Row>
          {profile && (
            <Col md={6}>
              <Link to={`/${baseDir}/about-you`}>
                <Card className="d-flex justify-content-center align-items-center user-card">
                  <FeatherIcon icon="user" size="3em" className="add-icon mb-3" />
                  <div className="add-text">{profile.fullName}</div>
                  <Badge variant="success" className="user-card__connection">
                    Me
                  </Badge>
                  <a href="#" className="user-card__edit">
                    <FeatherIcon
                      icon="edit"
                      size="1.5em"
                      className="add-icon mb-3"
                    />
                  </a>
                </Card>
              </Link>
            </Col>
          )}
          {(profile && profile.includesPartnerInfo) && (
            <>
              <Col md={6}>
                <Link to={`/${baseDir}/partner`}>
                  <Card className="d-flex justify-content-center align-items-center user-card">
                    <FeatherIcon
                      icon="user"
                      size="3em"
                      className="add-icon mb-3"
                    />
                    <div className="add-text">{profile.fullNamePartner}</div>
                    <Badge variant="success" className="user-card__connection">
                      {profile.relationshipStatus}
                    </Badge>
                    <a href="#" className="user-card__edit">
                      <FeatherIcon
                        icon="edit"
                        size="1.5em"
                        className="add-icon mb-3"
                      />
                    </a>
                  </Card>
                </Link>
              </Col>
            </>
          )}
          {(!profile || !profile.includesPartnerInfo )&& (
            <Col md={6}>
              <Link to={`/${baseDir}/partner`}>
                <Card className="d-flex justify-content-center align-items-center add-card">
                  <FeatherIcon icon="plus" size="3em" className="add-icon" />
                  <div className="add-text">Add your partner</div>
                </Card>
              </Link>
            </Col>
          )}
        </Row>
  );
}
