import PropTypes from 'prop-types'
import { Col, Row } from 'react-bootstrap'
import { TextField, CurrencyField, PhoneNumberField, SelectField, RadioToggleField, DayPickerField } from 'components/form'
import { useForm } from 'helpers'
import { employmentStatusOptions, relationshipStatusOptions } from 'config/schemas'

const AboutYourPartnerForm = ({ state, errors, success, handleChange }) => {
  return (
    <>
      {success && <div className="alert alert-success">{success}</div>}
        <TextField
          label="What is your partners full name?"
          inputClass="form-control"
          name="fullNamePartner"
          id="fullNamePartner"
          value={state.fullNamePartner}
          onChange={handleChange}
          error={errors.fullNamePartner}
        />
        <SelectField
          id="relationshipStatus"
          name="relationshipStatus"
          label="What is your relationship?"
          mutedText=""
          onChange={handleChange}
          value={state.relationshipStatus}
          options={relationshipStatusOptions}
          error={errors.relationshipStatus}
        />
        <SelectField
          id="employmentStatusPartner"
          name="employmentStatusPartner"
          label="What is your partners employment status?"
          mutedText=""
          onChange={handleChange}
          value={state.employmentStatusPartner}
          options={employmentStatusOptions}
          error={errors.employmentStatusPartner}
        />
        <TextField
          id="occupationPartner"
          name="occupationPartner"
          label="What is your partners occupation?"
          labelClass="mb-1"
          inputClass="form-control"
          value={state.occupationPartner}
          onChange={handleChange}
          error={errors.occupationPartner}
        />
        <DayPickerField
          id="dateOfBirthPartner"
          label="What is your partners date of birth?"
          labelClass="mb-1"
          inputClass="form-control"
          value={state.dateOfBirthPartner}
          onChange={handleChange}
          error={errors.dateOfBirthPartner}
        />

        <CurrencyField
          label="What is your partners after-tax monthly income?"
          mutedText="If your partner has multiple income sources or variable income, please try to make an estimated monthly average."
          inputClass="form-control"
          name="totalIncomePartner"
          id="totalIncomePartner"
          placeholder="£"
          value={state.totalIncomePartner}
          onChange={handleChange}
          error={errors.totalIncomePartner}
        />

        <CurrencyField
          label="What is your partners annual salary before tax?"
          inputClass="form-control"
          name="annualSalaryPartner"
          id="annualSalaryPartner"
          placeholder="£"
          value={state.annualSalaryPartner}
          onChange={handleChange}
          error={errors.annualSalaryPartner}
        />
    </>
  )
}

AboutYourPartnerForm.propTypes = {}

export default AboutYourPartnerForm
