import React from 'react'
import CurrencyInput from 'react-currency-input'
import { Form, InputGroup } from 'react-bootstrap'

let componentDidMount_super = CurrencyInput.prototype.componentDidMount
CurrencyInput.prototype.componentDidMount = function () {
  if (!this.props.autoFocus) {
    let setSelectionRange_super = this.theInput.setSelectionRange
    this.theInput.setSelectionRange = () => {}
    componentDidMount_super.call(this, ...arguments)
    this.theInput.setSelectionRange = setSelectionRange_super
  } else {
    componentDidMount_super.call(this, ...arguments)
  }
}
let componentDidUpdate_super = CurrencyInput.prototype.componentDidUpdate
CurrencyInput.prototype.componentDidUpdate = function () {
  if (!this.props.autoFocus) {
    let setSelectionRange_super = this.theInput.setSelectionRange
    this.theInput.setSelectionRange = () => {}
    componentDidUpdate_super.call(this, ...arguments)
    this.theInput.setSelectionRange = setSelectionRange_super
  } else {
    componentDidMount_super.call(this, ...arguments)
  }
}

const CurrencyField = ({
  id,
  name,
  label,
  value,
  error,
  onChange = null,
  onBlur = null,
  mutedText = null,
  labelClass = null,
  inputClass = null,
  readOnly = false,
  placeholder = ``,
  isNumber = false,
  symbol = `£`,
}) => (
  <Form.Group className="mb-4">
    <Form.Label>{label}</Form.Label>
    {mutedText && <small className="form-text text-muted">{mutedText}</small>}
    <InputGroup>
      <InputGroup.Text id="basic-addon1">{symbol}</InputGroup.Text>
      <CurrencyInput
        precision={0}
        allowEmpty={true}
        inputMode="decimal"
        className={`form-control ${inputClass} ${error ? "is-invalid" : ""}`}
        id={id}
        name={name}
        value={value}
        onChangeEvent={event => {
          event.preventDefault()
        }}
        onChange={value => {
          onChange({
            target: {
              id: id,
              name: name,
              value: parseFloat(value.replace(/,/g, "")) || 0.00,
            },
          })
        }}
        // onBlur={onBlur}
        readOnly={readOnly}
      />
      {error && <div className="invalid-feedback">{error}</div>}
    </InputGroup>
  </Form.Group>
)

export default CurrencyField
