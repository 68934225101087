import { Modal } from 'containers'
import { Table } from 'react-bootstrap'

const ModalTotalWealth = ({ buttonText }) => {
  return (
    <Modal buttonText={buttonText} heading="How did we calculate this?">
      <p>When forecasting your future wealth, as you’d expect there is a level of uncertainty when making any forecast but that's where our expertise comes in. We’ve used relevant, average data to make the forecasts as accurate as can be reasonably expected.</p>
      <p><i>Our default assumptions:</i></p>
      <Table size="sm" responsive bordered>
        <thead>
          <tr>
            <th scope="col">Asset type</th>
            <th scope="col">Growth Rate</th>
            <th scope="col">Why?</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>Investments</td>
            <td>7%</td>
            <td>This is the long-term average rate of growth a ‘medium’ risk investment portfolio would have experienced over the last 20 years e.g stocks ISA etc.</td>
          </tr>
          <tr>
            <td>Property</td>
            <td>3.5%</td>
            <td>This is the long-term average return of the UK property market across all UK regions averaged over the last 20 years</td>
          </tr>
          <tr>
            <td>Savings</td>
            <td>0.5%</td>
            <td>This is the long-term average rate of UK savings over the last 20 years.</td>
          </tr>
          <tr>
            <td>Retirement</td>
            <td>5%</td>
            <td>This is the long-term average rate of growth a ‘medium’ risk pension plan would have experienced over the last 20 years.</td>
          </tr>
        </tbody>
      </Table>
      <p>
        <b>Coming soon</b>: You will be able to edit the growth rates yourself if you’ve got a different view on things. Maybe you’ve got a few investment properties in an area that you think is going to skyrocket in value? Or maybe you’ve got some shares or crypto with a high potential for return!
      </p>
    </Modal>
  )
}

export default ModalTotalWealth
